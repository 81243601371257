import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../Assets/translations/en';

i18n
  .use(initReactI18next)
  .init({
    debug: false, // process.env.NODE_ENV !== 'production',
    resources: {
      en,
    },
    lng: 'pt',
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
