/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Button,
  Col,
  Row,
  Table,
  Input,
  Select,
  Popconfirm,
  Space,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FilterOutlined, CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment, { Moment } from 'moment';
import { Link, useHistory } from 'react-router-dom';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { Subsegment } from '../../../../Models/Subsegment.model';
import { Store } from '../../../../Models/Store.model';
import { EconomicGroup } from '../../../../Models/Group.model';
import { generateReportStores } from '../../../../Helpers/sheet';

interface FilterCategoriesState {
  textSearch: string | undefined
  startDate: Moment | undefined
  finishDate: Moment | undefined
  groupSearch: number | undefined
}

const { Search } = Input;
const { Option } = Select;

const initialFilterState: FilterCategoriesState = {
  textSearch: undefined,
  startDate: undefined,
  finishDate: undefined,
  groupSearch: undefined,
};

const ManagerStoresList = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [user, setUser] = useState(values?.currentUser?.id);
  const [groups, setGroups] = useState<EconomicGroup[]>([]);
  const [filteredStores, setFilteredStores] = useState<Store[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingClick, setLoadingClick] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [off, setOff] = useState(10);
  const [planilha, setPlanilha] = useState<any>();

  const [filterState, setFilterState] = useState<FilterCategoriesState>(initialFilterState);
  const [openFilter, setOpenFilter] = useState(false);
  const rangePickerRef = useRef<any>();

  let groupSearch: any;
  let textSearch: any;

  const columns = [
    // {
    //   title: t('Razão Social'),
    //   dataIndex: 'name',
    //   render: (_: unknown, record: Store) => `${record.corporateName}`,
    //   sorter: (a: Store, b: Store) => a.corporateName.localeCompare(b.corporateName),
    // },
    {
      title: t('Nome Fantasia'),
      dataIndex: 'name',
      render: (_: unknown, record: Store) => `${record.commercialName}`,
      sorter: (a: Store, b: Store) => a.commercialName.localeCompare(b.commercialName),
    },
    {
      title: t('CNPJ'),
      dataIndex: 'cnpj',
      width: 200,
      render: (_: unknown, record: Store) => `${record.cnpj}`,
      sorter: (a: Store, b: Store) => String(a.cnpj).localeCompare(String(b.cnpj)),
    },
    {
      title: t('Tab'),
      dataIndex: 'tab',
      width: 100,
      render: (_: unknown, record: Store) => `${record.tab}`,
      sorter: (a: Store, b: Store) => a.tab.localeCompare(b.tab),
    },
    {
      title: t('Data de criação'),
      width: 230,
      dataIndex: 'createdAt',
      render: (text: string) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
      defaultSortOrder: 'descend' as 'descend',
      sorter: (a: Store, b: Store) => moment(a.createdAt).diff(b.createdAt),
    },
    {
      title: t('Ações'),
      width: 150,
      dataIndex: 'action',
      render: (text: string, record: Store) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Space direction="vertical">
            <Button
              loading={loadingClick}
              size="small"
              style={{ width: '100%' }}
              onClick={async () => {
                setLoadingClick(true);
                const { data: storeSubsidiary } = await ApiService.manager.findStoreWithSubsidiary(record?.id!);
                if (!!storeSubsidiary) {
                  actions.setStoreSelected(storeSubsidiary || []);
                  setLoadingClick(false);
                  history.push('/manager/store/details');
                }
              }}
            >
              Detalhes
            </Button>
            <Popconfirm
              title="Excluir?"
              onConfirm={async () => {
                try {
                  setLoading(true);
                  setLoadingClick(true);
                  await ApiService.manager.DeleteStore(record.id);
                  const { data } = await ApiService.manager.ListStoresV2({
                    page: page - 1, search: textSearch, offset: off, group: groupSearch, userId: user,
                  });
                  setTotal(data.total);
                  actions.setStores(data.stores || []);
                  setFilteredStores(data.stores);
                } catch (error) {
                  actions.setStores([]);
                } finally {
                  setLoading(false);
                  setLoadingClick(false);
                }
              }}
            >
              <Button loading={loadingClick} type="primary" size="small" style={{ width: '100%' }}> Excluir</Button>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  const fetchStoreList = async () => {
    try {
      setUser(values?.currentUser?.id);
      setLoading(true);
      const { data: economicGroups } = await ApiService.groups.ListEconomicsGroupsPublic();
      setGroups(economicGroups);
      const { data } = await ApiService.manager.ListStoresV2({
        page: page - 1, search: textSearch, offset: off, group: groupSearch, userId: user,
      });
      setTotal(data.total);
      actions.setStores(data.stores || []);
      setFilteredStores(data.stores);
    } catch (error) {
      actions.setStores([]);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    // setFilteredStores(values.stores || []);
  };

  useEffect(() => {
    fetchStoreList();
  }, [page, off]);

  useEffect(() => {
    applyFilters();
  }, [values.stores]);

  return (
    <Row style={{ backgroundColor: 'white', padding: '0 10px' }}>
      <Col style={{
        overflow: 'auto', backgroundColor: 'white', margin: 'auto',
      }}
      >
        <SectionTitle
          title={t('Lojas')}
          renderExtraButton
          extraText="Exportar"
          loading={loadingExport}
          onExtraClick={async () => {
            let tempPage = 0;
            const tempOffset = 500;
            const tempList = [];
            setLoadingExport(true);
            const { data: count } = await ApiService.subsidiaries.CountAllSubsidiaries();
            for (let j = 0; j <= count; j += 500) {
              // eslint-disable-next-line no-await-in-loop
              const { data } = await ApiService.subsidiaries.ListAllSubsidiaries({ page: tempPage, offset: tempOffset });
              tempList.push(...data);
              tempPage += 1;
            }
            const result = generateReportStores(tempList, 'file');
            if (result.success) {
              message.success('Exportação completa!');
            } else {
              message.error('Falha na exportação!');
            }
            setLoadingExport(false);
          }}
        />

        <a id="download-exportacao" style={{ display: 'none' }} href="/" download>Fazer Download</a>

        <div className="align-items-center d-flex mx-3 mt-4 mb-2">
          <div style={{ display: 'flex', flex: 1 }}>
            <Button
              type="primary"
            >
              <Link to="/manager/store/create">
                Adicionar Loja
              </Link>
            </Button>
          </div>

          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Space>
              <Button
                type="primary"
                style={{ pointerEvents: 'none' }}
              >
                <Link
                  to="/manager/store/update"
                  style={{ pointerEvents: 'all' }}
                >
                  Atualizar Lojas em Lote
                </Link>
              </Button>
              <Button
                type="primary"
                style={{ pointerEvents: 'none' }}
              >
                <Link
                  to="/manager/store/add"
                  style={{ pointerEvents: 'all' }}
                >
                  Adicionar Lojas em Lote
                </Link>
              </Button>
            </Space>
          </div>
        </div>

        <div className="align-items-center justify-content-end d-flex mx-3 mt-4 mb-2">
          <Search
            onSearch={(e) => {
              textSearch = e;
              groupSearch = filterState.groupSearch;
              setFilterState({
                ...filterState,
                textSearch,
                groupSearch,
              });

              if (page === 1) {
                fetchStoreList();
              } else {
                setPage(1);
              }
            }}
            enterButton
            allowClear
            placeholder={t('Digite o Nome Fantasia, CNPJ ou n TAB da loja para buscar')}
            size="large"
            onChange={(e) => {
              textSearch = e.target.value;
              groupSearch = filterState.groupSearch;
              setFilterState({
                ...filterState,
                textSearch: textSearch,
                groupSearch: groupSearch,
              });
            }}
          />

          <Button
            type="primary"
            icon={<FilterOutlined />}
            size="large"
            onClick={() => setOpenFilter((op) => !op)}
          />

        </div>

        {openFilter && (
          <Row align="middle" justify="start" style={{ padding: '1rem' }}>
            <Col style={{ marginRight: '1rem' }}>
              <Select
                allowClear
                autoClearSearchValue
                placeholder={t('Escolha um grupo econômico')}
                size="large"
                style={{ width: 270 }}
                onChange={async (e) => {
                  groupSearch = Number(e);
                  textSearch = filterState.textSearch;
                  setFilterState({
                    ...filterState,
                    textSearch,
                    groupSearch,
                  });

                  if (page === 1) {
                    fetchStoreList();
                  } else {
                    setPage(1);
                  }
                }}
              >
                {
                  groups.map((element) => {
                    return (
                      <Option value={element.id}>{element.name}</Option>
                    );
                  })
                }
              </Select>
            </Col>

            {/* <Col>
              <Button
                size="large"
                onClick={resetFilterState}
              >
                Limpar Filtros
              </Button>
            </Col> */}
          </Row>
        )}

        {loading ? (
          <>
            <Row align="middle" justify="center">
              <Col style={{
                padding: 80,
                minWidth: '60vw',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                <LoadingOutlined style={{ fontSize: 60 }} />
              </Col>
            </Row>
          </>
        ) : (
          <Table
            columns={columns}
            dataSource={filteredStores || []}
            loading={false}
            pagination={{
              position: ['bottomCenter'],
              onChange: (event) => { setPage(event); },
              current: page,
              total,
              pageSize: off,
              onShowSizeChange: ((c, s) => {
                setPage(c);
                setOff(s);
              }),
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default ManagerStoresList;
