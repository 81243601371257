import { Lead } from './Lead.model';
import { LogStatus } from './LogStatus.model';
import { User } from './User.model';

export enum LogStatusEnum {
  CONTACTED = 1,
  PROJECT_SENT = 2,
  CLOSED = 3,
  FINANCING = 4,
  CANCELLED = 5,
}

export interface LeadLog {
  id: number;
  user?: User;
  lead?: Lead;
  createdAt?: Date;
  logStatus?: LogStatus;
  comment?: string;
}
