export const addressType = [
  { value: 'Aeroporto' },
  { value: 'Alameda' },
  { value: 'Área' },
  { value: 'Avenida' },
  { value: 'Campo' },
  { value: 'Chácara' },
  { value: 'Colônia' },
  { value: 'Condomínio' },
  { value: 'Conjunto' },
  { value: 'Distrito' },
  { value: 'Esplanada' },
  { value: 'Estação' },
  { value: 'Estrada' },
  { value: 'Favela' },
  { value: 'Fazenda' },
  { value: 'Feira' },
  { value: 'Jardim' },
  { value: 'Ladeira' },
  { value: 'Lago' },
  { value: 'Lagoa' },
  { value: 'Largo' },
  { value: 'Loteamento' },
  { value: 'Morro' },
  { value: 'Núcleo' },
  { value: 'Parque' },
  { value: 'Passarela' },
  { value: 'Pátio' },
  { value: 'Praça' },
  { value: 'Quadra' },
  { value: 'Recanto' },
  { value: 'Residencial' },
  { value: 'Rodovia' },
  { value: 'Rua' },
  { value: 'Setor' },
  { value: 'Sítio' },
  { value: 'Travessa' },
  { value: 'Trecho' },
  { value: 'Trevo' },
  { value: 'Vale' },
  { value: 'Vereda' },
  { value: 'Via' },
  { value: 'Viaduto' },
  { value: 'Viela' },
  { value: 'Vila' },
];
