import React from 'react';
import { Redirect, Route } from 'react-router-dom';

type ProtectRouteProps = {
  component: any;
  condition: boolean;
  redirect: string;
  path: string;
};

type RouteProps = {
  location: any;
};

const ProtectRoute = ({
  component: Component,
  condition,
  redirect,
  path,
  ...rest
}: ProtectRouteProps) => (
  <Route
    exact
    path={path}
    {...rest}
    component={({ location, ...props }: RouteProps) => (
      condition
        ? <Component {...rest} {...props} />
        : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: location },
            }}
          />
        )
    )}
  />
);

export default ProtectRoute;
