import axios, { AxiosRequestConfig, Method } from 'axios';
import { getHomepage } from '../../Helpers/functions';

export const getHeaders = () => ({
  Authorization: `Bearer ${sessionStorage.getItem(process.env.REACT_APP_AUTH_KEY || '')}`,
  'Content-Type': 'application/json',
});

function execute<T>(method: Method, url: string, data: any, config?: AxiosRequestConfig) {
  return axios.request<T>({
    ...config,
    baseURL: process.env.REACT_APP_API_URL,
    headers: getHeaders(),
    timeout: 999999999,
    method,
    url,
    data,
  });
}

function get<T>(url: string, config?: AxiosRequestConfig) {
  return execute<T>('GET', url, null, config);
}

function post<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return execute<T>('POST', url, data, config);
}

function put<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return execute<T>('PUT', url, data, config);
}

function httpDelete<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return execute<T>('DELETE', url, data, config);
}

export const ApiClient = {
  get, post, httpDelete, put,
};

axios.interceptors.response.use((value) => {
  return value;
}, (error) => {
  if (error && error.response && error.response.status === 401) {
    try {
      sessionStorage.removeItem(process.env.REACT_APP_AUTH_KEY || '');
      sessionStorage.removeItem('shopkeeperLoged');
      sessionStorage.clear();

      window.location.replace(getHomepage() || '/');
    } catch (err) {
      //
    }
  }
  Promise.reject(error);
});
