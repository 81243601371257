import React from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { LockOutlined } from '@ant-design/icons';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TemplateLogin, TemplateLoginProps } from '../Components/Templates/TemplateLogin';
import { FormInputProps } from '../Components/Molecules/InputDefault';
import { AuthContext } from '../Base/Providers/Auth.context';
import ApiService from '../Services/Api';

const getFields = (t: TFunction): FormInputProps[] => ([
  {
    name: 'password',
    inputProps: {
      type: 'password',
      placeholder: t('Nova Senha'),
      prefix: <LockOutlined />,
    },
    rules: [{
      required: true,
      min: 6,
      max: 20,
      message: t('A senha deve ter entre 6 e 20 caracteres'),
    }],
  },

  {
    name: 'confirmPassword',
    inputProps: {
      type: 'password',
      placeholder: t('Confirmar Senha'),
      prefix: <LockOutlined />,
    },
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('As senhas não coincidem.'));
        },
      }),
    ],
  },
]);

type IResetPassword = { password: string; confirmPassword: string; };

type RouteParams = RouteComponentProps<{ uuid: string }>;

const PageResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const fields: TemplateLoginProps<IResetPassword> = {
    onSubmit: async (model) => {
      // alert(mail);
      const token = history.location.search.replace('?token=', '');
      await ApiService.auth.registerNewPassword({ password: model.password, salt: token });
      history.push('/login');
    },
    loading: false,
    fields: getFields(t),
    title: t('Resetar Senha'),
    subtitle: t('Digite e confirme sua nova Senha'),
  };

  return (
    <TemplateLogin {...fields} />
  );
};

export default PageResetPassword;
