/* eslint-disable max-len */
import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { AuthContext } from './Providers/Auth.context';
import ProtectRoute from '../Components/Atoms/ProtectRoute';

import PageLogin from '../Pages/Login';

import LoggedInRouter from './LoggedInRouter';
import PageForgetPassword from '../Pages/ForgetPassword';
import PageResetPassword from '../Pages/ResetPassword';
import PageShopkeeperLogin from '../Pages/Dash/Shopkeeper-Options/Login-Shopkeeper/ShopkeeperLogin';
import { getHomepage } from '../Helpers/functions';

const LoggedOutRouter = () => {
  const { value, actions } = useContext(AuthContext);

  const isLoggedIn = !!value;

  const temp = sessionStorage.getItem(process.env.REACT_APP_LOGIN_TYPE!);

  return (
    <BrowserRouter basename={getHomepage()}>
      <Switch>
        {/* LoggedOut Routes */}
        <ProtectRoute path="/login" component={PageLogin} condition={!isLoggedIn} redirect="/" />
        <ProtectRoute path="/shopkeeper-login" component={PageShopkeeperLogin} condition={!isLoggedIn} redirect="/shopkeeper-options" />
        <ProtectRoute path="/forgot-password" component={PageForgetPassword} condition={!isLoggedIn} redirect="/" />
        <ProtectRoute path="/reset-password/:uuid" component={PageResetPassword} condition={!isLoggedIn} redirect="/" />
        {/* <ProtectRoute path="/authenticate-login/:email" component={PageConfirmationCode} condition={!isLoggedIn} redirect="/" /> */}

        {/* LoggedIn Dash */}
        <ProtectRoute path="/" component={LoggedInRouter} condition={isLoggedIn} redirect={temp === 'SHOPKEEPER' ? '/shopkeeper-login' : '/login'} />
      </Switch>
    </BrowserRouter>
  );
};

export default LoggedOutRouter;
