import { Subsidiary } from '../../Models/Subisidiary.model';
import { ApiClient } from './client';

export function ListAllSubsidiaries(body: any) {
  return ApiClient.post<any>('/admin/subsidiaries/manage/all-subsidiaries', body);
}

export function CountAllSubsidiaries() {
  return ApiClient.get<any>('/admin/subsidiaries/manage/count/all-subsidiaries');
}

export function ListSubsidiaries(body: any) {
  return ApiClient.post<any>('/admin/subsidiaries/manage', body);
}

export function ListAllSubsidiariesShopkeeper(body: any) {
  return ApiClient.post<any>('/admin/subsidiaries/manage/shopkeeper/all-subsidiaries', body);
}

export function ListShopkeeperSubsidiaries(body: any) {
  return ApiClient.post<any>('/admin/subsidiaries/manage/shopkeeper', body);
}

interface ListSubsidiairesV2Res {
  subsidiaries: Subsidiary[]
  total:number
}
export function ListSubsidiariesInModeration(body: any) {
  return ApiClient.post<ListSubsidiairesV2Res>('/admin/subsidiaries/manage/moderation', body);
}

export function DeleteListSubsidiaries(id: number) {
  return ApiClient.httpDelete<Subsidiary>(`/admin/subsidiaries/manage/delete/${id}`);
}

export function CreateSubsidiaries(body: FormData) {
  return ApiClient.post('/admin/subsidiaries/manage/create', body);
}

export function EditSubsidiaries(body: FormData) {
  return ApiClient.post('/admin/subsidiaries/manage/edit', body);
}

export function EditSubsidiariesByShopkeeper(body: any) {
  return ApiClient.post<Subsidiary>('/admin/subsidiaries/manage/shopkeeper/edit', body);
}

export function updateStatusOfSubsidiaryByShopkeeper(body: any) {
  return ApiClient.post<Subsidiary>('/admin/subsidiaries/manage/shopkeeper/status', body);
}

export function DeleteSubsidiarieByShopkeeper(id: number) {
  return ApiClient.httpDelete<Subsidiary>(`/admin/subsidiaries/manage/shopkeeper/delete/${id}`);
}

export function ApproveEdition(body: FormData) {
  return ApiClient.post('/admin/subsidiaries/manage/edition/approve', body);
}

export function ApproveManyEdition(body: any) {
  return ApiClient.post('/admin/subsidiaries/manage/edition/approve/many', body);
}

export function RejectEdition(body: FormData) {
  return ApiClient.post('/admin/subsidiaries/manage/edition/reject', body);
}

export function AddSubsidiaries(body: any) {
  return ApiClient.post('/admin/subsidiaries/manage/add-subsidiaries', body);
}

export function AddShopkeeperSubsidiaries(body: any) {
  return ApiClient.post('/public/clients/add-subsidiaries', body);
}
