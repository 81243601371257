/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Col, Modal, Row, ModalProps,
} from 'antd';

const ModalLoading: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props} centered footer={null} closable={false}>
      <Row align="middle" justify="center">
        <Col
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p>
            Gerando arquivo...
            {' '}
            {props.okText}
          </p>
          <LoadingOutlined style={{ fontSize: 60 }} />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalLoading;
