/* eslint-disable quote-props */

export default {
  translation: {
    'Informe um e-mail válido': 'Please enter a valid email address',
    'Informe sua senha': 'Inform your password',
    'Esqueci minha senha': 'Forgot my password',
    'Voltar': 'Go back',

    'Entrar': 'Log in',
    'Efetue seu login': 'Log in',

    'Enviar': 'Submit',
    'Sim': 'Yes',
    'Não': 'No',

    'Excluir item?': 'Delete item?',

    'Ocorreu um erro': 'An error has occurred',

    'Seja bem vindo ao Painel Administrativo': 'Welcome to the Administrative Panel',
    'No painel ao lado você pode escolher o tema que deseja acessar. ': 'Select the desired module beside',

    'Nome': 'Name',
    'Sobrenome': 'Surname',
    'Vendas': 'Sells',
    'Energia +': 'Energy +',

    'Casas de Eventos': 'Casas de Eventos',

    'Editar': 'Edit',
    'Novo': 'New',

    'Buscar': 'Search',
    'Digite para buscar': 'Type to search',

    'Grupo de Usuário': 'User Group',
    'Selecione o Grupo de Acesso': 'Select the Access Group',
    'Selecione a empresa': 'Select the company',

    'Empresas': 'Companies',
    'Empresa': 'Company',

    'Usuários': 'Users',
    'Usuário': 'User',

    'Grupo': 'Group',
    'Ativo': 'Active',
    'Ações': 'Actions',

    'Permissões': 'Permissions',
    'Grupos': 'Groups',

    'Limpar Filtros': 'Clear Filters',
    'Filtrar': 'Filter',

    'Administrador': 'Administrator',
    'Consultor': 'Consultant',
    'Editor': 'Editor',
    'Salvar Alterações': 'Save Changes',
    'Salvar': 'Save',

    'Pedidos': 'Requests',
    'Clientes': 'Customers',
    'Usuários e Permissões': 'Users and Permissions',
    'Eventos': 'Events',
    'Dashboard': 'Dashboard',
    'Transferências': 'Transfers',
    'Credenciais': 'Credentials',
    'Convidados e Cortesias': 'Guests and Courtesies',
    'Vendas em Grupo': 'Group Sales',

    'Artistas': 'Artists',

    'Nome Fantasia': 'Fantasy Name',
    'Razão Social': 'Company Name',
    'CNPJ ': 'CNPJ',
    'Use apenas números': 'Use only numbers',
    'Inscrição estatual': 'State Registration',
    'Telefone': 'Telephone',
    'E-mail': 'Email',
    'Endereço da empresa': "Company's Adress",
    'CEP': 'Zip Code',
    'Endereço': 'Address',
    'Número': 'Number',
    'Complemento': 'Complement',
    'Bairro': 'Neighborhood',
    'Cidade': 'City',
    'UF': 'UF',
    'Redes Sociais': 'Social Media',
    'Site': 'Site',
    'Facebook': 'Facebook',
    'Instagram': 'Instagram',
    'Twitter': 'Twitter',
    'Youtube': 'Youtube',

    'Cadastrar': 'Submit',
    'Tudo certo!': 'All right!',
    'Algo deu Errado...': 'Something went wrong...',
    'Algo deu errado, tente novamente': 'Something went wrong, please try again',

    'Nova Senha': 'New password',
    'Confirmar Senha': 'Confirm password',
    'Resetar Senha': 'Reset Password',
    'Digite e confirme sua nova Senha': 'Enter and confirm your new password',
    'Digite sua nova senha': 'Please enter a new password',
    'As senhas não coincidem': 'Passwords do not match',
    'Digite seu e-mail para recuperar sua senha': 'Enter your email to retrieve your password',

    'Autenticação': 'Authentication',
    'Digite o código de autenticação enviado via SMS': 'Enter the authentication code',
    'Código de autenticação inválido': 'Invalid authentication code',
    'Código de Autenticação': 'Authentication code',
    'Insira o Código de autenticação': 'Enter the Authentication code',

    'Verifique seu e-mail para recuperar sua senha': 'Check your email to recover your password',
    'Verifique seu e-mail e tente novamente': 'Check your email and try again',
    'Você já pode fazer login com sua nova senha!': 'You can now login with your new password!',
    'Verifique as senhas digitadas e tente novamente': 'Check the passwords entered and try again',
    'Por favor, clique no link enviado para seu e-mail': 'Por favor, clique no link enviado para seu e-mail',
    'Senha atualizada com sucesso.': 'Senha atualizada com sucesso.',
    'Salvo com sucesso': 'Salvo com sucesso',

    'Produtos Adicionais': 'Produtos Adicionais',
    'Produtos': 'Produtos',
    'Produto': 'Produto',
    'Item': 'Item',
    'Upload de Imagem': 'Upload de Imagem',
    'Click or drag file to this area to upload': 'Click or drag file to this area to upload',
    'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files': 'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',

    'gramas': 'gramas',

    'finished': 'Finalizado',
  },
};
