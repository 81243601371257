/* eslint-disable @typescript-eslint/no-extraneous-class */
import { DateTime, Settings } from 'luxon';

const zone: string = 'America/Sao_Paulo';
const locale: string = 'pt-BR';

Settings.defaultLocale = locale;
Settings.defaultZone = zone;

export class DateHelper {
  public static fromString(date: string) {
    return DateTime.fromISO(date).setZone(zone).setLocale(locale);
  }

  public static fromStringFormatted(date: string, withHours = true) {
    let format = 'dd/MM/yyyy HH:mm';
    if (!withHours) {
      format = 'dd/MM/yyyy';
    }
    return DateHelper.fromString(date).toFormat(format);
  }

  public static now() {
    return DateTime.now().setZone(zone).setLocale(locale);
  }
}
