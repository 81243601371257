/* eslint-disable no-alert */
/* eslint-disable no-extra-boolean-cast */
import React, { useContext, useEffect } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { FormInputProps } from '../../../../Components/Molecules/InputDefault';
import { TemplateLoginProps } from '../../../../Components/Templates/TemplateLogin';
import { AuthContext } from '../../../../Base/Providers/Auth.context';
import { recaptcha } from '../../../../Helpers/recaptcha';
import ApiService from '../../../../Services/Api';
import { TemplateLoginShopkeeper } from '../../../../Components/Templates/TemplateLoginShopkeeper';

const getFields = (t: TFunction): FormInputProps[] => ([
  {
    name: 'email',
    rules: [{
      required: true,
      message: t('Informe um CNPJ'),
    }],
    inputProps: {
      // type: 'text',
      placeholder: t('CNPJ'),
      mask: '11.111.111/1111-11',
      prefix: <UserOutlined />,
    },
  },

  {
    name: 'password',
    rules: [{
      required: true,
      message: t('Informe sua senha'),
    }],
    inputProps: {
      type: 'password',
      placeholder: t('Senha'),
      prefix: <LockOutlined />,
    },
  },
]);

interface ILogin { email: string; password: string; }

interface ILoginResponse {
  auth: {
    status: 'REQUIRED_2FA' | 'OK',
    email?: string;
    token?: string;
  }
}

const PageShopkeeperLogin = () => {
  const { t } = useTranslation();
  const { actions } = useContext(AuthContext);

  const fields: TemplateLoginProps<ILogin> = {
    onSubmit: async (model) => {
      if (!!Number(model.email.replace('.', '').replace('/', '').replace('-', ''))) {
        try {
          const { status, data } = await ApiService.auth.authUserShopkeeper({
            email: model.email.replace('.', '').replace('.', '').replace('/', '').replace('-', ''),
            password: model.password,
          });

          if (status === 201 && data.ok) {
            actions.doLogin(data.token);
            sessionStorage.setItem('shopkeeperLoged', model.email);
            sessionStorage.setItem(process.env.REACT_APP_LOGIN_TYPE!, 'SHOPKEEPER');
          } else {
            alert('Cnpj ou senha inválido!');
          }
        } catch (error) {
          //
        }
      } else {
        alert('Insira um CNPJ válido');
      }
    },
    loading: false,
    fields: getFields(t),
    title: t('Portal do lojista'),
    subtitle: t('Informe seu cnpj e senha para acessar'),
    actionButtons: {
      // leftButton: {
      //   title: t('Esqueci minha senha'),
      //   url: '/forgot-password',
      // },
      submitButton: {
        title: t('Login'),
      },
    },
  };

  return (
    <TemplateLoginShopkeeper
      {...fields}
    />
  );
};

export default PageShopkeeperLogin;
