/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Select,
  Popconfirm,
  Table,
  Modal,
  Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';

const SubsegmentEdit = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [subsegment, setSubsegment] = useState<any>(values.subsegmentEdit);
  const [allSubsegments, setAllSubsegments] = useState<any>(values.subsegments);
  const [validate, setValidate] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState('');
  const [fidelityCheck, setFidelityCheck] = useState(values.subsegmentEdit?.fidelity);

  const showModalEdit = () => {
    setIsModalEdit(true);
  };

  const handleOkEdit = async () => {
    setLoading(true);
    setIsModalEdit(false);
    await ApiService.subsegments.EditProduct({ id: productId, name: productName });
    const { data } = await ApiService.subsegments.ListProductsBySubsegment(values?.subsegmentEdit?.id!);
    setFilteredProducts(data);
    setProductName('');
    setProductId('');
    setLoading(false);
  };

  const handleCancelEdit = () => {
    setIsModalEdit(false);
    setProductName('');
    setProductId('');
  };

  const showModalAdd = () => {
    setIsModalAdd(true);
  };

  const handleOkAdd = async () => {
    setLoading(true);
    setIsModalAdd(false);
    await ApiService.subsegments.CreateProduct({ subsegmentId: values?.subsegmentEdit?.id, name: productName });
    const { data } = await ApiService.subsegments.ListProductsBySubsegment(values?.subsegmentEdit?.id!);
    setFilteredProducts(data);
    setProductName('');
    setProductId('');
    setLoading(false);
  };

  const handleCancelAdd = () => {
    setIsModalAdd(false);
    setProductName('');
    setProductId('');
  };

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'name',
      render: (_: unknown, record: any) => `${record.product.name}`,
      sorter: (a: any, b: any) => a.product.name.localeCompare(b.product.name),
    },
    {
      title: t('Data de criação'),
      dataIndex: 'createdAt',
      render: (text: string) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
      defaultSortOrder: 'descend' as 'descend',
      sorter: (a: any, b: any) => moment(a.product.createdAt).diff(b.product.createdAt),
    },
    {
      title: t('Ações'),
      width: 230,
      dataIndex: 'action',
      render: (text: string, record: any) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            loading={loading}
            size="small"
            style={{ width: '100%' }}
            onClick={async () => {
              showModalEdit();
              setProductId(record.product.id);
              setProductName(record.product.name);
            }}
          >
            Editar
          </Button>
          <Popconfirm
            title="Excluir?"
            onConfirm={async () => {
              try {
                setLoading(true);
                await ApiService.subsegments.DeleteProductFromSubsegment(record.product.id);
                const { data } = await ApiService.subsegments.ListProductsBySubsegment(values?.subsegmentEdit?.id!);
                setFilteredProducts(data || []);

                // setFilteredSegments(data);
              } catch (error) {
                //
              } finally {
                setLoading(false);
              }
            }}
          >
            <Button type="primary" size="small" style={{ width: '100%' }}> Excluir</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onFinish = async (formValues: any) => {
    setLoading(true);
    await ApiService.subsegments.EditSubsegment({
      id: subsegment.id,
      code: formValues.code,
      name: formValues.name,
      text: formValues.text,
      parcelCsc: formValues.parcelCsc,
      parcelCdc: formValues.parcelCdc,
      parcelCdcSub: formValues.parcelCdcSub,
      fidelity: formValues.fidelity,
    });
    setLoading(false);
    // history.push('/subsegments');
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await ApiService.subsegments.ListProductsBySubsegment(values?.subsegmentEdit?.id!);
      setFilteredProducts(data);
    };

    fetchData();
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <SectionTitle
            title={t('Subsegmentos | Editar')}
          />

          <Form
            name="edit-subsegment"
            onFinish={onFinish}
            layout="vertical"
            style={{
              maxWidth: 900,
              margin: 'auto',
            }}
          >
            <Form.Item
              label="Código"
              name="code"
              initialValue={values?.subsegmentEdit?.code}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            // validateStatus={validate ? 'error' : 'success'}
            // help={validate ? 'Should be combination of numbers & alphabets' : ''}
            >
              <Input
                onChange={(element) => {
                  const tempValidate = allSubsegments.some((a: any) => a.code === parseInt(element.target.value, 10) && parseInt(element.target.value, 10) !== values.subsegmentEdit?.code);
                  if (tempValidate) {
                    setValidate(true);
                  } else {
                    setValidate(false);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Nome"
              name="name"
              initialValue={values?.subsegmentEdit?.name}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Texto de apoio"
              name="text"
              initialValue={values?.subsegmentEdit?.text}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input.TextArea
                rows={4}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label="Prazo CSC"
                name="parcelCsc"
                initialValue={values?.subsegmentEdit?.parcelCsc}
                style={{ display: 'inline-block', width: 'calc(33% - 8px)' }}
              >
                <Input
                  style={{ display: 'inline-block', textTransform: 'uppercase', width: '33%' }}
                  type="number"
                />
              </Form.Item>
              <Form.Item
                label="Prazo CDC Tradicional"
                name="parcelCdc"
                initialValue={values?.subsegmentEdit?.parcelCdc}
                style={{ display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 0 0 8px' }}
              >
                <Input
                  style={{ display: 'inline-block', textTransform: 'uppercase', width: '33%' }}
                  type="number"
                />
              </Form.Item>
              <Form.Item
                label="Prazo CDC Subsidiado"
                name="parcelCdcSub"
                initialValue={values?.subsegmentEdit?.parcelCdcSub}
                style={{ display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 0 0 8px' }}
              >
                <Input
                  style={{ display: 'inline-block', textTransform: 'uppercase', width: '33%' }}
                  type="number"
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              style={{ marginTop: 20 }}
              name="fidelity"
              valuePropName="checked"
              initialValue={fidelityCheck}
              wrapperCol={{ offset: 0, span: 24 }}
            >
              <Checkbox onChange={(event) => setFidelityCheck(event.target.checked)}><b>+ Fidelidade</b></Checkbox>
            </Form.Item>
            <Form.Item style={{ display: 'flex', textAlignLast: 'center' }}>
              <Space>
                <Button loading={loading} type="primary" htmlType="submit" style={{ width: 150, flex: 1 }} disabled={validate}>
                  Editar subsegmento
                </Button>
                <Button loading={loading} style={{ width: 150, flex: 1 }}>
                  <Link to="/subsegments">
                    Cancelar
                  </Link>
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{ maxWidth: 900, margin: 'auto' }}
        >
          <div
            style={{
              marginTop: 40,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <h2>Produtos</h2>

            <div className="align-items-center d-flex mx-3 mb-2">
              <Button
                type="primary"
                onClick={() => showModalAdd()}
              >
                Adicionar Produto/Serviço
              </Button>
            </div>
          </div>

          {loading ? (
            <>
              <Row align="middle" justify="center">
                <Col style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                >
                  <LoadingOutlined style={{ fontSize: 60 }} />
                </Col>
              </Row>
            </>
          ) : (
            <Table
              columns={columns}
              dataSource={filteredProducts || []}
              loading={false}
              pagination={{ position: ['bottomCenter'] }}
            />
          )}
        </Col>
      </Row>
      <Modal title="Editar Produto/Serviço" visible={isModalEdit} onOk={handleOkEdit} onCancel={handleCancelEdit}>
        <Input
          value={productName}
          placeholder="Nome do Produto/Serviço"
          onChange={(element) => setProductName(element.target.value)}
        />
      </Modal>
      <Modal title="Adicionar Produto/Serviço" visible={isModalAdd} onOk={handleOkAdd} onCancel={handleCancelAdd}>
        <Input
          value={productName}
          placeholder="Nome do Produto/Serviço"
          onChange={(element) => setProductName(element.target.value)}
        />
      </Modal>
    </>
  );
};

export default SubsegmentEdit;
