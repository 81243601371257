import { Lead } from '../../Models/Lead.model';
import { LeadLog } from '../../Models/LeadLog.model';
import { LogStatus } from '../../Models/LogStatus.model';
import { Provider } from '../../Models/Provider.model';
import { ApiClient } from './client';

export function listLeads() {
  return ApiClient.get<Lead[]>('/admin/leads/manage');
}

export interface SearchLeadDto {
  page: number;
  offset?: number;
  search?: string;
  providers?: number[];
  status?: number;
  startDate?: string;
  endDate?: string;
}

export interface SearchLeadResponse {
  currentPage: number;
  offset: number;
  remainingPages: number;
  totalItems: number;
  items: Lead[];
}

export function searchLeads(model: SearchLeadDto) {
  return ApiClient.post<SearchLeadResponse>('/admin/leads/manage/search', model);
}

export function getById(id: any) {
  return ApiClient.get<Lead>(`/admin/leads/manage/${id}`);
}

export function getProviders() {
  return ApiClient.get<Provider[]>('/admin/leads/manage/providers');
}

export function getStatus() {
  return ApiClient.get<LogStatus[]>('/admin/leads/manage/status');
}

interface AddLogDto {
  statusId: number;
  leadId: number;
  comment?: string;
}

export function postAddLog(model: AddLogDto) {
  return ApiClient.post<LeadLog>('/admin/leads/manage/add-log', model);
}

export function deleteLog(id: number) {
  return ApiClient.httpDelete<void>(`/admin/leads/manage/remove-log/${id}`);
}
