/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Button,
  Layout,
  FormInstance,
  Carousel,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/lib/typography';
import { Link } from 'react-router-dom';
import LanguageDropdown from '../../Molecules/LanguageDropdown';
import DefaultLogo from '../../Atoms/DefaultLogo';
import InputDefaultShopkeeper, { FormInputProps } from '../../Molecules/InputDefaultShopkeeper';
import './TemplateLoginShopkeeper.less';
import './TemplateLoginShopkeeper.css';

const { Content, Sider } = Layout;
const { Title, Text } = Typography;

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

export type TemplateLoginProps<OnSubmit> = {
  onSubmit: (model: OnSubmit) => Promise<void>;
  fields: FormInputProps[];
  form?: FormInstance;
  title: string
  loading: boolean;
  subtitle?: string
  actionButtons?: {
    leftButton?: {
      title: string
      url?: string
      onClick?: () => void
    },
    submitButton?: {
      title?: string
    },
  }
};

function TemplateLoginShopkeeper<T>({
  onSubmit,
  loading,
  form,
  actionButtons,
  fields,
  subtitle,
  title,
}
  : TemplateLoginProps<T>) {
  const { t } = useTranslation();
  const { title: submitText } = (actionButtons || {}).submitButton || {};

  return (
    <Layout className="logged-out-page bg-white">
      <div
        className="father"
        style={{
          display: 'flex',
          flexFlow: 'wrap',
          minHeight: '100vh',
          flex: 1,
        }}
      >
        <div
          style={{
            backgroundColor: '#CC0000',
            display: 'flex',
            minWidth: '50vw',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Carousel autoplay style={{ zIndex: 10000, maxWidth: 300, padding: 50 }}>
            <div style={{ minWidth: 280, maxWidth: 350, padding: 20 }}>
              <h3
                style={{
                  color: '#fff',
                  fontSize: 35,
                  padding: 130,
                }}
              >
                Complete os dados cadastrais da sua loja e exiba ofertas personalizadas aos clientes finais
              </h3>
              {/* <h3
                style={{
                  color: '#fff',
                  fontSize: '1.17em',
                }}
              >
                Complete os dados cadastrais da sua loja e exiba ofertas personalizadas aos clientes finais
              </h3> */}
            </div>
            {/* <div style={{ minWidth: 280, maxWidth: 350 }}>
              <h3
                style={{
                  color: '#fff',
                  fontSize: '1.17em',
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis soluta praesentium quia voluptates maxime debitis velit excepturi exercitationem blanditiis? Nisi eos laboriosam autem voluptatem quibusdam iste quas enim dicta animi?
              </h3>
            </div>
            <div style={{ minWidth: 280, maxWidth: 350 }}>
              <h3
                style={{
                  color: '#fff',
                  fontSize: '1.17em',
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis soluta praesentium quia voluptates maxime debitis velit excepturi exercitationem blanditiis? Nisi eos laboriosam autem voluptatem quibusdam iste quas enim dicta animi?
              </h3>
            </div> */}
          </Carousel>
        </div>
        <div
          style={{
            display: 'flex',
            minWidth: '50vw',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
          className="inner-content"
        >
          <Form
            autoComplete="chrome-off"
            className="p-4"
            onFinish={onSubmit}
            // style={{ border: '1px solid #CCCCCC' }}
            {...(form ? { form } : {})}
          >
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div className="text-box  pr-3">
                <Title level={4}>{title}</Title>

                {subtitle && <Text>{subtitle}</Text>}
              </div>

              {/* <LanguageDropdown /> */}
            </div>

            {fields.map((item) => (
              <InputDefaultShopkeeper
                key={`input-${item.name}`}
                field={item}
              />
            ))}

            <Form.Item className="mb-0">
              <div className={`flex-row justify-content-${(actionButtons || {}).leftButton ? 'between' : 'end'} mt-4`}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                  }}
                >
                  {submitText || t('Enviar')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Layout>
  );
}

export default TemplateLoginShopkeeper;
