/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { PageHeader, Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';

type SectionTitleProps = {
  loading?: boolean,
  transparent?: boolean,
  title: string,
  to?: string,
  textButton?: string,
  type?: 'primary' | 'link' | 'text' | 'ghost' | 'default' | 'dashed' | undefined,
  extraText?: string,
  renderExtraButton?: boolean,
  renderRefreshButton?: boolean,
  onExtraClick?: () => void,
  onRefreshClick?: () => void,
};

const SectionTitle = ({
  loading,
  transparent,
  title,
  to,
  textButton,
  type,
  extraText,
  renderExtraButton,
  renderRefreshButton,
  onExtraClick,
  onRefreshClick,
}: SectionTitleProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const text = t(textButton || '');

  return (
    <PageHeader
      style={{ backgroundColor: !!transparent ? 'transparent' : 'white' }}
      className="px-3 mb-2 no-padding"
      ghost={false}
      onBack={() => history.goBack()}
      title={title}
      extra={
        !!renderExtraButton && (
          <Row align="middle" justify="center">
            {renderRefreshButton && (
              <Button
                style={{ marginRight: '1px' }}
                type="primary"
                icon={<SyncOutlined />}
                onClick={onRefreshClick}
              />
            )}
            <Button loading={loading} type={type} onClick={onExtraClick}>
              {extraText}
            </Button>
          </Row>
        )
      }
    />
  );
};

SectionTitle.defaultProps = {
  loading: false,
  transparent: false,
  textButton: 'Criar',
  to: '',
  type: 'primary',
  renderExtraButton: false,
  extraText: '',
  renderRefreshButton: false,
  onExtraClick: () => { },
  onRefreshClick: () => { },
};

export default SectionTitle;
