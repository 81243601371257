import { Store } from '../../Models/Store.model';
import { Subsidiary } from '../../Models/Subisidiary.model';
import { ApiClient } from './client';

interface ListStoresV2Res {
  stores: Store[]
  total:number
}
export function ListAllSubsidiaries(id: number) {
  return ApiClient.get<any>(`/manager/stores/all-subsidiaries/${id}`);
}

export function ListStoresV2(body: any) {
  return ApiClient.post<ListStoresV2Res>('manager/stores/list-stores', body);
}

export function CreateStore(body: any) {
  return ApiClient.post('manager/stores/create-store', body);
}

export function EditStore(body: any) {
  return ApiClient.post<Store>('manager/stores/edit-store', body);
}

export function ApproveEdition(body: FormData) {
  return ApiClient.post('manager/stores/edition/approve', body);
}

export function DeleteStore(id: number) {
  return ApiClient.httpDelete<Store>(`manager/stores/delete-store/${id}`);
}

export function ApproveManyEdition(body: any) {
  return ApiClient.post('manager/stores/edition/approve/many', body);
}

export function RejectEdition(body: FormData) {
  return ApiClient.post('manager/stores/edition/reject', body);
}

export function findStoreWithSubsidiary(id: number) {
  return ApiClient.get(`manager/stores/with-subsidiaries/${id}`);
}

export function updateStores(body: any) {
  return ApiClient.post<string[]>('manager/stores/update-stores', body);
}

export function AddStores(body: any) {
  return ApiClient.post('manager/stores/add-stores', body);
}

export function ListSubsidiaries(body: any) {
  return ApiClient.post<any>('manager/stores/list-subsidiaries', body);
}

export function CreateSubsidiaries(body: FormData) {
  return ApiClient.post('manager/stores/create-subsidiary', body);
}

export function EditSubsidiaries(body: FormData) {
  return ApiClient.post('manager/stores/edit-subsidiary', body);
}

export function DeleteListSubsidiaries(id: number) {
  return ApiClient.httpDelete<Subsidiary>(`manager/stores/delete-subsidiary/${id}`);
}

export function AddSubsidiaries(body: any) {
  return ApiClient.post('/manager/stores/add-subsidiaries', body);
}

interface ListSubsidiairesV2Res {
  subsidiaries: Subsidiary[]
  total:number
}

export function ListSubsidiariesInModeration(body: any) {
  return ApiClient.post<ListSubsidiairesV2Res>('manager/stores/moderation', body);
}

export const postAdminUser = async (id?: number, body?: any) => {
  return ApiClient.put(`/admin/users/manage/manager/${id}`, body);
};
