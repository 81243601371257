/* eslint-disable max-len */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Col,
  Row,
  Table,
  Divider,
  Button,
  Popconfirm,
  Space,
  Input,
  DatePicker,
  Popover,
  Tooltip,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { FilterOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { Subsidiary } from '../../../../Models/Subisidiary.model';

interface FilterCategoriesState {
  textSearch: string | undefined
  startDate: Moment | undefined
  finishDate: Moment | undefined
}

const { Search } = Input;

const initialFilterState: FilterCategoriesState = {
  textSearch: undefined,
  startDate: undefined,
  finishDate: undefined,
};

const ManagerModerationList = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [user, setUser] = useState(values.currentUser?.id);
  const [store, setStore] = useState(values.storeSelected);
  const [loading, setLoading] = useState(true);
  const [filteredSusidiaries, setFilteredSubsidiaries] = useState<Subsidiary[]>([]);
  const [filterState, setFilterState] = useState<FilterCategoriesState>(initialFilterState);
  const [openFilter, setOpenFilter] = useState(false);
  // const [page, setPage] = useState(1);
  // const [total, setTotal] = useState(0);
  // const [off, setOff] = useState(10);
  const [arrayToModeration, setArrayToModeration] = useState([]);
  const rangePickerRef = useRef<any>();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setArrayToModeration(selectedRows.map((a: any) => {
        return ({
          id: a.id,
          commercialName: a.commercialName,
          description: a.description,
          storeId: a.store.id,
        });
      }));
    },
  };

  const columns = [
    {
      title: t('Nome Fantasia'),
      dataIndex: 'name',
      ellipsis: true,
      width: 180,
      render: (_: unknown, record: any) => (
        <Tooltip title={record.commercialName} placement="top">
          {record.commercialName}
        </Tooltip>
      ),
      sorter: (a: any, b: any) => a.commercialName.localeCompare(b.commercialName),
    },
    {
      title: t('Descrição'),
      ellipsis: true,
      width: 250,
      dataIndex: 'description',
      render: (_: unknown, record: any) => (
        <Tooltip title={record.description} placement="top">
          {record.description}
        </Tooltip>
      ),
    },
    {
      title: t('Data de criação'),
      width: 120,
      dataIndex: 'createdAt',
      render: (text: string) => moment(text).format('DD/MM/YYYY'),
      defaultSortOrder: 'descend' as 'descend',
      sorter: (a: Subsidiary, b: Subsidiary) => moment(a.createdAt).diff(b.createdAt),
    },
    {
      title: t('Ações'),
      dataIndex: 'action',
      width: 100,
      render: (text: string, record: Subsidiary) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            size="small"
            type="primary"
            style={{ width: '100%' }}
            onClick={async () => {
              actions.setSubisidiryEdit(record || []);
              history.push('/moderation/subsidiary/moderate');
            }}
            disabled={arrayToModeration.length > 0}
          >
            Avaliar
          </Button>
        </div>
      ),
    },
  ];

  const resetFilterState = () => {
    setFilterState(initialFilterState);
  };

  const fetchUserList = async () => {
    try {
      setLoading(true);
      // const { data } = await ApiService.subsidiaries.ListSubsidiariesInModeration({ page: page - 1, search: filterState.textSearch, offset: off });
      const { data } = await ApiService.manager.ListSubsidiariesInModeration({ search: filterState.textSearch, userId: user });
      // setTotal(data.total);
      const dataKey = data.subsidiaries.map((a: any) => { return ({ ...a, key: a.id }); });
      actions.setSubisidiries(dataKey || []);
      setFilteredSubsidiaries(dataKey);
    } catch (error) {
      actions.setSubisidiries([]);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    setFilterState(initialFilterState);
  };

  useEffect(() => {
    fetchUserList();
  }, []);
  // }, [page, off]);

  useEffect(() => {
    applyFilters();
  }, [filterState, values.stores]);

  return (
    <Row style={{ backgroundColor: 'white', padding: '0 10px' }}>
      <Col style={{
        overflow: 'auto', backgroundColor: 'white', margin: 'auto',
      }}
      >
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: 'white',
            borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
          }}
        >

          <SectionTitle
            title={t('Moderar | Filiais')}
          />

          <Row gutter={[16, 16]}>

            {
              arrayToModeration.length > 0 && (
                <Col>
                  <Popconfirm
                    title={`Aprovar ${arrayToModeration.length} itens?`}
                    onConfirm={async () => {
                      const result = await ApiService.manager.ApproveManyEdition(arrayToModeration);
                      fetchUserList();
                    }}
                  >
                    <Button
                      size="large"
                      type="primary"
                      style={{ width: '100%' }}
                      onClick={async () => {
                        const result = await ApiService.manager.ApproveManyEdition(arrayToModeration);
                        fetchUserList();
                      }}
                    >
                      {`Aprovar ${arrayToModeration.length}`}
                    </Button>
                  </Popconfirm>
                </Col>
              )
            }

            <Col span={24}>

              <div className="align-items-center justify-content-end d-flex mx-3 mt-4 mb-2">
                <Search
                  onSearch={(e) => {
                    setFilterState({
                      ...filterState,
                      textSearch: e,
                    });

                    // if (page === 1) {
                    //   fetchUserList();
                    // } else {
                    //   setPage(1);
                    // }
                  }}
                  enterButton
                  allowClear
                  placeholder={t('Digite para buscar')}
                  size="large"
                  onChange={(e) => setFilterState({
                    ...filterState,
                    textSearch: e.target.value,
                  })}
                />

                <Button
                  type="primary"
                  icon={<FilterOutlined />}
                  size="large"
                  onClick={() => setOpenFilter((op) => !op)}
                />

              </div>

              {openFilter && (
                <Row align="middle" justify="start" style={{ padding: '1rem' }}>
                  <Col style={{ marginRight: '1rem' }}>
                    <DatePicker.RangePicker
                      ref={rangePickerRef}
                      size="large"
                      locale={locale}
                      onCalendarChange={(calendarValues) => {
                        if (!calendarValues) {
                          setFilterState({
                            ...filterState,
                            startDate: undefined,
                            finishDate: undefined,
                          });
                          return;
                        }

                        const [startDate, finishDate] = calendarValues as Array<Moment>;

                        setFilterState({
                          ...filterState,
                          startDate,
                          finishDate,
                        });
                      }}
                    />
                  </Col>

                  <Col>
                    <Button
                      size="large"
                      onClick={resetFilterState}
                    >
                      Limpar Filtros
                    </Button>
                  </Col>
                </Row>
              )}

              {loading ? (
                <>
                  <Row align="middle" justify="center">
                    <Col style={{
                      padding: 80,
                      minWidth: '60vw',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    >
                      <LoadingOutlined style={{ fontSize: 60 }} />
                    </Col>
                  </Row>
                </>
              ) : (
                <Table
                  columns={columns}
                  dataSource={filteredSusidiaries || []}
                  loading={false}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  pagination={{
                    position: ['bottomCenter'],
                    // onChange: (event) => { setPage(event); },
                    // current: page,
                    // total,
                    // pageSize: off,
                    // onShowSizeChange: ((c, s) => {
                    //   setPage(c);
                    //   setOff(s);
                    // }),
                  }}
                />
              )}

            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default ManagerModerationList;
