/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, {
  useState, useContext,
} from 'react';
import {
  Button,
  Col,
  Row,
  Input,
  Select,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { CloseSquareOutlined } from '@ant-design/icons';
import $ from 'jquery';
import * as XLSX from 'xlsx';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { getHomepage } from '../../../../Helpers/functions';

interface FilterCategoriesState {
  textSearch: string | undefined
  startDate: Moment | undefined
  finishDate: Moment | undefined
  groupSearch: number | undefined
}

const { Search } = Input;
const { Option } = Select;

const initialFilterState: FilterCategoriesState = {
  textSearch: undefined,
  startDate: undefined,
  finishDate: undefined,
  groupSearch: undefined,
};

const ManagerStoresUpdate = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [planilha, setPlanilha] = useState<any>();
  const [allPlanilha, setAllPlanilha] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [resultsText, setResultsText] = useState<any[]>([]);

  const readUploadFile = (e: any) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target!.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setAllPlanilha(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const uploadXlsx = async () => {
    setLoading(true);
    const dividedArrays = [];
    if (!!allPlanilha) {
      for (let i = 0; i < allPlanilha.length; i += 2) {
        dividedArrays.push(allPlanilha.slice(i, i + 2));
      }
    }
    for (const frags of dividedArrays) {
      const { data } = await ApiService.manager.updateStores({ file: frags });
      setResultsText((tx) => [...tx, ...data]);
    }
    setLoading(false);
    // history.push('/stores');
  };

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Lojas | Atualização em Lote')}
          renderExtraButton
        />
        <div className="d-flex mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto' }}>
          <Alert
            banner
            message="Baixe a planilha em Excel e utilize como base para a atualização.
            Lembre-se! É obrigatório preencher todos os dados."
          />
        </div>
        <div className="d-flex mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto' }}>
          <div style={{ display: 'flex', marginRight: 20 }}>
            <a
              href={`${getHomepage()}/modelo-atualizacao.xlsx`}
              download
              target="_blank"
            >
              <Button
                type="primary"
              >
                Baixar modelo de planilha
              </Button>
            </a>
          </div>
          <div style={{ display: 'flex', marginRight: 20 }}>
            <input
              disabled={loading}
              style={{ display: 'none' }}
              accept=".ods, .xlsx, .xls. xlt, .xml"
              name="updateStore"
              id="update-store"
              type="file"
              onChange={(e) => {
                setPlanilha(e.target.files![0]);
                readUploadFile(e);
              }}
            />

            <div style={{ display: 'flex' }}>
              <label
                htmlFor="update-store"
                className="select-image"
                style={{ padding: '5px 30px' }}
              >
                Selecionar planilha preenchida
              </label>
            </div>
          </div>

          {
            !!planilha && (
              <div style={{ display: 'flex' }}>
                <Button
                  loading={loading}
                  style={{ padding: '0 30px' }}
                  type="primary"
                  onClick={async () => {
                    uploadXlsx();
                  }}
                >
                  Enviar
                </Button>
              </div>
            )
          }

        </div>
        {
          !!planilha && (
            <div className="d-flex mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto', alignItems: 'center' }}>
              <p>{planilha.name}</p>
              <CloseSquareOutlined
                style={{ fontSize: 18, marginLeft: 10 }}
                onClick={(e) => {
                  setPlanilha(null);
                  $('#update-store').val('');
                }}
              />
            </div>
          )
        }

        {resultsText.length > 0 && (
          <div className="d-flex mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto', flexDirection: 'column' }}>
            <h2>Resultados da atualização</h2>

              {resultsText.map((result) => {
                return (
                  <p
                    key={result}
                    style={{
                      color: result.startsWith('Falhou') ? 'red' : 'initial',
                    }}
                  >
                    {result}
                  </p>
                );
              })}
          </div>
        )}

      </Col>
    </Row>
  );
};

export default ManagerStoresUpdate;
