import XLSX from 'xlsx';
import { Lead } from '../Models/Lead.model';
import { ADM_PROVIDER_ID } from './consts';
import { LeadOpenSea } from '../Models/LeadOpenSea.model';
import { providerContactedLead } from './functions';
import { SearchLeadDto } from '../Services/Api/leads';
import ApiService from '../Services/Api';

const fieldTranslation: any = {
  id: 'Id',
  name: 'Nome',
  document: 'Documento',
  contact: 'Contato',
  email: 'E-mail',
  phoneNumber: 'Telefone',
  address: 'Endereço',
  addressType: 'Tipo de endereço',
  addressNumber: 'Número',
  complement: 'Complemento',
  district: 'Bairro',
  city: 'Cidade',
  state: 'Estado',
  country: 'País',
  zipCode: 'CEP',
  latitude: 'Latitude',
  longitude: 'Longitude',
  dealership: 'Concessionária',
  lastBill1: 'Última conta 1',
  lastBill2: 'Última conta 2',
  lastBill3: 'Última conta 3',
  management: 'Gestão',
  createdAt: 'Data de criação',
  updatedAt: 'Data de atualização',
  providerName: 'Fornecedor',
  contacted: 'Contatado',
  event: 'Evento',
  eventDate: 'Data do evento',
  userName: 'Nome do usuário',
  comment: 'Observação',
  voucher: 'Voucher',
  commercialName: 'Nome Fantasia',
  corporateName: 'Razão Social',
  isMatriz: 'Matriz',
  site: 'Site',
  whatsapp: 'WhatsApp',
  telephone: 'Telefone',
  tab: 'Tab',
  cnpj: 'CNPJ',
  subsegment: 'Subsegmento',
  products: 'Produtos',
};

function deleteUnusedFields(lead: any) {
  const temp: any = {};
  const exclude = [
    'providers',
    'leadLogs',
    'acceptedProductOffers',
    'vouchers',
    'image',
    'logo',
    'isActive',
    'cep',
    'neigborhood',
    'lat',
    'long',
    'number',
    'street',
    'distance',
    'matrizStore',
    'openingHour',
    'isAnalysis',
    'originalSubsidiary',
    'Id',
  ];
  const keys = Object.keys(lead);
  keys.forEach((k) => {
    if (exclude.includes(k)) return;
    temp[k] = lead[k];
  });
  return temp;
}

function localizeFieldNames(lead: any) {
  const temp: any = {};
  const keys = Object.keys(lead);

  keys.forEach((k) => {
    const t = fieldTranslation[k] || k;

    temp[t] = lead[k];
  });

  return temp;
}

function formatLead(lead: any) {
  const keys = Object.keys(lead);
  const temp: any = {};

  keys.forEach((k) => {
    temp[k] = lead[k]?.toString();
  });

  return localizeFieldNames(temp);
}

export async function getAllLeads(params: Partial<SearchLeadDto>,
  onProgress?: (progress:number) => void) {
  let page = 0;
  const offset = 600;

  const { data } = await ApiService.leads.searchLeads({
    ...params,
    page,
    offset,
  });

  if (data.remainingPages === 0) {
    return data.items;
  }

  const leads: Lead[] = [];
  // eslint-disable-next-line prefer-const
  let { remainingPages, currentPage, totalItems } = data;

  const totalPages = Math.ceil(totalItems / offset);

  if (onProgress) {
    onProgress(((currentPage + 1) / totalPages) * 100);
  }

  leads.push(...data.items);

  while (remainingPages > 0) {
    page += 1;
    // eslint-disable-next-line no-await-in-loop
    const { data: tempData } = await ApiService.leads.searchLeads({
      ...params,
      offset,
      page,
    });

    leads.push(...tempData.items);
    remainingPages = tempData.remainingPages;

    if (onProgress) {
      onProgress(((tempData.currentPage + 1) / totalPages) * 100);
    }
  }

  return leads;
}

export function generateReportSheet(leads: Lead[]) {
  const lds: any[] = [];

  leads.forEach((lead) => {
    lead.providers?.forEach((prov) => {
      const contacted = providerContactedLead(lead, prov.id) ? 'SIM' : 'NÃO';
      const tempLead = deleteUnusedFields(lead);

      if (lead.leadLogs?.length === 0) {
        lds.push(formatLead({
          ...tempLead,
          providerName: prov.name,
          contacted,
          event: '',
          eventDate: '',
          userName: '',
          comment: '',
        }));

        return;
      }

      lead.leadLogs?.forEach((log) => {
        if (log.user?.provider?.id === ADM_PROVIDER_ID) {
          const contactedStd = providerContactedLead(lead, ADM_PROVIDER_ID) ? 'SIM' : 'NÃO';
          lds.push(formatLead({
            ...tempLead,
            providerName: log.user.provider.name,
            contacted: contactedStd,
            event: log.logStatus?.name,
            eventDate: log.createdAt,
            userName: log.user.name,
            comment: log.comment,
          }));
          return;
        }

        if (log.user?.provider?.id !== prov.id) return;
        lds.push(formatLead({
          ...tempLead,
          providerName: prov.name,
          contacted,
          event: log.logStatus?.name,
          eventDate: log.createdAt,
          userName: log.user.name,
          comment: log.comment,
        }));
      });
    });
  });

  try {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(lds, { header: Object.keys(lds[0]) });

    XLSX.utils.book_append_sheet(wb, ws, 'Exportação');

    const sheetData = XLSX.write(wb, {
      bookSST: true,
      bookType: 'xlsx',
      type: 'base64',
    });
    return { success: true, data: sheetData };
  } catch (error) {
    return { success: false, data: null };
  }
}

export function generateOpenSeaReportSheet(leads: LeadOpenSea[]) {
  const lds: any[] = leads.map((l) => {
    const voucher = l.vouchers && l.vouchers.length > 0 && l.vouchers[0].code;

    return deleteUnusedFields(formatLead({ ...l, voucher }));
  });

  try {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(lds, { header: Object.keys(lds[0]) });

    XLSX.utils.book_append_sheet(wb, ws, 'Exportação Lojas e filiais');

    const sheetData = XLSX.write(wb, {
      bookSST: true,
      bookType: 'xlsx',
      type: 'base64',
    });
    return { success: true, data: sheetData };
  } catch (error) {
    return { success: false, data: null };
  }
}

export function generateReportStores(list: any[], type?: 'base64' | 'file') {
  const lds: any[] = [];

  list.forEach((stores) => {
    // const contacted = providerContactedLead(stores, prov.id) ? 'SIM' : 'NÃO';
    const tempLead = deleteUnusedFields(stores);

    if (list?.length === 0) return;
    lds.push(formatLead({
      ...tempLead,
      commercialName: stores?.commercialName || '',
      // corporateName: stores?.corporateName || '',
      isMatriz: stores?.isMatriz ? 'Sim' : 'Não' || '',
      subsegment: stores?.matrizStore?.subsegment?.code || '',
      cnpj: stores?.matrizStore?.cnpj || '',
      tab: stores?.matrizStore?.tab || '',
      site: stores?.site || '',
      whatsapp: stores?.whatsapp || '',
      telephone: stores?.telephone || '',
      state: stores?.state || '',
      zipCode: stores?.cep || '',
      city: stores?.city || '',
      district: stores?.neigborhood || '',
      addressType: stores?.address || '',
      address: stores?.street || '',
      addressNumber: stores?.number || '',
      complement: stores?.complement || '',
      latitude: stores?.lat || '',
      longitude: stores?.long || '',
      products: stores?.matrizStore?.storeProducts?.map((a: any) => a.product.name).toString().replace(/ ,|,/g, ',  ') || '',
    }));
  });

  try {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(lds, { header: Object.keys(lds[0]) });

    XLSX.utils.book_append_sheet(wb, ws, 'Exportação');

    if (type === 'file') {
      XLSX.writeFile(wb, 'Exportação.xlsx', {
        bookSST: true,
        bookType: 'xlsx',
      });

      return { success: true, data: null };
    }
    const sheetData = XLSX.write(wb, {
      bookSST: true,
      bookType: 'xlsx',
      type: type || 'base64',
    });
    return { success: true, data: sheetData };
  } catch (error) {
    return { success: false, data: null };
  }
}
