/* eslint-disable no-param-reassign */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Select,
  Checkbox,
  TimePicker,
  message,
  Alert,
  Popover,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { MaskedInput } from 'antd-mask-input';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { Subsegment } from '../../../../Models/Subsegment.model';
import { EconomicGroup } from '../../../../Models/Group.model';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { addressType } from '../../../../Components/Atoms/AddressType';
import GetCepLatLong from '../../../../Components/Molecules/GetLatLngByCep/GetLatLngByCep';
import '../../../../Components/Atoms/global.style.css';

const { Option } = Select;

const containerStyle = {
  width: '400px',
  height: '400px',
  margin: 'auto',
};

const ManagerStoreEdit = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [subsegment, setSubsegments] = useState<Subsegment[]>([]);
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState(values.storeSelected);
  const [products, setProducts] = useState<any>([]);
  const [productsSelected, setProductsSelected] = useState<any>(values.storeSelected?.storeProducts?.map((a: any) => a.product.id));
  const [description, setDescription] = useState(values?.storeSelected?.description?.description);
  const [subsidiary, setSubsidiary] = useState(!!values.storeSelected.subsidiary ? values.storeSelected.subsidiary : {});
  const [cidade, setCidade] = useState(values.storeSelected?.subsidiary.city);
  const [bairro, setBairro] = useState(values.storeSelected?.subsidiary.neigborhood);
  const [logradouro, setLogradouro] = useState(values.storeSelected?.subsidiary.street);
  const [uf, setUf] = useState(values.storeSelected?.subsidiary.state);
  const [cidadePdv, setCidadePdv] = useState(values.storeSelected?.cityPDV);
  const [bairroPdv, setBairroPdv] = useState(values.storeSelected?.neigborhoodPDV);
  const [logradouroPdv, setLogradouroPdv] = useState(values.storeSelected?.streetPDV);
  const [numeroPdv, setNumeroPdv] = useState(values.storeSelected?.numberPDV);
  const [complementoPdv, setComplementoPdv] = useState(values.storeSelected?.complementPDV);
  const [ufPdv, setUfPdv] = useState(values.storeSelected?.statePDV);
  const [cepPdv, setCepPdv] = useState(values.storeSelected?.cepPDV);
  const [lati, setLati] = useState<any>(parseFloat(values.storeSelected.subsidiary?.lat.replace(',', '.')!));
  const [long, setLong] = useState<any>(parseFloat(values.storeSelected.subsidiary?.long.replace(',', '.')!));
  const [usePdv, setUsePdv] = useState(true);
  const [useOnline, setUseOnline] = useState(values.storeSelected.onlineSimulation);
  const [image, setImage] = useState<any>(values.storeSelected.subsidiary?.image);
  const [logo, setLogo] = useState<any>(values.storeSelected.subsidiary?.logo);
  const [sameFileImage, setSameFileImage] = useState(true);
  const [sameFileLogo, setSameFileLogo] = useState(true);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(values.storeSelected?.isActive);

  const hoursSub = store.subsidiary?.openingHour;

  const [weekday, setWeekday] = useState(true);
  const weekdayA = !!values.storeSelected.subsidiary ? hoursSub.find((a: any) => a.order === 0) : false;
  const [InitWeekday, setInitWeekday] = useState<any>(!!weekdayA ? weekdayA.hourInit : false);
  const [FinalWeekday, setFinalWeekday] = useState<any>(!!weekdayA ? weekdayA.hourFinal : false);
  const [saturday, setSaturday] = useState<any>(!!values.storeSelected.subsidiary ? subsidiary?.openingHour.find((a: any) => a.day === 'SABADO' && !!a.hourInit) : false);
  const saturdayA = !!values.storeSelected.subsidiary ? hoursSub.find((a: any) => a.order === 1) : false;
  const [InitASaturday, setInitASaturday] = useState<any>(!!saturdayA ? saturdayA.hourInit : false);
  const [FinalASaturday, setFinalASaturday] = useState<any>(!!saturdayA ? saturdayA.hourFinal : false);
  const [sunday, setSunday] = useState<any>(!!values.storeSelected.subsidiary ? subsidiary?.openingHour.find((a: any) => a.day === 'DOMINGO' && !!a.hourInit) : false);
  const sundayA = !!values.storeSelected.subsidiary ? hoursSub.find((a: any) => a.order === 2) : false;
  const [InitASunday, setInitASunday] = useState<any>(!!sundayA ? sundayA.hourInit : false);
  const [FinalASunday, setFinalASunday] = useState<any>(!!sundayA ? sundayA.hourFinal : false);
  const [holiday, setHoliday] = useState(!!values.storeSelected.subsidiary ? subsidiary?.openingHour.find((a: any) => a.day === 'FERIADO' && !!a.hourInit) : false);
  const holidayA = !!values.storeSelected.subsidiary ? hoursSub.find((a: any) => a.order === 3) : false;
  const [InitAHoliday, setInitAHoliday] = useState<any>(!!holidayA ? holidayA.hourInit : false);
  const [FinalAHoliday, setFinalAHoliday] = useState<any>(!!holidayA ? holidayA.hourFinal : false);
  // const [whats, setWhats] = useState('');

  function mphone(v: any) {
    let r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
      r = r.replace(/^(\d*)/, '($1');
    }
    return r;
  }

  function mask(o: any, f: any) {
    setTimeout(() => {
      const v = mphone(o.value);
      if (v !== o.value) {
        o.value = v;
      }
    }, 1);
  }

  const fileImage = store.subsidiary?.image;
  const fileLogo = store.subsidiary?.logo;

  const content = (
    <div>
      <Space direction="vertical">
        <Alert
          message="Loja ATIVA: O anúncio da sua loja na vitrine do Site do +Vezes está disponível."
          type="success"
          showIcon
        />
        <Alert
          message="Loja INATIVA: O anúncio da sua loja na vitrine do Site do +Vezes não está disponível."
          type="warning"
          showIcon
        />
      </Space>
    </div>
  );

  const center = {
    lat: lati,
    lng: long,
  };

  const hours = [
    {
      day: 'SEMANA',
      hourInit: !!InitWeekday ? InitWeekday : '',
      hourFinal: !!FinalWeekday ? FinalWeekday : '',
      order: 0,
    },
    {
      day: 'SABADO',
      hourInit: !!InitASaturday ? InitASaturday : '',
      hourFinal: !!FinalASaturday ? FinalASaturday : '',
      order: 1,
    },
    {
      day: 'DOMINGO',
      hourInit: !!InitASunday ? InitASunday : '',
      hourFinal: !!FinalASunday ? FinalASunday : '',
      order: 2,
    },
    {
      day: 'FERIADO',
      hourInit: !!InitAHoliday ? InitAHoliday : '',
      hourFinal: !!FinalAHoliday ? FinalAHoliday : '',
      order: 3,
    },
  ];

  const validateMessages = () => {
    setError(true);
    setLoading(false);
    setTimeout(() => { setError(false); }, 10000);
  };

  const onFinish = async (formValues: any) => {
    if (!!InitWeekday && !!FinalWeekday) {
      setLoading(true);
      if (sameFileImage === false && sameFileLogo === true) {
        const formData = new FormData();
        formData.append('image', image || '');
        formData.append('logo', logo || '');
        const extras = JSON.stringify({
          ...formValues,
          // whatsapp: whats,
          idSubsidiary: store.subsidiary?.id,
          id: store?.id,
          originalStore: store?.id,
          isMatriz: true,
          city: cidade,
          neigborhood: bairro,
          street: logradouro,
          state: uf,
          cityPDV: cidadePdv,
          neigborhoodPDV: bairroPdv,
          streetPDV: logradouroPdv,
          numberPDV: numeroPdv,
          complementPDV: complementoPdv,
          statePDV: ufPdv,
          cepPDV: cepPdv,
          lat: lati,
          addressPDV: formValues.addressPDV || formValues.address,
          long,
          logo: '',
          hours,
          isActive: status,
          idDescription: values?.storeSelected?.description?.id,
          onlineSimulation: useOnline,
          parcel: formValues.portion,
        });
        formData.append('extras', extras);
        await ApiService.manager.EditStore(formData);
        setLoading(false);
        history.push('/manager/store/details');
      } else if (sameFileLogo === false && sameFileImage === true) {
        const formData = new FormData();
        formData.append('image', image || '');
        formData.append('logo', logo || '');
        // console.log({
        const extras = JSON.stringify({
          ...formValues,
          idSubsidiary: store.subsidiary?.id,
          id: store?.id,
          originalStore: store?.id,
          isMatriz: true,
          city: cidade,
          neigborhood: bairro,
          street: logradouro,
          state: uf,
          cityPDV: cidadePdv,
          neigborhoodPDV: bairroPdv,
          streetPDV: logradouroPdv,
          numberPDV: numeroPdv,
          complementPDV: complementoPdv,
          statePDV: ufPdv,
          cepPDV: cepPdv,
          lat: lati,
          addressPDV: formValues.addressPDV || formValues.address,
          long,
          image: '',
          hours,
          isActive: status,
          idDescription: values?.storeSelected?.description?.id,
          onlineSimulation: useOnline,
          parcel: formValues.portion,
        });
        formData.append('extras', extras);
        await ApiService.manager.EditStore(formData);
        setLoading(false);
        history.push('/manager/store/details');
      } else {
        const formData = new FormData();
        formData.append('image', image || '');
        formData.append('logo', logo || '');
        // console.log({
        const extras = JSON.stringify({
          ...formValues,
          idSubsidiary: store.subsidiary?.id,
          id: store?.id,
          originalStore: store?.id,
          isMatriz: true,
          city: cidade,
          neigborhood: bairro,
          street: logradouro,
          state: uf,
          cityPDV: cidadePdv,
          neigborhoodPDV: bairroPdv,
          streetPDV: logradouroPdv,
          numberPDV: numeroPdv,
          complementPDV: complementoPdv,
          statePDV: ufPdv,
          cepPDV: cepPdv,
          lat: lati,
          addressPDV: formValues.addressPDV || formValues.address,
          long,
          hours,
          isActive: status,
          idDescription: values?.storeSelected?.description?.id,
          onlineSimulation: useOnline,
          parcel: formValues.portion,
        });
        formData.append('extras', extras);
        await ApiService.manager.EditStore(formData);
        setLoading(false);
        history.push('/manager/store/details');
      }
    } else {
      validateMessages();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data: subsegments } = await ApiService.subsegments.ListSubsegmentsPublic();
      setSubsegments(subsegments);

      const { data: subsegmentsProducts } = await ApiService.stores.listProducts({ subsegmentId: values.storeSelected?.subsegment?.id });
      setProducts(subsegmentsProducts);
    };

    fetchData();
  }, []);

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Lojas | Editar')}
        />
        <Form
          name="create-stores"
          onFinish={onFinish}
          onFinishFailed={validateMessages}
          layout="vertical"
          style={{
            maxWidth: 800,
            margin: 'auto',
          }}
        >
          <Col style={{ marginTop: 20 }}>
            <h2>Atualize as informações da sua empresa</h2>
          </Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label>Status</label>
            <Popover placement="rightTop" content={content}>
              <InfoCircleOutlined className="detail" style={{ marginLeft: 5 }} />
            </Popover>
          </div>
          <Form.Item>
            <Checkbox checked={status} onChange={(event) => setStatus(event.target.checked)}>
              <b>
                {status ? 'Loja Ativa' : 'Loja Inativa'}
              </b>
            </Checkbox>
          </Form.Item>
          {/* <Form.Item
            label="Razão social"
            name="corporateName"
            initialValue={!!values.storeSelected.subsidiary ? subsidiary?.corporateName : ''}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input placeholder="Digite a razão social" />
          </Form.Item> */}
          <Form.Item
            label="Nome Fantasia"
            name="commercialName"
            initialValue={!!values.storeSelected.subsidiary ? subsidiary?.commercialName : ''}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input placeholder="Digite o nome fantasia" />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            initialValue={store?.email}
            rules={[{ required: true, message: 'Campo obrigatório!', type: 'email' }]}
          >
            <Input placeholder="Digite o email da Sua Loja" />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="CNPJ"
              name="cnpj"
              initialValue={store?.cnpj}
              // validateStatus={teste ? 'error' : 'success'}
              // help={teste ? 'Deve conter todos os dígitos!' : ''}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <MaskedInput mask="11.111.111/1111-11" disabled />
            </Form.Item>
            <Form.Item
              label="Código Tab"
              name="tab"
              initialValue={store?.tab}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              <Input disabled />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            initialValue={!!values.storeSelected.subsidiary ? description : ''}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input.TextArea rows={5} maxLength={432} showCount placeholder="Descreva as características da loja" />
          </Form.Item>
          <Form.Item
            label="Site"
            name="site"
            initialValue={!!values.storeSelected.subsidiary ? subsidiary?.site : ''}
          // rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input placeholder="Digite o site, se houver" />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Instagram"
              name="instagram"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.instagram : ''}
            >
              <Input placeholder="Digite o link do Instagram, se houver" />
            </Form.Item>
            <Form.Item
              label="Facebook"
              name="facebook"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.facebook : ''}
            >
              <Input placeholder="Digite o link do Facebook, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Pinterest"
              name="pinterest"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.pinterest : ''}
            >
              <Input placeholder="Digite o link do Pinterest, se houver" />
            </Form.Item>
            <Form.Item
              label="Twitter"
              name="twitter"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.twitter : ''}
            >
              <Input placeholder="Digite o link do PinterestTwitter, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Youtube "
              name="youtube"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.youtube : ''}
            >
              <Input placeholder="Digite o link do Youtube, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Telefone"
              name="telephone"
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.telephone : ''}
              // validateStatus={teste ? 'error' : 'success'}
              // help={teste ? 'Deve conter todos os dígitos!' : ''}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input
                // value={whats}
                onChange={(event) => mask(event.target, mphone)}
              />
            </Form.Item>
            <Form.Item
              label="Whatsapp"
              name="whatsapp"
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.whatsapp : ''}
              // rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              {/* <MaskedInput mask="(11) 11111-1111" /> */}
              <Input
                // value={whats}
                onChange={(event) => mask(event.target, mphone)}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Subsegmento"
              name="subsegmentId"
              initialValue={store?.subsegment.id}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Select
                onChange={async (e) => {
                  const { data: subsegmentsProducts } = await ApiService.stores.listProducts({ subsegmentId: e });
                  setProducts(subsegmentsProducts);
                }}
              >
                {
                  subsegment.map((element) => {
                    return (
                      <Option value={element.id}>{element.name}</Option>
                    );
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="Produtos/Serviços"
              name="productsId"
              initialValue={productsSelected}
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              <Select
                placeholder="Clique e escolha os produtos"
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                defaultValue={productsSelected}
                showArrow
              >
                {
                  products.map((element: any) => {
                    return (
                      <Option value={element.product.id}>{element.product.name}</Option>
                    );
                  })
                }
              </Select>
            </Form.Item>
          </Form.Item>
          <Col style={{ marginTop: 20 }}>
            <h2>Informe os horários de funcionamento da sua loja</h2>
          </Col>
          <Form.Item>
            <Form.Item
              // valuePropName={monday}
              initialValue={weekday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={weekday} onChange={(event) => setWeekday(true)}><b>Segunda a Sexta-Feira</b></Checkbox>
            </Form.Item>
            {
              weekday && (
                <Form.Item>
                  <Form.Item
                    style={{ display: 'inline-block', width: 'calc(33.8%)' }}
                    validateStatus={!!InitWeekday && !!FinalWeekday ? '' : 'error'}
                    help={!!InitWeekday && !!FinalWeekday ? '' : 'Campo obrigatório'}
                  >
                    <TimePicker.RangePicker
                      format="HH:mm"
                      defaultValue={InitWeekday
                        ? [moment(InitWeekday, 'HH:mm'), moment(FinalWeekday, 'HH:mm')]
                        : [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]}
                      onChange={(event, timeString) => {
                        setInitWeekday(timeString[0]);
                        setFinalWeekday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }
            <Form.Item
              valuePropName="checked"
              initialValue={saturday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={saturday} onChange={(event) => setSaturday(event.target.checked)}><b>Sábado</b></Checkbox>
            </Form.Item>
            {
              saturday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      defaultValue={InitASaturday
                        ? [moment(InitASaturday, 'HH:mm'), moment(FinalASaturday, 'HH:mm')]
                        : [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]}
                      onChange={(event, timeString) => {
                        setInitASaturday(timeString[0]);
                        setFinalASaturday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }

            <Form.Item
              valuePropName="checked"
              initialValue={sunday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={sunday} onChange={(event) => setSunday(event.target.checked)}><b>Domingo</b></Checkbox>
            </Form.Item>
            {
              sunday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      defaultValue={InitASunday
                        ? [moment(InitASunday, 'HH:mm'), moment(FinalASunday, 'HH:mm')]
                        : [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]}
                      onChange={(event, timeString) => {
                        setInitASunday(timeString[0]);
                        setFinalASunday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }
            <Form.Item
              valuePropName="checked"
              initialValue={holiday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={holiday} onChange={(event) => setHoliday(event.target.checked)}><b>Feriado</b></Checkbox>
            </Form.Item>
            {
              holiday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      defaultValue={InitAHoliday
                        ? [moment(InitAHoliday, 'HH:mm'), moment(FinalAHoliday, 'HH:mm')]
                        : [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]}
                      onChange={(event, timeString) => {
                        setInitASunday(timeString[0]);
                        setFinalASunday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }
          </Form.Item>
          {/* <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="image"
              label="Foto"
              valuePropName="Foto"
              initialValue={store.subsidiary?.image}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <div>

                {image && (
                  <div>
                    <img
                      src={
                        // image === fileLogo
                        image === fileImage
                          ? `https://assets.energiamaisvezes.com.br.s3.amazonaws.com/subsidiary/${store.subsidiary?.image}`
                          : URL.createObjectURL(image)
                      }
                      style={{
                        height: 160,
                        borderRadius: 4,
                        marginBottom: 20,
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )}

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  name="image"
                  id="image-or-video"
                  type="file"
                  onChange={(event) => {
                    if (event.target.files) {
                      setImage(event.target.files[0]);
                      setSameFileImage(false);
                    }
                  }}
                />
                <label htmlFor="image-or-video" className="select-image">
                  Selecionar Imagem
                </label>
              </div>
            </Form.Item>
            <Form.Item
              name="logo"
              label="Logo"
              initialValue={store.subsidiary?.logo}
              valuePropName="Logo"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              <div>

                {logo && (
                  <div>
                    <img
                      src={
                        // image === fileImage
                        logo === fileLogo
                          ? `https://assets.energiamaisvezes.com.br.s3.amazonaws.com/subsidiary/${store.subsidiary?.logo}`
                          : URL.createObjectURL(logo)
                      }
                      style={{
                        height: 160,
                        borderRadius: 4,
                        marginBottom: 20,
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )}

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  name="logo"
                  id="image-or-video-logo"
                  type="file"
                  onChange={(event) => {
                    if (event.target.files) {
                      setLogo(event.target.files[0]);
                      setSameFileLogo(false);
                    }
                  }}
                />
                <label htmlFor="image-or-video-logo" className="select-image">
                  Selecionar Logo
                </label>
              </div>
            </Form.Item>
          </Form.Item> */}
          <Col style={{ marginTop: 20 }}>
            <h2>Informe o endereço da sua loja matriz</h2>
          </Col>
          <Form.Item
            label="CEP"
            name="cep"
            initialValue={!!values.storeSelected.subsidiary ? subsidiary?.cep : ''}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <MaskedInput
              mask="11111-111"
              onChange={async (element) => {
                let cep: any = parseInt(element.target.value.replace('-', ''), 10);
                if (JSON.stringify(cep).length < 8) {
                  cep = `0${JSON.stringify(cep)}`;
                } else {
                  cep = `${JSON.stringify(cep)}`;
                }
                if (cep.length === 8) {
                  const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
                  setCidade(data.localidade);
                  setBairro(data.bairro);
                  setLogradouro(data.logradouro);
                  setUf(data.uf);
                  if (usePdv) {
                    setCepPdv(JSON.stringify(cep));
                    setUfPdv(data.uf);
                    setCidadePdv(data.localidade);
                    setBairroPdv(data.bairro);
                    setLogradouroPdv(data.logradouro);
                  }
                  const LatLng = await GetCepLatLong(cep);
                  if (LatLng) {
                    setLati(LatLng.results[0].geometry.location.lat);
                    setLong(LatLng.results[0].geometry.location.lng);
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Estado"
            name="state"
            valuePropName={uf}
            initialValue={!!values.storeSelected.subsidiary ? values.storeSelected.subsidiary?.state : ''}
            rules={[{ required: !uf, message: 'Campo obrigatório!' }]}
          >
            <Input value={uf} readOnly />
          </Form.Item>
          <Form.Item
            label="Cidade"
            name="city"
            valuePropName={cidade}
            initialValue={!!values.storeSelected.subsidiary ? values.storeSelected.subsidiary?.city : ''}
            rules={[{ required: !cidade, message: 'Campo obrigatório!' }]}
          >
            <Input
              value={cidade}
              onChange={(event) => {
                setCidade(event.target.value);
                if (usePdv) {
                  setCidadePdv(event.target.value);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Bairro"
            name="neigborhood"
            valuePropName={bairro}
            initialValue={!!values.storeSelected.subsidiary ? values.storeSelected.subsidiary?.neigborhood : ''}
            rules={[{ required: !bairro, message: 'Campo obrigatório!' }]}
          >
            <Input
              value={bairro}
              onChange={(event) => {
                setBairro(event.target.value);
                if (usePdv) {
                  setBairroPdv(event.target.value);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Logradouro (tipo)"
            name="address"
            initialValue={!!values.storeSelected.subsidiary ? values.storeSelected.subsidiary?.address : ''}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
            style={{ textTransform: 'capitalize' }}
          >
            <Select showSearch>
              {
                addressType.map((element) => {
                  return (
                    <Option style={{ textTransform: 'capitalize' }} value={element.value}>{element.value}</Option>
                  );
                })
              }
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Logradouro"
              name="street"
              valuePropName={logradouro}
              initialValue={!!values.storeSelected.subsidiary ? values.storeSelected.subsidiary?.street : ''}
              rules={[{ required: !logradouro, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input
                placeholder="Digite o endereço da sua loja"
                value={logradouro}
                onChange={(event) => {
                  setLogradouro(event.target.value);
                  if (usePdv) {
                    setLogradouroPdv(event.target.value);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Número"
              name="number"
              initialValue={!!values.storeSelected.subsidiary ? values.storeSelected.subsidiary?.number : ''}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(20% - 8px)', margin: '0 0 0 8px' }}
            >
              <Input
                placeholder="Digite o nº"
                onChange={(event) => {
                  if (usePdv) {
                    setNumeroPdv(event.target.value);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Complemento"
              name="complement"
              initialValue={!!values.storeSelected.subsidiary ? values.storeSelected.subsidiary?.complement : ''}
              style={{ display: 'inline-block', width: 'calc(30%)', margin: '0 0 0 8px' }}
            >
              <Input
                placeholder="Ex: apt, bloco, torre."
                onChange={(event) => {
                  if (usePdv) {
                    setComplementoPdv(event.target.value);
                  }
                }}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Latitude"
              name="lat"
              // validateStatus={teste ? 'error' : 'success'}
              // help={teste ? 'Deve conter todos os dígitos!' : ''}
              valuePropName={lati}
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.lat : ''}
              rules={[{ required: !lati, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input
                readOnly
                type="number"
                value={lati}
                onChange={(event) => setLati(Number(event.target.value))}
              />
            </Form.Item>
            <Form.Item
              label="Longitude"
              name="long"
              valuePropName={long}
              initialValue={!!values.storeSelected.subsidiary ? subsidiary?.long : ''}
              rules={[{ required: !long, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              <Input
                readOnly
                type="number"
                value={long}
                onChange={(event) => setLong(Number(event.target.value))}
              />
            </Form.Item>
          </Form.Item>
          <LoadScript
            googleMapsApiKey="AIzaSyB9HUirJIS34PJTyYpgjhIVijyBZEX3_qg"
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onClick={(event) => {
                setLati(event.latLng?.lat());
                setLong(event.latLng?.lng());
              }}
            >
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>
          <Form.Item
            style={{ marginTop: 20 }}
            name="usePDV"
            valuePropName="checked"
            initialValue={usePdv}
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <Checkbox onChange={(event) => setUsePdv(event.target.checked)}><b>Utilizar o endereço da Matriz para recebimento de materiais de apoio de vendas e campanhas (PDV)? ATENÇÃO, você poderá selecionar apenas um endereço para o CNPJ credenciado.</b></Checkbox>
          </Form.Item>
          {
            !usePdv && (
              <>
                <Col style={{ marginTop: 20 }}>
                  <h2>Preencha aqui o endereço para o recebimento do material de PDV</h2>
                </Col>
                <Form.Item
                  label="CEP - PDV"
                  name="cepPDV"
                  valuePropName={cepPdv}
                  initialValue={store?.cepPDV}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                >
                  <MaskedInput
                    mask="11111-111"
                    value={cepPdv}
                    onChange={async (element) => {
                      let cep: any = parseInt(element.target.value.replace('-', ''), 10);
                      if (JSON.stringify(cep).length < 8) {
                        cep = `0${JSON.stringify(cep)}`;
                      } else {
                        cep = `${JSON.stringify(cep)}`;
                      }
                      if (cep.length === 8) {
                        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
                        setCidadePdv(data.localidade);
                        setBairroPdv(data.bairro);
                        setLogradouroPdv(data.logradouro);
                        setUfPdv(data.uf);
                        setCepPdv(element.target.value);
                        const LatLng = await GetCepLatLong(cep);
                        if (LatLng) {
                          setLati(LatLng.results[0].geometry.location.lat);
                          setLong(LatLng.results[0].geometry.location.lng);
                        }
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Estado - PDV"
                  name="statePDV"
                  valuePropName={ufPdv}
                  initialValue={store?.statePDV}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                >
                  <Input value={ufPdv} readOnly />
                </Form.Item>
                <Form.Item
                  label="Cidade - PDV"
                  name="cityPDV"
                  valuePropName={cidadePdv}
                  initialValue={store?.cityPDV}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                >
                  <Input value={cidadePdv} onChange={(event) => setCidadePdv(event.target.value)} />
                </Form.Item>
                <Form.Item
                  label="Bairro - PDV"
                  name="neigborhoodPDV"
                  valuePropName={bairroPdv}
                  initialValue={store?.neigborhoodPDV}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                >
                  <Input value={bairroPdv} onChange={(event) => setBairroPdv(event.target.value)} />
                </Form.Item>
                <Form.Item
                  label="Logradouro (tipo) - PDV"
                  name="addressPDV"
                  initialValue={store?.addressPDV}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                  style={{ textTransform: 'capitalize' }}
                >
                  <Select showSearch>
                    {
                      addressType.map((element) => {
                        return (
                          <Option style={{ textTransform: 'capitalize' }} value={element.value}>{element.value}</Option>
                        );
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Form.Item
                    label="Logradouro - PDV"
                    name="streetPDV"
                    valuePropName={logradouroPdv}
                    initialValue={store?.streetPDV}
                    rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                  >
                    <Input value={logradouroPdv} onChange={(event) => setLogradouroPdv(event.target.value)} />
                  </Form.Item>
                  <Form.Item
                    label="Número - PDV"
                    name="numberPDV"
                    valuePropName={numeroPdv}
                    initialValue={store?.numberPDV}
                    rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    style={{ display: 'inline-block', width: 'calc(20% - 8px)', margin: '0 0 0 8px' }}
                  >
                    <Input value={numeroPdv} onChange={(event) => setNumeroPdv(event.target.value)} />
                  </Form.Item>
                  <Form.Item
                    label="Complemento - PDV"
                    name="complementPDV"
                    valuePropName={complementoPdv}
                    initialValue={store?.complementPDV}
                    style={{ display: 'inline-block', width: 'calc(30%)', margin: '0 0 0 8px' }}
                  >
                    <Input value={complementoPdv} onChange={(event) => setComplementoPdv(event.target.value)} />
                  </Form.Item>
                </Form.Item>
              </>
            )
          }
          <Form.Item>
            <Checkbox checked={useOnline} onChange={(event) => setUseOnline(event.target.checked)}><b>Habilitar opção de simulação online para o cliente final.</b></Checkbox>
          </Form.Item>
          {!!useOnline && (
            <Form.Item
              label="Quantidade máxima de parcelas"
              name="portion"
              initialValue={store?.parcels[0]?.qtdParcel}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input
                type="number"
                style={{ width: '10%' }}
              />
            </Form.Item>
          )}
          {
            error && (
              <>
                <Form.Item style={{
                  display: 'flex', textAlignLast: 'center', marginTop: 15,
                }}
                >
                  <Alert style={{ maxWidth: '60%', margin: 'auto', padding: '2px 10px' }} message="Há campos obrigatórios que faltam ser preenchidos!" type="error" showIcon />
                </Form.Item>
              </>
            )
          }
          <Form.Item style={{ display: 'flex', textAlignLast: 'center', marginTop: 15 }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit" style={{ width: 130, flex: 1 }}>
                Salvar
              </Button>
              <Button loading={loading} style={{ width: 130, flex: 1 }}>
                <Link to="/manager/store/details">
                  Cancelar
                </Link>
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ManagerStoreEdit;
