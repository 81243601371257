import React, { useState } from 'react';
import { Space, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  FileSearchOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import ModalSelectStatus from '../../Organisms/ModalSelectStatus';
import { Lead } from '../../../Models/Lead.model';

interface OnFinish {
  (): void;
}

type TableActionButtonsProps = {
  onDetails?: string;
  onFinishPress?: OnFinish;
  disableFinishButton?: boolean;
  lead?: Lead;
};

const TableActionButtons = ({
  onDetails,
  onFinishPress,
  disableFinishButton,
  lead,
}: TableActionButtonsProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="action-button-cell">
      <Space
        size="middle"
        className="inner-cell"
        direction="vertical"
        style={{ alignItems: 'stretch' }}
      >
        {!!onDetails && (
          <Button size="small" style={{ width: '100%' }}>
            <Link to={onDetails} title={t('Detalhes')}>
              {t('Detalhes')}
              {' '}
              <FileSearchOutlined />
            </Link>
          </Button>
        )}

        <Button
          size="small"
          onClick={() => {
            setShowModal(true);
          }}
          disabled={disableFinishButton}
        >
          {t('Adicionar evento')}
          {' '}
          <PlusCircleOutlined />
        </Button>
      </Space>
      <ModalSelectStatus
        visible={showModal}
        lead={lead}
        onOk={async (newLeadState) => {
          try {
            //
          } catch (error) {
            //
          } finally {
            setShowModal(false);
          }
        }}
        modalProps={{
          onCancel: () => {
            setShowModal(false);
          },
        }}
      />
    </div>
  );
};

TableActionButtons.defaultProps = {
  onDetails: '',
  disableFinishButton: true,
  onFinishPress: () => {},
  lead: undefined,
  // onEdit: '',
  // onDelete: false,
};

export default TableActionButtons;
