/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';

const EconomicGroupEdit = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [economicGroup, setEconomicGroup] = useState<any>(values.groupsEdit.id);
  const [stores, setStores] = useState<any>([]);
  const [selectedStores, setSelectedStores] = useState(values.groupsEdit.storeSelected.map((a: any) => a.id));
  const [loading, setLoading] = useState(false);

  interface ItemProps {
    label: string;
    value: number;
  }
  const [options, setOptions] = useState<ItemProps[]>([]);

  const onFinish = async (formValues: any) => {
    setLoading(true);
    await ApiService.groups.EditEconomicGroup({ id: economicGroup.id, name: formValues.name, econmicGroup: formValues.econmicGroup });
    setLoading(false);
    history.push('/economics-groups');
  };

  const listSomeStores = async (search: any) => {
    if (search.length >= 3) {
      const { data } = await ApiService.stores.ListStores({ search });
      setStores(data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await ApiService.stores.listStoresByGroup({ economicGroup: values.groupsEdit.id });
      setSelectedStores(data);
      setStores(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setOptions(
        stores.map((e: any) => {
          return (
            {
              label: `${e.tab} - ${e.commercialName}`,
              value: e.id,
            }
          );
        }),
      );
    };

    fetchData();
  }, [stores]);

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Grupos Econômicos | Editar')}
        />

        <Form
          name="edit-subsegment"
          onFinish={onFinish}
          layout="vertical"
          style={{
            maxWidth: 700,
            margin: 'auto',
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            initialValue={economicGroup.name}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Lojas"
            name="econmicGroup"
            initialValue={selectedStores}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Select
              placeholder="Digite o TAB"
              value={selectedStores}
              defaultValue={selectedStores}
              options={options}
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="label"
              onChange={(event) => {
                setSelectedStores(event);
              }}
              onSearch={(element) => {
                listSomeStores(element);
              }}
            />
          </Form.Item>
          <Form.Item style={{ display: 'flex', textAlignLast: 'center' }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit" style={{ width: 150, flex: 1 }}>
                Salvar
              </Button>
              <Button loading={loading} style={{ width: 150, flex: 1 }}>
                <Link to="//economics-groups">
                  Cancelar
                </Link>
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default EconomicGroupEdit;
