import { ApiClient } from './client';

interface AuthUserDto {
  email: string;
  password: string;
}

interface AuthUserResponse {
  token: string;
  ok: boolean;
  error: any;
  language: string;
  username: string;
  id: string;
  type: string;
}

export function authUser({ email, password }: AuthUserDto) {
  return ApiClient.post<AuthUserResponse>('/admin/auth', { email, password });
}

export function authUserShopkeeper({ email, password }: AuthUserDto) {
  return ApiClient.post<AuthUserResponse>('/admin/auth/shopkeeper', { email, password });
}

export function authUserManager({ email, password }: AuthUserDto) {
  return ApiClient.post<AuthUserResponse>('/admin/auth/manager', { email, password });
}

export function createUser(body: any) {
  return ApiClient.post<AuthUserResponse>('/admin/users/manage', body);
}

export function recoverPassword(lang:string, email:string) {
  return ApiClient.get(`admin/auth/recover?lang=${lang}&email=${email}`);
}

export function registerNewPassword(body: any) {
  return ApiClient.post('admin/auth/register-password', body);
}

export function listExpirePassword() {
  return ApiClient.get<any>('admin/auth');
}

export async function logout(tokenToLogout: string) {
  if (!tokenToLogout) return;

  await ApiClient.post<any>('admin/auth/logout', null, {
    headers: {
      Authorization: `Bearer ${tokenToLogout}`,
    },
  });
}
