import React, { useContext, useState, useEffect } from 'react';
import {
  Modal, Col, Row, Dropdown, Button, Menu, Input, Divider,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../../Base/Providers/Data.context';
import { LogStatus } from '../../../Models/LogStatus.model';

interface Values {
  selectedStatus?: LogStatus;
  comment?: string;
}

interface ModalBodyProps {
  onValueChange?: (values: Values | undefined) => void
}

function ModalBody(props: ModalBodyProps) {
  const { onValueChange } = props;
  const { values } = useContext(DataContext);
  const { t } = useTranslation();
  const [drop, setDrop] = useState<LogStatus>({ id: -1, name: t('Selecionar...') });
  const [comment, setComment] = useState<string>();

  const dropdownStatus = (
    <Menu>
      {values.status.map((st) => (
        <Menu.Item
          key={`menu-drop-${st.id}`}
          onClick={(e) => {
            setDrop(st);
          }}
        >
          {st.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    if (onValueChange) {
      onValueChange({
        selectedStatus: drop,
        comment,
      });
    }
  }, [drop, comment]);

  return (
    <Col>
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        arrow
        overlay={dropdownStatus}
      >
        <Button
          type="ghost"
          icon={<CaretDownOutlined />}
          size="large"
        >
          {drop.name}
        </Button>
      </Dropdown>

      <Divider />

      <Input.TextArea
        rows={4}
        placeholder={t('Observação')}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
    </Col>
  );
}

ModalBody.defaultProps = {
  onValueChange: () => {},
};

export default ModalBody;
