/* eslint-disable no-alert */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Col,
  Row,
  Table,
  Divider,
  Button,
  Popconfirm,
  Space,
  Input,
  DatePicker,
  Alert,
  Popover,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  CheckCircleFilled, ExclamationCircleFilled, FilterOutlined, InfoCircleOutlined, LoadingOutlined, PlusOutlined,
} from '@ant-design/icons';
import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import Marquee from 'react-fast-marquee';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { Subsidiary } from '../../../../Models/Subisidiary.model';
import { generateReportStores } from '../../../../Helpers/sheet';

interface FilterCategoriesState {
  textSearch: string | undefined
  startDate: Moment | undefined
  finishDate: Moment | undefined
}

const { Search } = Input;

const initialFilterState: FilterCategoriesState = {
  textSearch: undefined,
  startDate: undefined,
  finishDate: undefined,
};

const ShopkeeperMenu = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [store, setStore] = useState(values.storeSelected);
  const [description, setDescription] = useState(values?.storeSelected?.description?.description);
  const [loading, setLoading] = useState(true);
  const [filteredSusidiaries, setFilteredSubsidiaries] = useState<Subsidiary[]>([]);
  const [filterState, setFilterState] = useState<FilterCategoriesState>(initialFilterState);
  const [page, setPage] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);

  const [semana, setSemana] = useState([]);
  const [sabado, setSabado] = useState([]);
  const [domingo, setDomingo] = useState([]);
  const [feriado, setFeriado] = useState([]);
  const rangePickerRef = useRef<any>();

  const content = (
    <div style={{ maxWidth: 900 }}>
      <Space direction="vertical">
        <Alert
          message="Loja ATIVA: O anúncio da sua loja na vitrine do Site do +Vezes está disponível para a visualização dos clientes finais."
          type="success"
          showIcon
        />
        <Alert
          message="Loja INATIVA: O anúncio da sua loja na vitrine do Site do +Vezes não está disponível para a visualização dos clientes finais. Caso deseja torná-lo ATIVO, procure o seu Consultor+ ou Gerente de Relacionamento para realizar a reativação."
          type="warning"
          showIcon
        />
      </Space>
    </div>
  );

  const columns = [
    {
      title: t('Nome Fantasia'),
      dataIndex: 'name',
      render: (_: unknown, record: Subsidiary) => `${record.commercialName}`,
      sorter: (a: Subsidiary, b: Subsidiary) => a.commercialName.localeCompare(b.commercialName),
    },
    {
      title: t('Data de criação'),
      dataIndex: 'createdAt',
      render: (text: string) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
      defaultSortOrder: 'descend' as 'descend',
      sorter: (a: Subsidiary, b: Subsidiary) => moment(a.createdAt).diff(b.createdAt),
    },
    {
      title: t('Ações'),
      dataIndex: 'action',
      render: (text: string, record: Subsidiary) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            size="small"
            style={{ width: '100%' }}
            onClick={async () => {
              if (store?.email) {
                actions.setSubisidiryEdit(record || []);
                history.push('/shopkeeper-options/subsidiary/edit');
              } else {
                alert('Para continuar adicione o email da matriz através do botão "Editar Loja" no topo da página');
              }
            }}
          >
            Editar
          </Button>
          <Popconfirm
            title="Excluir"
            onConfirm={async () => {
              if (store?.email) {
                try {
                  setLoading(true);
                  await ApiService.subsidiaries.DeleteSubsidiarieByShopkeeper(record.id);
                  const { data } = await ApiService.subsidiaries.ListShopkeeperSubsidiaries({ id: store?.id!, search: filterState.textSearch });
                  actions.setSubisidiries(data || []);

                  setFilteredSubsidiaries(data);
                } catch (error) {
                  actions.setSubisidiries([]);
                } finally {
                  setLoading(false);
                }
              } else {
                alert('Para continuar adicione o email da matriz através do botão "Editar Loja" no topo da página');
              }
            }}
          >
            <Button type="primary" size="small" style={{ width: '100%' }}>
              Excluir
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const resetFilterState = () => {
    setFilterState(initialFilterState);
  };

  const fetchUserList = async () => {
    try {
      setLoading(true);

      const cnpj = sessionStorage.getItem('shopkeeperLoged');

      const { data: cnpjStore } = await ApiService.stores.ListStoreByCnpj({ cnpj: cnpj!.replace('.', '').replace('.', '').replace('/', '').replace('-', '') });
      setStore(cnpjStore);
      setDescription(cnpjStore?.description?.description);
      setSemana(cnpjStore?.subsidiary.openingHour.filter((a: any) => a.day === 'SEMANA'));
      setSabado(cnpjStore?.subsidiary.openingHour.filter((a: any) => a.day === 'SABADO'));
      setDomingo(cnpjStore?.subsidiary.openingHour.filter((a: any) => a.day === 'DOMINGO'));
      setFeriado(cnpjStore?.subsidiary.openingHour.filter((a: any) => a.day === 'FERIADO'));

      const { data } = await ApiService.subsidiaries.ListShopkeeperSubsidiaries({ id: cnpjStore?.id!, search: filterState.textSearch });
      actions.setSubisidiries(data || []);

      setFilteredSubsidiaries(data);
    } catch (error) {
      actions.setSubisidiries([]);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    setFilteredSubsidiaries(values.subisidiries || []);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filterState, values.stores]);

  return (
    <Row style={{ justifyContent: 'center' }}>
      {store?.id && (
        <Col style={{ overflow: 'auto', backgroundColor: 'white', padding: '0 10px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: 'white',
              borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
            }}
          >
            <Row gutter={[16, 16]}>
              <Row style={{ maxWidth: 1024, margin: 'auto' }}>
                <Col span={24}>
                  <h2 style={{ fontSize: '28px', marginBottom: 0 }}>
                    Seja bem vindo ao Painel Lojista!
                  </h2>
                  <p>
                    O acesso exclusivo onde você pode atualizar a localização, contatos, horário de funcionamento e filiais da sua loja e estar visível aos clientes finais no site do +Vezes e Portal Cliente Final.
                  </p>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>

                    <h2 style={{ fontSize: '22px', marginBottom: 0 }}>{store?.commercialName}</h2>

                  </Row>
                  <Col style={{ marginBottom: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p>Status</p>
                      <Popover placement="rightTop" content={content}>
                        <InfoCircleOutlined className="detail" style={{ marginLeft: 5 }} />
                      </Popover>
                    </div>
                    {
                      store?.isActive ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <CheckCircleFilled style={{
                            display: 'flex', flex: 1, fontSize: 18, color: '#52c41a', maxWidth: 24,
                          }}
                          />
                          <strong>Loja Ativa</strong>
                        </div>
                      )
                        : (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ExclamationCircleFilled style={{
                              display: 'flex', flex: 1, fontSize: 18, color: '#a8071a', maxWidth: 24,
                            }}
                            />
                            <strong>Loja Inativa</strong>
                          </div>
                        )
                    }
                  </Col>
                  <Col style={{ marginBottom: 25 }}>
                    <p>
                      <strong>
                        Se seu status estiver INATIVO e você deseja torná-lo ATIVO, procure o seu Consultor+ ou Gerente de Relacionamento para realizar a reativação.
                      </strong>
                    </p>
                  </Col>
                </Col>

                <Col span={24} style={{ textAlign: 'end', marginBottom: 20 }}>
                  <Space>
                    <Button
                      style={{ padding: '0 30px' }}
                      size="large"
                      type="primary"
                      onClick={async () => {
                        const { data: storeSubsidiary } = await ApiService.stores.findStoreWithSubsidiaryByShopkeeper(store?.id!);
                        if (!!storeSubsidiary) {
                          actions.setStoreSelected(storeSubsidiary || []);
                          history.push('/shopkeeper-options/edit');
                        }
                      }}
                    >
                      Atualizar dados
                    </Button>

                    <Button
                      style={{ padding: '0 30px' }}
                      size="large"
                      type="primary"
                      onClick={async () => {
                        const { data } = await ApiService.subsidiaries.ListAllSubsidiariesShopkeeper({ cnpj: store?.cnpj! });
                        const result = generateReportStores(data);
                        if (result.success) {
                          const base64Excel = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.data}`;
                          const elem = document.getElementById('download-exportacao') as HTMLLinkElement;
                          if (!elem) {
                            window.location.href = base64Excel;
                          } else {
                            elem.href = base64Excel;
                            elem.click();
                          }
                        }
                      }}
                    >
                      Exportar
                    </Button>
                    <a id="download-exportacao" style={{ display: 'none' }} href="/" download>Fazer Download</a>
                  </Space>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    {/* <p>{`Nome Fantasia: ${store?.commercialName || ''}`}</p> */}
                    <p>{`Subsegmento: ${store?.subsegment.code} - ${store?.subsegment.name || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`CNPJ: ${store?.cnpj || ''}`}</p>
                    <p>{`Código TAB: ${store?.tab || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Telefone: ${store?.subsidiary?.telephone || ''}`}</p>
                    <p>{`WhatsApp: ${store?.subsidiary?.whatsapp || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`E-mail: ${store?.email || ''}`}</p>
                    <p>{`Site: ${store?.site || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Descrição: ${description || ''}`}</p>
                  </Row>
                </Col>

                <Divider />

                <Col span={24}>
                  <h2 className="alignP" style={{ fontSize: '28px' }}>Endereço</h2>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Cep: ${store?.subsidiary.cep || ''}`}</p>
                    <p>{`Estado: ${store?.subsidiary.state || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Cidade: ${store?.subsidiary.city || ''}`}</p>
                    <p>{`Bairro: ${store?.subsidiary.neigborhood || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Logradouro (tipo): ${store?.subsidiary.address || ''}`}</p>
                    <p>{`Logradouro: ${store?.subsidiary.street || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Número: ${store?.subsidiary.number || ''}`}</p>
                    <p>{`complemento: ${store?.subsidiary.complement || ''}`}</p>
                  </Row>
                </Col>

                {
                  !!store?.subsidiary.openingHour && (
                    <>
                      <Divider />
                      <Col span={24}>
                        <h2 className="alignP" style={{ fontSize: '28px' }}>Horário de funcionamento</h2>
                      </Col>
                      {
                        store?.subsidiary.openingHour.some((a: any) => a.day === 'SEMANA') && (
                          <Col span={7}>
                            <p>Segunda a Sexta-Feira:</p>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              {
                                semana.map((a: any) => {
                                  return (
                                    <>
                                      <p>{`${a.hourInit}/`}</p>
                                      <p style={{ marginRight: 20 }}>{`${a.hourFinal}`}</p>
                                    </>
                                  );
                                })
                              }
                            </div>
                          </Col>
                        )
                      }
                      {
                        store?.subsidiary.openingHour.some((a: any) => a.day === 'SABADO') && (
                          <Col span={7}>
                            <p>Sabado:</p>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              {
                                sabado.map((a: any) => {
                                  return (
                                    <>
                                      <p>{`${a.hourInit}/`}</p>
                                      <p style={{ marginRight: 20 }}>{`${a.hourFinal}`}</p>
                                    </>
                                  );
                                })
                              }
                            </div>
                          </Col>
                        )
                      }
                      {
                        store?.subsidiary.openingHour.some((a: any) => a.day === 'DOMINGO') && (
                          <Col span={7}>
                            <p>Domingo:</p>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              {
                                domingo.map((a: any) => {
                                  return (
                                    <>
                                      <p>{`${a.hourInit}/`}</p>
                                      <p style={{ marginRight: 20 }}>{`${a.hourFinal}`}</p>
                                    </>
                                  );
                                })
                              }
                            </div>
                          </Col>
                        )
                      }
                      {
                        store?.subsidiary.openingHour.some((a: any) => a.day === 'FERIADO') && (
                          <Col span={7}>
                            <p>Feriado:</p>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              {
                                feriado.map((a: any) => {
                                  return (
                                    <>
                                      <p>{`${a.hourInit}/`}</p>
                                      <p style={{ marginRight: 20 }}>{`${a.hourFinal}`}</p>
                                    </>
                                  );
                                })
                              }
                            </div>
                          </Col>
                        )
                      }
                    </>
                  )
                }

                <Col span={24}>
                  <h2 className="alignP" style={{ fontSize: '28px' }}>Endereço PDV</h2>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Cep: ${store?.cepPDV || ''}`}</p>
                    <p>{`Estado: ${store?.statePDV || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Cidade: ${store?.cityPDV || ''}`}</p>
                    <p>{`Bairro: ${store?.neigborhoodPDV || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Logradouro (tipo): ${store?.addressPDV || ''}`}</p>
                    <p>{`Logradouro: ${store?.streetPDV || ''}`}</p>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                    <p>{`Número: ${store?.numberPDV || ''}`}</p>
                    <p>{`complemento: ${store?.complementPDV || ''}`}</p>
                  </Row>
                </Col>

                <Divider />

                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={10}>
                      <h2 className="alignP" style={{ fontSize: '28px' }}>Filiais</h2>
                    </Col>
                    <Row align="middle" justify="space-between" style={{ justifyContent: 'center' }}>
                      <Button
                        style={{ marginRight: 10 }}
                        type="primary"
                        size="large"
                        onClick={() => {
                          actions.setStoreSelected(store || []);
                          history.push('/shopkeeper-options/subsidiary/add');
                        }}
                      >
                        Adicionar Filiais em Lote
                      </Button>
                      <Button
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          if (store?.email) {
                            actions.setMatrizId(store?.id);
                            history.push('/shopkeeper-options/subsidiary/edit');
                          } else {
                            alert('Para continuar adicione o email da matriz através do botão "Editar Loja" no topo da página');
                          }
                        }}
                      >
                        Adicionar Filial
                      </Button>
                    </Row>
                  </Row>

                  <Col>
                    <p>
                      Você poderá incluir outros pontos de venda que operam no +Vezes através do mesmo CNPJ da filial. Atenção! Os pontos de venda adicionais das filiais não podem ter credenciamento independente.
                    </p>
                  </Col>

                  <div className="align-items-center justify-content-end d-flex mx-3 mt-4 mb-2">
                    <Search
                      onSearch={(e) => {
                        setFilterState({
                          ...filterState,
                          textSearch: e,
                        });

                        if (page === 1) {
                          fetchUserList();
                        } else {
                          setPage(1);
                        }
                      }}
                      enterButton
                      allowClear
                      placeholder={t('Digite o nome da filial para buscar ')}
                      size="large"
                      onChange={(e) => setFilterState({
                        ...filterState,
                        textSearch: e.target.value,
                      })}
                    />

                    <Button
                      type="primary"
                      icon={<FilterOutlined />}
                      size="large"
                      onClick={() => setOpenFilter((op) => !op)}
                    />

                  </div>

                  {openFilter && (
                    <Row align="middle" justify="start" style={{ padding: '1rem' }}>
                      <Col style={{ marginRight: '1rem' }}>
                        <DatePicker.RangePicker
                          ref={rangePickerRef}
                          size="large"
                          locale={locale}
                          onCalendarChange={(calendarValues) => {
                            if (!calendarValues) {
                              setFilterState({
                                ...filterState,
                                startDate: undefined,
                                finishDate: undefined,
                              });
                              return;
                            }

                            const [startDate, finishDate] = calendarValues as Array<Moment>;

                            setFilterState({
                              ...filterState,
                              startDate,
                              finishDate,
                            });
                          }}
                        />
                      </Col>

                      <Col>
                        <Button
                          size="large"
                          onClick={resetFilterState}
                        >
                          Limpar Filtros
                        </Button>
                      </Col>
                    </Row>
                  )}

                  {loading ? (
                    <>
                      <Row align="middle" justify="center">
                        <Col style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        >
                          <LoadingOutlined style={{ fontSize: 60 }} />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={filteredSusidiaries || []}
                      loading={false}
                      pagination={{
                        position: ['bottomCenter'],
                        onChange: (event) => { setPage(event); },
                        current: page,
                        pageSize: 10,
                        onShowSizeChange: ((c) => {
                          setPage(c);
                        }),
                      }}
                    />
                  )}

                </Col>
              </Row>

            </Row>
          </Content>
        </Col>
      )}
    </Row>
  );
};

export default ShopkeeperMenu;
