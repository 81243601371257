import React, { useContext, useEffect } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { FormInputProps } from '../Components/Molecules/InputDefault';
import { TemplateLogin, TemplateLoginProps } from '../Components/Templates/TemplateLogin';
import { AuthContext } from '../Base/Providers/Auth.context';
import { recaptcha } from '../Helpers/recaptcha';
import ApiService from '../Services/Api';

const getFields = (t: TFunction): FormInputProps[] => ([
  {
    name: 'email',
    rules: [{
      required: true,
      message: t('Informe um e-mail válido'),
    }],
    inputProps: {
      type: 'email',
      placeholder: 'Digite seu email',
      // placeholder: t('E-mail'),
      prefix: <MailOutlined />,
    },
  },

  {
    name: 'password',
    rules: [{
      required: true,
      message: t('Informe sua senha'),
    }],
    inputProps: {
      type: 'password',
      placeholder: 'Digite sua senha',
      // placeholder: t('Senha'),
      prefix: <LockOutlined />,
    },
  },
]);

interface ILogin { email: string; password: string; }

interface ILoginResponse {
  auth: {
    status: 'REQUIRED_2FA' | 'OK',
    email?: string;
    token?: string;
  }
}

const PageLogin = () => {
  const { t } = useTranslation();
  const { actions } = useContext(AuthContext);

  const fields: TemplateLoginProps<ILogin> = {
    onSubmit: async (model) => {
      try {
        const { status, data } = await ApiService.auth.authUser(model);

        if (status === 201 && data.ok) {
          actions.doLogin(data.token);
          sessionStorage.setItem(process.env.REACT_APP_LOGIN_TYPE!, 'ADMIN');
        } else {
          message.error({
            content: 'Login inválido!',
            style: {
              color: '#CC0000',
            },
          });
        }
      } catch (error) {
        //
      }
    },
    loading: false,
    fields: getFields(t),
    title: t('Efetue seu login'),
    actionButtons: {
      // leftButton: {
      //   title: t('Esqueci minha senha'),
      //   url: '/forgot-password',
      // },
      submitButton: {
        title: t('Entrar'),
      },
    },
  };

  return (
    <TemplateLogin
      {...fields}
    />
  );
};

export default PageLogin;
