/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Col,
  Row,
  Table,
  Divider,
  Button,
  Popconfirm,
  Space,
  Input,
  DatePicker,
  Alert,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import SectionTitle from '../../../../../Components/Molecules/SectionTitle';
import { DataContext } from '../../../../../Base/Providers/Data.context';
import './Leads-Detail.style.css';
import { DateHelper } from '../../../../../Helpers/date.helper';

const LeadsPlusDetails = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [leads, setLeads] = useState(values.leadsCalculo);

  return (
    <Row>
      <Col style={{ overflow: 'auto', backgroundColor: 'white' }}>

        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: 'white',
            borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
          }}
        >

          <SectionTitle
            title={t('Prospecta + | Leads')}
          />

          <Row gutter={[16, 16]}>
            <Row>
              {/* <Col span={24}>
                <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>

                  <h2 className="alignP" style={{ fontSize: '22px', marginBottom: 0 }}>{leads?.commercialName || ''}</h2>
                </Row>
              </Col> */}

              <Col span={22}>
                <Row align="middle">
                  {/* <p className="alignP">{`Nome Fantasia: ${store?.commercialName || ''}`}</p> */}
                  <p className="alignP">{`Nome: ${leads?.leadName || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Email: ${leads?.leadEmail || ''}`}</p>
                  <p className="alignP">{`Telefone: ${leads?.leadPhone || ''}`}</p>
                  <p className="alignP">{`CNPJ: ${leads?.leadCnpj || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Subsegmento: ${leads?.subsegmento.name || ''}`}</p>
                </Row>
              </Col>

              <Divider />

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Ticket: ${leads?.ticket || ''}`}</p>
                  <p className="alignP">{`Prazo: ${leads?.prazo || ''}`}</p>
                  <p className="alignP">{`Prazo Mais Vendido: ${leads?.prazoMaisVendido || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Caixa Proprio: ${leads?.caixaProprio || ''}`}</p>
                  <p className="alignP">{`Caixa Antecipa: ${leads?.cartaoAntecipa || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Carência: ${leads?.carencia || ''}`}</p>
                  <p className="alignP">{`Prazo de Carência: ${leads?.carenciaPrazo || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`CDC Tradicional: ${leads?.cdcTrad || ''}`}</p>
                  <p className="alignP">{`CDC Subsidiado: ${leads?.cdcSubsi || ''}`}</p>
                  <p className="alignP">{`CSC: ${leads?.csc || ''}`}</p>
                </Row>
              </Col>

              <Divider />

              <Col span={24}>
                <Row align="middle">
                  <p>Com Entrada</p>
                  <br />
                  <br />
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Coeficiente: ${leads?.comEntradaCoeficiente || ''}`}</p>
                  <p className="alignP">{`Retenção: ${leads?.comEntradaRetencao || ''}`}</p>
                  <p className="alignP">{`Valor Recebido: ${leads?.comEntradaValorRecebido || ''}`}</p>
                </Row>
              </Col>

              <Divider />

              <Col span={24}>
                <Row align="middle">
                  <p>Sem Entrada</p>
                  <br />
                  <br />
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Coeficiente: ${leads?.semEntradaCoeficiente || ''}`}</p>
                  <p className="alignP">{`Retenção: ${leads?.semEntradaRetencao || ''}`}</p>
                  <p className="alignP">{`Valor Recebido: ${leads?.semEntradaValorRecebido || ''}`}</p>
                </Row>
              </Col>

              {leads && leads.user && (
                <>
                  <Divider />
                  <Col span={22}>
                    <Row align="middle">
                      <p className="alignP">{`Criado Por: ${leads?.user?.name || ''}`}</p>
                    </Row>
                    <Row align="middle">
                      <p className="alignP">{`Email: ${leads?.user?.email || ''}`}</p>
                    </Row>
                    {leads?.createdAt && (
                    <Row align="middle">
                      <p className="alignP">{`Data de criação: ${DateHelper.fromStringFormatted(leads.createdAt)}`}</p>
                    </Row>
                    )}
                  </Col>
                </>
              )}

              {/* <Divider />

              <Col span={24}>
                <Row align="middle">
                  <h2 style={{ fontSize: '28px' }}>Endereço</h2>
                </Row>
              </Col> */}
            </Row>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default LeadsPlusDetails;
