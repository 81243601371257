/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button, Col, Form, Input, Row, Space,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { add } from 'date-fns';
import { DataContext } from '../../Base/Providers/Data.context';
import ApiService from '../../Services/Api';
import { AuthContext } from '../../Base/Providers/Auth.context';
import DefaultLogo from '../../Components/Atoms/DefaultLogo';

const PageHome = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { actions } = useContext(AuthContext);
  const { values } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [day, setDay] = useState(90);

  const userUpdate = add(new Date(values.currentUser?.updatedAt), { days: day });
  const today = (new Date());

  const onFinish = async (formValues: any) => {
    setLoading(true);
    const userEdited = await ApiService.manager.postAdminUser(values.currentUser?.id, { ...values.currentUser, password, firstLogin: false });
    const { status, data } = await ApiService.auth.authUser({ email: values.currentUser?.email!, password });
    actions.doLogin(data.token);
    sessionStorage.setItem(process.env.REACT_APP_LOGIN_TYPE!, 'ADMIN');
    // actions.setUser({ ...values.currentUser!, firstLogin: false });
    setLoading(false);
  };

  useEffect(() => {
    const fetchUserList = async () => {
      const { data } = await ApiService.auth.listExpirePassword();
      if (data && data?.length > 0) setDay(data[0].days);
    };
    fetchUserList();
  }, []);

  const teste = () => {
    if (userUpdate < today) {
      return (
        <Row
          justify="center"
          align="middle"
          className="px-1"
          style={{
            minHeight: '80vh', padding: 10, display: 'flex', flexDirection: 'column',
          }}
        >
          <DefaultLogo />
          <div style={{ width: '100%', maxWidth: '550px', minWidth: 300 }} className="inner-content p-4 bg-white radius-4">
            <Col span={24}>
              <h4 style={{ fontSize: 20, color: '#CC0000', textAlignLast: 'start' }}>Senha expirada</h4>
              <div style={{ marginBottom: 15 }}>
                <p style={{
                  color: '#CC0000', textAlignLast: 'start',
                }}
                >Sua senha expirou, digite uma nova senha.
                </p>
              </div>
              <Form
                name="create-economics-groups"
                onFinish={onFinish}
                layout="vertical"
                style={{
                  maxWidth: 700,
                  margin: 'auto',
                }}
              >
                <Form.Item
                  // label="Nova Senha"
                  name="password"
                  valuePropName={password}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    placeholder="Nova Senha"
                    style={{
                      textAlignLast: 'start', padding: 5, display: 'flex', maxWidth: '100%',
                    }}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  // label="Repetir Nova Senha"
                  name="repeatPassword"
                  valuePropName={repeatPassword}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    placeholder="Repetir Nova Senha"
                    style={{ textAlignLast: 'start', padding: 5 }}
                    type="password"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                  />
                </Form.Item>
                {
                  !(password === repeatPassword
                    && password.length >= 6
                    && repeatPassword.length >= 6) && (
                    <Alert
                      style={{ marginBottom: 15 }}
                      message="As senhas devem ser iguais e conter pelo menos 6 dígitos."
                      type="info"
                      showIcon
                    />
                  )
                }
                <Form.Item className="teste" style={{ textAlignLast: 'end' }}>
                  <Button
                    disabled={!(password === repeatPassword
                      && password.length >= 6
                      && repeatPassword.length >= 6)}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Alterar Senha
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </div>
        </Row>
      );
    } else if (values.currentUser?.isManager && values.currentUser?.firstLogin) {
      return (
        <Row
          justify="center"
          align="middle"
          className="px-1"
          style={{
            minHeight: '80vh', padding: 10, display: 'flex', flexDirection: 'column',
          }}
        >
          <DefaultLogo />
          <div style={{ width: '100%', maxWidth: '550px', minWidth: 300 }} className="inner-content p-4 bg-white radius-4">
            <Col span={24}>
              <h4 style={{ fontSize: 20, color: '#CC0000', textAlignLast: 'start' }}>Alterar Senha - Primeiro Acesso</h4>
              <Form
                name="create-economics-groups"
                onFinish={onFinish}
                layout="vertical"
                style={{
                  maxWidth: 700,
                  margin: 'auto',
                }}
              >

                <Form.Item
                  // label="Nova Senha"
                  name="password"
                  valuePropName={password}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    placeholder="Nova Senha"
                    style={{
                      textAlignLast: 'start', padding: 5, display: 'flex', maxWidth: '100%',
                    }}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  // label="Repetir Nova Senha"
                  name="repeatPassword"
                  valuePropName={repeatPassword}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    placeholder="Repetir Nova Senha"
                    style={{ textAlignLast: 'start', padding: 5 }}
                    type="password"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                  />
                </Form.Item>
                {
                  !(
                    values.currentUser?.firstLogin
                    && password === repeatPassword
                    && password.length >= 6
                    && repeatPassword.length >= 6) && (
                    <Alert
                      style={{ marginBottom: 15 }}
                      message="As senhas devem ser iguais e conter pelo menos 6 dígitos."
                      type="info"
                      showIcon
                    />
                  )
                }
                <Form.Item className="teste" style={{ textAlignLast: 'end' }}>
                  <Button
                    disabled={!(password === repeatPassword
                      && password.length >= 6
                      && repeatPassword.length >= 6)}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Alterar Senha
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </div>
        </Row>
      );
    } else {
      return (
        <div>
          <h4 className="text-center mt-5 mb-4">{t('Seja bem vindo ao Painel Administrativo')}</h4>

          <p className="text-center">
            {t('No painel ao lado você pode escolher o tema que deseja acessar. ')}
          </p>
        </div>
      );
    }
  };

  return (
    <Layout>

      <Content
        style={{
          display: 'flex',
          placeContent: 'center',
          textAlignLast: 'center',
          marginTop: 30,
        }}
      >
        {teste()}
      </Content>
    </Layout>
  );
};

export default PageHome;
