import { Subsegment } from '../../Models/Subsegment.model';
import { ApiClient } from './client';

export function ListSubsegments() {
  return ApiClient.get<Subsegment[]>('/admin/subsegments/manage');
}

export function ListSubsegmentsPublic() {
  return ApiClient.get<Subsegment[]>('/public/clients/subsegments');
}

export function ListSubsegmentsSelected(id: number) {
  return ApiClient.get<Subsegment[]>(`/admin/subsegments/manage/selected/${id}`);
}

export function DeleteSubsegments(id: number) {
  return ApiClient.httpDelete<Subsegment>(`/admin/subsegments/manage/delete/${id}`);
}

export function CreateSubsegments(body: any) {
  return ApiClient.post<Subsegment>('/admin/subsegments/manage/create', body);
}

export function EditSubsegment(body: any) {
  return ApiClient.post<Subsegment>('/admin/subsegments/manage/edit', body);
}

export function ListAllProducts() {
  return ApiClient.get<Subsegment[]>('/admin/subsegments/manage/products');
}

export function ListProductsBySubsegment(id: number) {
  return ApiClient.get<Subsegment[]>(`/admin/subsegments/manage/products-selected/${id}`);
}

export function DeleteProductFromSubsegment(id: number) {
  return ApiClient.httpDelete<any>(`/admin/subsegments/manage/delete-product/${id}`);
}

export function CreateProduct(body: any) {
  return ApiClient.post<any>('/admin/subsegments/manage/product/create', body);
}

export function EditProduct(body: any) {
  return ApiClient.post<any>('/admin/subsegments/manage/product/edit', body);
}

export function ListFeeBySubsgment(id: number) {
  return ApiClient.get<Subsegment>(`/admin/subsegments/manage/fee-selected/${id}`);
}

export function ListLeadsCalculo(body: any) {
  return ApiClient.post<any>('/admin/subsegments/manage/leads-calculo', body);
}

export function ListMyLeadsCalculo(body: any) {
  return ApiClient.post<any>('/admin/subsegments/manage/leads-meu-calculo', body);
}

export function DeleteFee(id: number) {
  return ApiClient.httpDelete<any>(`/admin/subsegments/manage/delete-fee/${id}`);
}

export function CreateFee(body: any) {
  return ApiClient.post<any>('/admin/subsegments/manage/fee/create', body);
}

export function EditFee(body: any) {
  return ApiClient.post<any>('/admin/subsegments/manage/fee/edit', body);
}

export function FeesAttOrImport(body: any) {
  return ApiClient.post('/admin/subsegments/manage/fee/att-import-fees', body);
}

export function FeesDeleteMany(body: any) {
  return ApiClient.post('/admin/subsegments/manage/fee/delete-many', body);
}
