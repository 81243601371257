/* eslint-disable max-len */
import React, {
  useContext, useEffect, useReducer, Reducer,
} from 'react';
import { Category } from '../../Models/Category.model';
import { EconomicGroup } from '../../Models/Group.model';
import { Lead } from '../../Models/Lead.model';
import { LeadOpenSea } from '../../Models/LeadOpenSea.model';
import { LogStatus } from '../../Models/LogStatus.model';
import { Provider } from '../../Models/Provider.model';
import { Store } from '../../Models/Store.model';
import { Subsidiary } from '../../Models/Subisidiary.model';
import { Subsegment } from '../../Models/Subsegment.model';
import { User } from '../../Models/User.model';
import ApiService from '../../Services/Api';
import { AuthContext } from './Auth.context';

export type ContextDispatchProps = {
  setUsers: (users: User[]) => void
  setLeads: (leads: Lead[]) => void
  setCategories: (categories: Category[]) => void
  setCategoryToEdit: (categoryEdit: Category) => void
  setSubsegmentCategoryToEdit: (subsegCategoryEdit: any) => void
  setSubsegments: (subsegments: Subsegment[]) => void
  setSubsegmentToEdit: (subsegmentEdit: Subsegment) => void
  setGroups: (groups: EconomicGroup[]) => void
  setGroupToEdit: (group: any) => void
  setStores: (stores: Store[]) => void
  setStoreSelected: (stores: any) => void
  setSubisidiries: (subisidiries: any[]) => void
  setSubisidiryEdit: (subisidiry: any) => void
  setUser: (user: User) => void
  setProviders: (providers: Provider[]) => void
  setStatus: (status: LogStatus[]) => void
  setLeadsOpenSea: (leads: LeadOpenSea[]) => void
  setProducts: (products: any) => void
  setLeadsCalculo: (products: any) => void
  setMatrizId: (id?: number) => void
};

enum DataActionTypes {
  SET_USERS = 'SET_USERS',
  SET_LEADS = 'SET_LEADS',
  SET_USER = 'SET_USER',
  SET_PROVIDERS = 'SET_PROVIDERS',
  SET_STATUS = 'SET_STATUS',
  SET_LEADS_OPEN_SEA = 'SET_LEADS_OPEN_SEA',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_CATEGORY_EDIT = 'SET_CATEGORY_EDIT',
  SET_SUBSEGMENT_CATEGORY = 'SET_SUBSEGMENT_CATEGORY',
  SET_SUBSEGMENTS = 'SET_SUBSEGMENTS',
  SET_SUBSEGMENT_EDIT = 'SET_SUBSEGMENT_EDIT',
  SET_GROUPS = 'SET_GROUPS',
  SET_GROUPS_EDIT = 'SET_GROUPS_EDIT',
  SET_STORES = 'SET_STORES',
  SET_STORE_SELECTED = 'SET_STORE_SELECTED',
  SET_SUBSIDIARIES = 'SET_SUBSIDIARIES',
  SET_SUBSIDIARY_EDIT = 'SET_SUBSIDIARY_EDIT',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_LEADS_CALCULO = 'SET_LEADS_CALCULO',
  SET_MATRIZ_ID = 'SET_MATRIZ_ID',
}

type ContextProps = {
  values: DataState;
  actions: ContextDispatchProps
};

type Action = {
  type: DataActionTypes;
  payload: any;
};

type DataState = {
  users: User[];
  leads: Lead[];
  categories: Category[];
  categoryEdit?: Category,
  subsegmentCatSelected?: any;
  subsegments: Subsegment[];
  subsegmentEdit?: Subsegment;
  groups: EconomicGroup[];
  groupsEdit?: any;
  stores: Store[];
  storeSelected?: any;
  subisidiries: Subsidiary[];
  subsidiaryEdit?: any;
  providers: Provider[];
  status: LogStatus[];
  leadsOpenSea: LeadOpenSea[];
  currentUser: User | undefined;
  products: any;
  leadsCalculo: any;
  currentMatrizId?: number;
};

type DataReducer = Reducer<DataState, Action>;

const initialState: DataState = {
  users: [],
  leads: [],
  categories: [],
  subsegments: [],
  groups: [],
  stores: [],
  subisidiries: [],
  providers: [],
  status: [],
  currentUser: undefined,
  leadsOpenSea: [],
  products: [],
  leadsCalculo: undefined,
  currentMatrizId: undefined,
};

export const DataContext = React.createContext({
  values: {},
} as ContextProps);

export const DataProvider = ({ children }: { children: any }) => {
  const { value: userToken, actions } = useContext(AuthContext);
  const [state, dispatch] = useReducer<DataReducer, DataState>(
    (prevState, action) => {
      switch (action.type) {
        case DataActionTypes.SET_USERS:
          return {
            ...prevState,
            users: action.payload,
          };

        case DataActionTypes.SET_LEADS:
          return {
            ...prevState,
            leads: action.payload,
          };

        case DataActionTypes.SET_USER:
          return {
            ...prevState,
            currentUser: action.payload,
          };

        case DataActionTypes.SET_PROVIDERS:
          return {
            ...prevState,
            providers: action.payload,
          };

        case DataActionTypes.SET_STATUS:
          return {
            ...prevState,
            status: action.payload,
          };

        case DataActionTypes.SET_LEADS_OPEN_SEA:
          return {
            ...prevState,
            leadsOpenSea: action.payload,
          };

        case DataActionTypes.SET_CATEGORIES:
          return {
            ...prevState,
            categories: action.payload,
          };

        case DataActionTypes.SET_CATEGORY_EDIT:
          return {
            ...prevState,
            categoryEdit: action.payload,
          };

        case DataActionTypes.SET_SUBSEGMENT_CATEGORY:
          return {
            ...prevState,
            subsegmentCatSelected: action.payload,
          };

        case DataActionTypes.SET_SUBSEGMENTS:
          return {
            ...prevState,
            subsegments: action.payload,
          };

        case DataActionTypes.SET_SUBSEGMENT_EDIT:
          return {
            ...prevState,
            subsegmentEdit: action.payload,
          };

        case DataActionTypes.SET_GROUPS:
          return {
            ...prevState,
            groups: action.payload,
          };

        case DataActionTypes.SET_GROUPS_EDIT:
          return {
            ...prevState,
            groupsEdit: action.payload,
          };

        case DataActionTypes.SET_STORES:
          return {
            ...prevState,
            stores: action.payload,
          };

        case DataActionTypes.SET_STORE_SELECTED:
          return {
            ...prevState,
            storeSelected: action.payload,
          };

        case DataActionTypes.SET_SUBSIDIARIES:
          return {
            ...prevState,
            subisidiries: action.payload,
          };

        case DataActionTypes.SET_SUBSIDIARY_EDIT:
          return {
            ...prevState,
            subsidiaryEdit: action.payload,
          };

        case DataActionTypes.SET_PRODUCTS:
          return {
            ...prevState,
            products: action.payload,
          };

        case DataActionTypes.SET_LEADS_CALCULO:
          return {
            ...prevState,
            leadsCalculo: action.payload,
          };

        case DataActionTypes.SET_MATRIZ_ID:
          return {
            ...prevState,
            currentMatrizId: action.payload,
          };

        default:
          return prevState;
      }
    },
    initialState,
    () => initialState,
  );

  const setUsers = (users: User[]) => dispatch({ type: DataActionTypes.SET_USERS, payload: users });
  const setLeads = (leads: Lead[]) => dispatch({ type: DataActionTypes.SET_LEADS, payload: leads });
  const setCategories = (categories: Category[]) => dispatch({ type: DataActionTypes.SET_CATEGORIES, payload: categories });
  const setCategoryToEdit = (category: Category) => dispatch({ type: DataActionTypes.SET_CATEGORY_EDIT, payload: category });
  const setSubsegmentCategoryToEdit = (subsegCategory: any) => dispatch({ type: DataActionTypes.SET_SUBSEGMENT_CATEGORY, payload: subsegCategory });
  const setSubsegments = (subsegments: Subsegment[]) => dispatch({ type: DataActionTypes.SET_SUBSEGMENTS, payload: subsegments });
  const setSubsegmentToEdit = (subsegment: Subsegment) => dispatch({ type: DataActionTypes.SET_SUBSEGMENT_EDIT, payload: subsegment });
  const setGroups = (groups: EconomicGroup[]) => dispatch({ type: DataActionTypes.SET_GROUPS, payload: groups });
  const setGroupToEdit = (group: any) => dispatch({ type: DataActionTypes.SET_GROUPS_EDIT, payload: group });
  const setStores = (stores: Store[]) => dispatch({ type: DataActionTypes.SET_STORES, payload: stores });
  const setStoreSelected = (store: any) => dispatch({ type: DataActionTypes.SET_STORE_SELECTED, payload: store });
  const setSubisidiries = (subsidiaries: any[]) => dispatch({ type: DataActionTypes.SET_SUBSIDIARIES, payload: subsidiaries });
  const setSubisidiryEdit = (subsidiary: any) => dispatch({ type: DataActionTypes.SET_SUBSIDIARY_EDIT, payload: subsidiary });
  const setUser = (user: User) => dispatch({ type: DataActionTypes.SET_USER, payload: user });
  const setProviders = (providers: Provider[]) => dispatch({ type: DataActionTypes.SET_PROVIDERS, payload: providers });
  const setStatus = (status: LogStatus[]) => dispatch({ type: DataActionTypes.SET_STATUS, payload: status });
  const setLeadsOpenSea = (leads: LeadOpenSea[]) => dispatch({ type: DataActionTypes.SET_LEADS_OPEN_SEA, payload: leads });
  const setProducts = (products: LeadOpenSea[]) => dispatch({ type: DataActionTypes.SET_PRODUCTS, payload: products });
  const setLeadsCalculo = (leadsCalculo: LeadOpenSea[]) => dispatch({ type: DataActionTypes.SET_LEADS_CALCULO, payload: leadsCalculo });
  const setMatrizId = (id?: number) => dispatch({ type: DataActionTypes.SET_MATRIZ_ID, payload: id });

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        const { data: user } = await ApiService.users.getMe();
        setUser(user);
      } catch (error) {
        actions.doLogout();
      }
    };

    if (userToken) {
      getCurrentUser();
    }
  }, [userToken]);

  return (
    <DataContext.Provider value={{
      values: state,
      actions: {
        setUsers,
        setLeads,
        setCategories,
        setCategoryToEdit,
        setUser,
        setProviders,
        setStatus,
        setLeadsOpenSea,
        setSubsegmentCategoryToEdit,
        setSubsegments,
        setSubsegmentToEdit,
        setGroups,
        setGroupToEdit,
        setStores,
        setStoreSelected,
        setSubisidiries,
        setSubisidiryEdit,
        setProducts,
        setLeadsCalculo,
        setMatrizId,
      },
    }}
    >
      {children}
    </DataContext.Provider>
  );
};
