/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/indent */
import React, {
  FC, useCallback, useEffect, useState,
  useContext,
} from 'react';
import { Menu, Popover } from 'antd';
import {
  FundProjectionScreenOutlined,
  TeamOutlined,
  CalendarOutlined,
  ShopOutlined,
  ProfileOutlined,
  SolutionOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Sider from 'antd/lib/layout/Sider';
import { useTranslation } from 'react-i18next';
import { Header } from 'antd/lib/layout/layout';
import { Link, useLocation } from 'react-router-dom';
import './SidebarMenu.less';
import './SidebarMenu.css';

import ReactTooltip from 'react-tooltip';

import { DataContext } from '../../../Base/Providers/Data.context';
import { getHomepage } from '../../../Helpers/functions';

const { SubMenu } = Menu;

const content1 = (
  <div style={{ maxWidth: 900 }}>
    <p>
      Grupo de filiais de uma única empresa
    </p>
  </div>
);
const content2 = (
  <div style={{ maxWidth: 900 }}>
    <p>
      Cadastre informações da loja para aparecer no Site +Vezes e portal cliente final
    </p>
  </div>
);
const content3 = (
  <div style={{ maxWidth: 900 }}>
    <p>
      Cadastre ou atualize as categorias e os respectivos subsegmentos exibidos no Site +Vezes e portal cliente final
    </p>
  </div>
);
const content4 = (
  <div style={{ maxWidth: 900 }}>
    <p>
      Cadastre ou atualize os subsegmentos de Bens e Serviços
    </p>
  </div>
);

const getMenuItems = (t: (val: string) => string, isMaster?: boolean) => {
  const subitems = [];

  // if (!isManager) {
  subitems.push({
    title: t('Leads'),
    slug: 'leads',
  });
  // }

  if (isMaster) {
    subitems.push({
      title: t('Mar Aberto'),
      slug: 'opensealeads',
    });
  }

  // if (!isManager) {
  return [
    {
      title: t('Energia +'),
      slug: 'manage',
      icon: <FundProjectionScreenOutlined />,
      subitems,
    },
  ];
  // }
  // return [];
};

const getMenuItemsTwo = (t: (val: string) => string, isMaster?: boolean) => {
  const subitems = [];

  if (isMaster) {
    subitems.push({
      title: t('Grupo Econômico'),
      slug: 'economics-groups',
      info: 'Grupo de filiais de uma única empresa',
    },
      {
        title: t('Lojas'),
        slug: 'stores',
        info: 'Cadastre informações da loja para aparecer no Site +Vezes e portal cliente final',
      },
      {
        title: t('Categorias'),
        slug: 'categories',
        info: 'Cadastre ou atualize as categorias e os respectivos subsegmentos exibidos no Site +Vezes e portal cliente final',
      },
      {
        title: t('Subsegmentos'),
        slug: 'subsegments',
        info: 'Cadastre ou atualize os subsegmentos de Bens e Serviços',
      });
  }
  if (isMaster) {
    return [
      {
        title: t('Painel Lojista'),
        slug: 'shopkeeper-panel',
        icon: <ShopOutlined />,
        subitems,
      },
    ];
  }
  return [];
};

const getShopkeeperItem = (t: (val: string) => string, isShopkeeper?: boolean) => {
  if (isShopkeeper) {
    return [
      {
        title: t('Menu do Lojista'),
        slug: 'shopkeeper-options',
        icon: <ProfileOutlined />,
      },
    ];
  }
  return [];
};

const ModerationPanel = (t: (val: string) => string, isMaster?: boolean) => {
  if (isMaster) {
    return [
      {
        title: t('Moderação'),
        slug: 'moderation/list',
        icon: <SolutionOutlined />,
      },
    ];
  }
  return [];
};

const ManagerPanel = (t: (val: string) => string, isManager?: boolean) => {
  if (isManager) {
    // const ManagerPanel = (t: (val: string) => string, isMaster?: boolean) => {
    //   if (isMaster) {
    return [
      {
        title: t('Painel Lojista'),
        slug: 'manager/store',
        icon: <ShopOutlined />,
      },
    ];
  }
  return [];
};

const ManagerModerationPanel = (t: (val: string) => string, isManager?: boolean) => {
  if (isManager) {
    // const ManagerModerationPanel = (t: (val: string) => string, isMaster?: boolean) => {
    //   if (isMaster) {
    return [
      {
        title: t('Moderação'),
        slug: 'manager/moderation',
        icon: <SolutionOutlined />,
      },
    ];
  }
  return [];
};

const getConsultorMais = (t: (val: string) => string, isMaster?: boolean, leads?: boolean, fees?: boolean, myLeads?: boolean) => {
  const subitems = [];

  if (leads || isMaster || myLeads) {
    subitems.push({
      title: t('Leads'),
      slug: 'consultant-plus/leads',
      info: 'Cadastre informações da loja para aparecer no Site +Vezes e portal cliente final',
    });
  }

  if (fees || isMaster) {
    subitems.push({
      title: t('Taxas'),
      slug: 'fees',
      info: 'Grupo de filiais de uma única empresa',
    });
  }
  if (leads || fees || isMaster || myLeads) {
    return [
      {
        title: t('Prospecta +'),
        slug: 'consultant-plus',
        icon: <ShopOutlined />,
        subitems,
      },
    ];
  }
  return [];
};

const SidebarMenu: FC = () => {
  const { t } = useTranslation();
  const { values } = useContext(DataContext);

  const location = useLocation();
  const path = (location.pathname || '').slice(1);

  const [submenuKey, setSubmenuKey] = useState('');
  const [submenuKeyTwo, setSubmenuKeyTwo] = useState('');

  const findSubmenuKey = useCallback(() => {
    const item = getMenuItems(
      t, values.currentUser?.isMaster,
    ).find((menu) => {
      const currentItem = menu.subitems.find((opt) => opt.slug === path);

      return currentItem || false;
    });

    setSubmenuKey((item || { slug: '' }).slug);
  }, []);

  const findSubmenuKeyTwo = useCallback(() => {
    const item = getMenuItemsTwo(t).find((menu) => {
      const currentItem = menu.subitems.find((opt) => opt.slug === path);

      return currentItem || false;
    });

    setSubmenuKey((item || { slug: '' }).slug);
  }, []);

  useEffect(() => {
    findSubmenuKey();
    findSubmenuKeyTwo();
  }, [location]);

  return (
    <Sider
      width={275}
      className="SidebarMenu"
      breakpoint="lg"
      collapsedWidth="0"
      style={{}}
    // style={{
    //   overflow: 'auto',
    //   height: '100vh',
    //   position: 'fixed',
    //   left: 0,
    // }}
    >
      <Link to="/" title="Home">
        <Header className="logo d-flex justify-content-center align-items-center">
          <img
            src={`${getHomepage()}/images/logo.svg`}
            style={{ maxWidth: '250px' }}
            alt="Logo"
          />
        </Header>
      </Link>

      <Menu
        mode="inline"
        theme="light"
        forceSubMenuRender
        selectedKeys={[path]}
        defaultOpenKeys={[submenuKey]}
      >
        {
          !values.currentUser?.isShopkeeper && !values.currentUser?.isManager && (
            getMenuItems(t, values.currentUser?.isMaster).map((item) => (item.subitems.length ? (
              <SubMenu key={item.slug} title={item.title} icon={item.icon}>
                {item.subitems.map((subitem) => (
                  <Menu.Item key={subitem.slug}>
                    <Link title={subitem.title} to={`/${subitem.slug}`}>{subitem.title}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.slug} icon={item.icon}>
                <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
              </Menu.Item>
            )))
          )
        }
        {
          values.currentUser?.isMaster && !values.currentUser.isShopkeeper && (
            getMenuItemsTwo(t, values.currentUser?.isMaster).map((item) => (item.subitems.length ? (
              <SubMenu key={item.slug} title={item.title} icon={item.icon}>
                {/* {item.subitems.map((subitem) => ( */}
                <div className="itemMenu">
                  <Menu.Item key="economics-groups">
                    <Link
                      title="Grupo Econômico"
                      to="/economics-groups"
                      style={{
                        color: 'rgba(0, 0, 0, 0.85)',
                      }}
                    >
                      Grupo Econômico
                    </Link>
                  </Menu.Item>
                  <Popover placement="rightTop" content={content1}>
                    <InfoCircleOutlined className="itemMenuInfo" style={{ marginLeft: 5 }} />
                  </Popover>
                </div>
                <div className="itemMenu">
                  <Menu.Item key="stores">
                    <Link
                      title="Lojas"
                      to="/stores"
                      style={{
                        color: 'rgba(0, 0, 0, 0.85)',
                      }}
                    >
                      Lojas
                    </Link>
                  </Menu.Item>
                  <Popover placement="rightTop" content={content2}>
                    <InfoCircleOutlined className="itemMenuInfo" style={{ marginLeft: 5 }} />
                  </Popover>
                </div>
                <div className="itemMenu">
                  <Menu.Item key="categories">
                    <Link
                      title="Categorias"
                      to="/categories"
                      style={{
                        color: 'rgba(0, 0, 0, 0.85)',
                      }}
                    >
                      Categorias
                    </Link>
                  </Menu.Item>
                  <Popover placement="rightTop" content={content3}>
                    <InfoCircleOutlined className="itemMenuInfo" style={{ marginLeft: 5 }} />
                  </Popover>
                </div>
                <div className="itemMenu">
                  <Menu.Item key="subsegments">
                    <Link
                      title="Subsegmentos"
                      to="/subsegments"
                      style={{
                        color: 'rgba(0, 0, 0, 0.85)',
                      }}
                    >
                      Subsegmentos
                    </Link>
                  </Menu.Item>
                  <Popover placement="rightTop" content={content4}>
                    <InfoCircleOutlined className="itemMenuInfo" style={{ marginLeft: 5 }} />
                  </Popover>
                </div>
                {/* ))} */}
                <ReactTooltip place="right" type="light" effect="solid" />
              </SubMenu>
            ) : (
              <Menu.Item key={item.slug} icon={item.icon}>
                <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
              </Menu.Item>
            )))
          )
        }
        {
          values.currentUser?.isShopkeeper && (
            getShopkeeperItem(t, values.currentUser?.isShopkeeper).map((item) => (
              <Menu.Item key={item.slug} icon={item.icon}>
                <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
              </Menu.Item>
            ))
          )
        }
        {
          values.currentUser?.isMaster && !values.currentUser.isShopkeeper && (
            ModerationPanel(t, values.currentUser?.isMaster).map((item) => (
              <Menu.Item key={item.slug} icon={item.icon}>
                <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
              </Menu.Item>
            ))
          )
        }
        {
          values.currentUser?.isManager && (
            ManagerPanel(t, values.currentUser?.isManager).map((item) => (
              // values.currentUser?.isMaster && !values.currentUser.isShopkeeper && (
              //   ManagerPanel(t, values.currentUser?.isMaster).map((item) => (
              <Menu.Item key={item.slug} icon={item.icon}>
                <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
              </Menu.Item>
            ))
          )
        }
        {
          values.currentUser?.isManager && (
            ManagerModerationPanel(t, values.currentUser?.isManager).map((item) => (
              // values.currentUser?.isMaster && !values.currentUser.isShopkeeper && (
              //   ManagerModerationPanel(t, values.currentUser?.isMaster).map((item) => (
              <Menu.Item key={item.slug} icon={item.icon}>
                <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
              </Menu.Item>
            ))
          )
        }
        {/* {
          values.currentUser?.isManager && (
            getConsultorMais(t, values.currentUser?.isMaster, values.currentUser?.isManager).map((item) => (item.subitems.length ? (
              <SubMenu key={item.slug} title={item.title} icon={item.icon}>
                {item.subitems.map((subitem) => (
                  <Menu.Item key={subitem.slug}>
                    <Link title={subitem.title} to={`/${subitem.slug}`}>{subitem.title}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.slug} icon={item.icon}>
                <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
              </Menu.Item>
            )))
          )
        } */}
        {
          (!!values.currentUser?.isMaster || !!values.currentUser?.prospectPlusLeads || !!values.currentUser?.prospectPlusFees || !!values.currentUser?.prospectConsult) && (
            getConsultorMais(t, values.currentUser?.isMaster, values.currentUser?.prospectPlusLeads, values.currentUser?.prospectPlusFees, values.currentUser?.prospectConsult).map((item) => (item.subitems.length ? (
              <SubMenu key={item.slug} title={item.title} icon={item.icon}>
                {item.subitems.map((subitem) => (
                  <Menu.Item key={subitem.slug}>
                    <Link title={subitem.title} to={`/${subitem.slug}`}>{subitem.title}</Link>
                  </Menu.Item>
                ))}
                {(!!values.currentUser?.prospectPlusLeads || !!values.currentUser?.isMaster || !!values.currentUser?.prospectConsult) && (
                  <Menu.Item key="prospeccao">
                    {/* <a href="https://dl9vqseyqqsk4.cloudfront.net/" target="_blank">Prospecção</a> */}
                    <a href="https://prospecta.energiamaisvezes.com.br/" target="_blank">Prospecção</a>
                  </Menu.Item>
                )}
              </SubMenu>
            ) : (
              <Menu.Item key={item.slug} icon={item.icon}>
                <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
              </Menu.Item>
            )))
          )
        }
      </Menu>
    </Sider>
  );
};

export default React.memo(SidebarMenu);
