import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// @ts-ignore
import gitVersion from './version'; // eslint-disable-line import/extensions
import App from './App';
import './Assets/less/styles.less';

const isProd = process.env.NODE_ENV === 'production';

if (process.env.REACT_APP_SENTRY_DSN && isProd) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    ignoreErrors: [],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1,
    sampleRate: 1,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: gitVersion,
  });
}

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root'),
);
