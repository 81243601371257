import axios from 'axios';

export default async function GetCepLatLong(cep: any) {
  const { data } = await axios.get(
    'https://maps.googleapis.com/maps/api/geocode/json',
    {
      params: {
        components: `postal_code:${cep}`,
        key: 'AIzaSyB9HUirJIS34PJTyYpgjhIVijyBZEX3_qg',
        language: 'pt-BR',
      },
    },
  );
  return data;
}
