/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Form,
  Row,
  Button,
  Layout, FormInstance,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/lib/typography';
import { Link, useHistory } from 'react-router-dom';
import LanguageDropdown from '../../Molecules/LanguageDropdown';
import DefaultLogo from '../../Atoms/DefaultLogo';
import InputDefault, { FormInputProps } from '../../Molecules/InputDefault';
import './TemplateLogin.less';
import './TemplateLogin.css';

const { Content } = Layout;
const { Title, Text } = Typography;

export type TemplateLoginProps<OnSubmit> = {
  onSubmit: (model: OnSubmit) => Promise<void>;
  fields: FormInputProps[];
  form?: FormInstance;
  title: string
  loading: boolean;
  subtitle?: string
  actionButtons?: {
    leftButton?: {
      title: string
      url?: string
      onClick?: () => void
    },
    submitButton?: {
      title?: string
    },
  }
};

function TemplateLogin<T>({
  onSubmit,
  loading,
  form,
  actionButtons,
  fields,
  subtitle,
  title,
}: TemplateLoginProps<T>) {
  const { t } = useTranslation();
  const { title: submitText } = (actionButtons || {}).submitButton || {};
  const history = useHistory();

  return (
    <Layout className="logged-out-page">
      <Content>
        <Row justify="center" align="middle" className="px-1" style={{ minHeight: '100vh' }}>
          <div style={{ width: '100%', maxWidth: '550px' }} className="inner-content">
            <Link to="/" title="Santander">
              <DefaultLogo />
            </Link>
            <Form
              className="p-4 bg-white radius-4"
              onFinish={onSubmit}
              style={{ border: '1px solid #CCCCCC' }}
              {...(form ? { form } : {})}
            >
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="text-box  pr-3">
                  <Title level={4} className="text-primary mb-0">{title}</Title>

                  {subtitle && <Text className="text-primary mb-0">{subtitle}</Text>}
                </div>

                {/* <LanguageDropdown /> */}
              </div>

              {fields.map((item) => (
                <InputDefault
                  key={`input-${item.name}`}
                  field={item}
                />
              ))}

              <Form.Item className="mb-0">
                <div className={`flex-row justify-content-${(actionButtons || {}).leftButton ? 'between' : 'end'} mt-3 ${(actionButtons || {}).leftButton ? 'align-center' : ''}`}>
                  {actionButtons && actionButtons.leftButton && (actionButtons.leftButton.url ? (
                    <Link to={actionButtons.leftButton.url}>
                      <Button htmlType="button" disabled={loading} onClick={actionButtons.leftButton.onClick}>
                        {actionButtons.leftButton.title}
                      </Button>
                    </Link>
                  ) : (
                    <Button htmlType="button" disabled={loading} className="second-btn" onClick={actionButtons.leftButton.onClick}>
                      {actionButtons.leftButton.title}
                    </Button>
                  ))}

                  <>
                    {window.location.href.endsWith('login') && (
                      <div className="fogot-password">
                        <p onClick={() => history.push('/forgot-password')}>Esqueci minha senha</p>
                      </div>
                    )}
                  </>

                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    className="login-form-button"
                  >
                    {submitText || t('Enviar')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>

        </Row>
      </Content>
    </Layout>
  );
}

export default TemplateLogin;
