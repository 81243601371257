import React from 'react';
import { AuthProvider } from './Base/Providers/Auth.context';
import Router from './Base/Router';
import './Base/i18n';
import { DataProvider } from './Base/Providers/Data.context';
import IdleMonitor from './Base/IdleMonitor';

const App = () => {
  try {
    setInterval(() => {
      const panelHeight = document.getElementById('root')?.scrollHeight;
      window.parent.postMessage({
        panelHeight,
        action: 'update-iframe-height',
      }, '*');
    }, 500);
  } catch (error) {
    //
  }

  return (
    <AuthProvider>
      {() => (
        <div className="App">
          <DataProvider>
            <IdleMonitor>
              <Router />
            </IdleMonitor>
          </DataProvider>
        </div>
      )}
    </AuthProvider>
  );
};

export default App;
