/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Col,
  Row,
  Table,
  Divider,
  Button,
  Popconfirm,
  Space,
  Input,
  DatePicker,
  Alert,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { FilterOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import Marquee from 'react-fast-marquee';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { Subsidiary } from '../../../../Models/Subisidiary.model';
import './Store-Detail.style.css';

interface FilterCategoriesState {
  textSearch: string | undefined
  startDate: Moment | undefined
  finishDate: Moment | undefined
}

const { Search } = Input;

const initialFilterState: FilterCategoriesState = {
  textSearch: undefined,
  startDate: undefined,
  finishDate: undefined,
};

const StoreDetails = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [store, setStore] = useState(values.storeSelected);
  const [description, setDescription] = useState(values?.storeSelected?.description?.description);
  const [loading, setLoading] = useState(true);
  const [loadingClick, setLoadingClick] = useState(false);
  const [filteredSusidiaries, setFilteredSubsidiaries] = useState<Subsidiary[]>([]);
  const [filterState, setFilterState] = useState<FilterCategoriesState>(initialFilterState);
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(1);
  const rangePickerRef = useRef<any>();
  const [semana, setSemana] = useState(store?.subsidiary.openingHour.filter((a: any) => a.day === 'SEMANA'));
  const [sabado, setSabado] = useState(store?.subsidiary.openingHour.filter((a: any) => a.day === 'SABADO'));
  const [domingo, setDomingo] = useState(store?.subsidiary.openingHour.filter((a: any) => a.day === 'DOMINGO'));
  const [feriado, setFeriado] = useState(store?.subsidiary.openingHour.filter((a: any) => a.day === 'FERIADO'));

  function openingDays() {
    return (
      <>
        {
          store?.subsidiary.openingHour.some((a: any) => a.day === 'SEMANA') && (
            <Col span={7}>
              <p>Segunda a Sexta-Feira:</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {
                  semana.map((a: any) => {
                    return (
                      <>
                        <p>{`${a.hourInit}/`}</p>
                        <p style={{ marginRight: 20 }}>{`${a.hourFinal}`}</p>
                      </>
                    );
                  })
                }
              </div>
            </Col>
          )
        }
        {
          store?.subsidiary.openingHour.some((a: any) => a.day === 'SABADO') && (
            <Col span={7}>
              <p>Sabado:</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {
                  sabado.map((a: any) => {
                    return (
                      <>
                        <p>{`${a.hourInit}/`}</p>
                        <p style={{ marginRight: 20 }}>{`${a.hourFinal}`}</p>
                      </>
                    );
                  })
                }
              </div>
            </Col>
          )
        }
        {
          store?.subsidiary.openingHour.some((a: any) => a.day === 'DOMINGO') && (
            <Col span={7}>
              <p>Domingo:</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {
                  domingo.map((a: any) => {
                    return (
                      <>
                        <p>{`${a.hourInit}/`}</p>
                        <p style={{ marginRight: 20 }}>{`${a.hourFinal}`}</p>
                      </>
                    );
                  })
                }
              </div>
            </Col>
          )
        }
        {
          store?.subsidiary.openingHour.some((a: any) => a.day === 'FERIADO') && (
            <Col span={7}>
              <p>Feriado:</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {
                  feriado.map((a: any) => {
                    return (
                      <>
                        <p>{`${a.hourInit}/`}</p>
                        <p style={{ marginRight: 20 }}>{`${a.hourFinal}`}</p>
                      </>
                    );
                  })
                }
              </div>
            </Col>
          )
        }
      </>
    );
  }

  const columns = [
    {
      title: t('Nome Fantasia'),
      dataIndex: 'name',
      render: (_: unknown, record: Subsidiary) => `${record.commercialName}`,
      sorter: (a: Subsidiary, b: Subsidiary) => a.commercialName.localeCompare(b.commercialName),
    },
    {
      title: t('Data de criação'),
      dataIndex: 'createdAt',
      render: (text: string) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
      defaultSortOrder: 'descend' as 'descend',
      sorter: (a: Subsidiary, b: Subsidiary) => moment(a.createdAt).diff(b.createdAt),
    },
    {
      title: t('Ações'),
      dataIndex: 'action',
      render: (text: string, record: Subsidiary) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            loading={loadingClick}
            size="small"
            style={{ width: '100%' }}
            onClick={async () => {
              setLoadingClick(true);
              actions.setSubisidiryEdit(record || []);
              setLoadingClick(false);
              history.push('/subsidiaries/edit');
            }}
          >
            Editar
          </Button>
          <Popconfirm
            title="Excluir?"
            onConfirm={async () => {
              try {
                setLoading(true);
                await ApiService.subsidiaries.DeleteListSubsidiaries(record.id);
                const { data } = await ApiService.subsidiaries.ListSubsidiaries({ id: store?.id!, search: filterState.textSearch });
                actions.setSubisidiries(data || []);
                setFilteredSubsidiaries(data);
              } catch (error) {
                actions.setSubisidiries([]);
              } finally {
                setLoading(false);
              }
            }}
          >
            <Button type="primary" size="small" style={{ width: '100%' }}> Excluir</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const resetFilterState = () => {
    setFilterState(initialFilterState);
  };

  const fetchUserList = async () => {
    try {
      setLoading(true);

      const { data } = await ApiService.subsidiaries.ListSubsidiaries({ id: store?.id!, search: filterState.textSearch });
      actions.setSubisidiries(data || []);

      setFilteredSubsidiaries(data);
    } catch (error) {
      actions.setSubisidiries([]);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    setFilteredSubsidiaries(values.subisidiries || []);
  };

  useEffect(() => {
    fetchUserList();
  }, [page]);

  useEffect(() => {
    applyFilters();
  }, [values.subisidiries]);

  return (
    <Row>
      <Col style={{ overflow: 'auto', backgroundColor: 'white' }}>

        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: 'white',
            borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
          }}
        >

          <SectionTitle
            title={t('Lojas | Detalhes')}
          />

          <div>
            {
              !values.storeSelected?.isActive && (
                <Alert
                  banner
                  message="Se seu status estiver INATIVO e você deseja torná-lo ATIVO, procure o seu Consultor+ ou Gerente de Relacionamento para realizar a reativação "
                />

              )
            }
            {
              values.storeSelected?.isActive ? (
                <Alert
                  style={{ display: 'flex', flex: 1, marginTop: 10 }}
                  message="Loja ATIVA: O anúncio da sua loja na vitrine do Site do +Vezes está disponível para a visualização dos clientes finais."
                  type="success"
                  showIcon
                />
              )
                : (
                  <Alert
                    style={{ display: 'flex', flex: 1, marginTop: 10 }}
                    message="Loja INATIVA: O anúncio da sua loja na vitrine do Site do +Vezes não está disponível para a visualização dos clientes finais."
                    type="warning"
                    showIcon
                  />
                )
            }
          </div>
          <Row gutter={[16, 16]}>
            <Row>
              <Col span={24}>
                <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                  <div>
                    <h2 className="alignP" style={{ fontSize: '22px', lineHeight: '1', marginBottom: 4 }}>{store?.commercialName}</h2>
                    <h3 className="alignP" style={{ fontSize: '16px', lineHeight: '1', marginBottom: 0 }}>{store?.corporateName}</h3>
                  </div>

                  <Button
                    style={{ padding: '0 30px' }}
                    size="large"
                    type="primary"
                    onClick={async () => {
                      const { data: storeSubsidiary } = await ApiService.stores.findStoreWithSubsidiary(values.storeSelected?.id!);
                      if (!!storeSubsidiary) {
                        actions.setStoreSelected(storeSubsidiary || []);
                        history.push('/stores/edit');
                      }
                    }}
                  >
                    Editar Loja
                  </Button>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  {/* <p className="alignP">{`Nome Fantasia: ${store?.commercialName || ''}`}</p> */}
                  <p className="alignP">{`Subsegmento: ${store?.subsegment.code || ''} - ${store?.subsegment.name || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`CNPJ: ${store?.cnpj || ''}`}</p>
                  <p className="alignP">{`Código TAB: ${store?.tab || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Telefone: ${store?.subsidiary?.telephone || ''}`}</p>
                  <p className="alignP">{`WhatsApp: ${store?.subsidiary?.whatsapp || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`E-mail: ${store?.email || ''}`}</p>
                  <p className="alignP">{`Site: ${store?.site || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Descrição: ${description || ''}`}</p>
                </Row>
              </Col>

              <Divider />

              <Col span={24}>
                <Row align="middle">
                  <h2 style={{ fontSize: '28px' }}>Endereço</h2>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Cep: ${store?.subsidiary.cep || ''}`}</p>
                  <p className="alignP">{`Estado: ${store?.subsidiary.state || ''}`}</p>

                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Cidade: ${store?.subsidiary.city || ''}`}</p>
                  <p className="alignP">{`Bairro: ${store?.subsidiary.neigborhood || ''}`}</p>

                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Logradouro (tipo): ${store?.subsidiary.address || ''}`}</p>
                  <p className="alignP">{`Logradouro: ${store?.subsidiary.street || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Número: ${store?.subsidiary.number || ''}`}</p>
                  <p className="alignP">{`complemento: ${store?.subsidiary.complement || ''}`}</p>
                </Row>
              </Col>

              {
                store?.subsidiary.openingHour.length > 0 && (
                  <>
                    <Divider />
                    <Col span={24}>
                      <Row align="middle" justify="space-between" style={{ margin: '20px 0' }}>
                        <h2 className="alignP" style={{ fontSize: '28px' }}>Horário de funcionamento</h2>
                      </Row>
                    </Col>
                    {openingDays()}
                  </>
                )
              }

              <Divider />

              <Col span={24}>
                <Row align="middle">
                  <h2 className="alignP" style={{ fontSize: '28px' }}>Endereço PDV</h2>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Cep: ${store?.cepPDV || ''}`}</p>
                  <p className="alignP">{`Estado: ${store?.statePDV || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Cidade: ${store?.cityPDV || ''}`}</p>
                  <p className="alignP">{`Bairro: ${store?.neigborhoodPDV || ''}`}</p>

                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Logradouro (tipo): ${store?.addressPDV || ''}`}</p>
                  <p className="alignP">{`Logradouro: ${store?.streetPDV || ''}`}</p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <p className="alignP">{`Número: ${store?.numberPDV || ''}`}</p>
                  <p className="alignP">{`complemento: ${store?.complementPDV || ''}`}</p>
                </Row>
              </Col>

              <Divider />

              <Col span={24}>
                <Row align="middle" justify="space-between">
                  <Col span={10}>
                    <h2 className="alignP" style={{ fontSize: '28px', marginTop: 10 }}>Filiais</h2>
                  </Col>
                  <Row align="middle" justify="space-between" style={{ justifyContent: 'center' }}>
                    <Button
                      style={{ marginRight: 10, marginTop: 10 }}
                      type="primary"
                      size="large"
                      onClick={() => {
                        actions.setStoreSelected(values.storeSelected || []);
                        history.push('/subsidiaries/add');
                      }}
                    >
                      Adicionar Filiais em Lote
                    </Button>
                    <Button
                      style={{ marginTop: 10 }}
                      size="large"
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        history.push('/subsidiaries/create');
                      }}
                    >
                      Adicionar Filial
                    </Button>
                  </Row>
                </Row>

                <div className="align-items-center justify-content-end d-flex mx-3 mt-4 mb-2">
                  <Search
                    onSearch={(e) => {
                      setFilterState({
                        ...filterState,
                        textSearch: e,
                      });

                      if (page === 1) {
                        fetchUserList();
                      } else {
                        setPage(1);
                      }
                    }}
                    enterButton
                    allowClear
                    placeholder={t('Digite o nome da filial para buscar ')}
                    size="large"
                    onChange={(e) => setFilterState({
                      ...filterState,
                      textSearch: e.target.value,
                    })}
                  />

                  <Button
                    type="primary"
                    icon={<FilterOutlined />}
                    size="large"
                    onClick={() => setOpenFilter((op) => !op)}
                  />

                </div>

                {openFilter && (
                  <Row align="middle" justify="start" style={{ padding: '1rem' }}>
                    <Col style={{ marginRight: '1rem' }}>
                      <DatePicker.RangePicker
                        ref={rangePickerRef}
                        size="large"
                        locale={locale}
                        onCalendarChange={(calendarValues) => {
                          if (!calendarValues) {
                            setFilterState({
                              ...filterState,
                              startDate: undefined,
                              finishDate: undefined,
                            });
                            return;
                          }

                          const [startDate, finishDate] = calendarValues as Array<Moment>;

                          setFilterState({
                            ...filterState,
                            startDate,
                            finishDate,
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Button
                        size="large"
                        onClick={resetFilterState}
                      >
                        Limpar Filtros
                      </Button>
                    </Col>
                  </Row>
                )}

                {loading ? (
                  <>
                    <Row align="middle" justify="center">
                      <Col style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      >
                        <LoadingOutlined style={{ fontSize: 60 }} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Table
                    columns={columns}
                    dataSource={filteredSusidiaries || []}
                    loading={false}
                    pagination={{
                      position: ['bottomCenter'],
                      onChange: (event) => { setPage(event); },
                      current: page,
                      pageSize: 10,
                      onShowSizeChange: ((c) => {
                        setPage(c);
                      }),
                    }}
                  />
                )}

              </Col>
            </Row>
          </Row>
        </Content>
      </Col>
    </Row>
  );
};

export default StoreDetails;
