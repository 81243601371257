import { Store } from '../../Models/Store.model';
import { ApiClient } from './client';

export function ListStores(body: any) {
  return ApiClient.post<Store[]>('/admin/stores/manage', body);
}

interface ListStoresV2Res {
  stores: Store[]
  total:number
}
export function ListStoresV2(body: any) {
  return ApiClient.post<ListStoresV2Res>('/admin/stores/manage/list-v2', body);
}

export function ListStoresModeration(body: any) {
  return ApiClient.post<ListStoresV2Res>('/admin/stores/manage/moderation', body);
}

export function ListStoreByCnpj(body: any) {
  return ApiClient.post<any>('/admin/stores/manage/cnpj', body);
}

export function listStoresByGroup(body: any) {
  return ApiClient.post<any>('/admin/economics-groups/manage/selected', body);
}

export function DeleteStore(id: number) {
  return ApiClient.httpDelete<Store>(`/admin/stores/manage/delete/${id}`);
}

export function CreateStore(body: any) {
  return ApiClient.post('/admin/stores/manage/create', body);
}

export function EditStore(body: any) {
  return ApiClient.post<Store>('/admin/stores/manage/edit', body);
}

export function ApproveEdition(body: any) {
  return ApiClient.post<Store>('/admin/stores/manage/edition/approve', body);
}

export function RejectEdition(body: any) {
  return ApiClient.post<Store>('/admin/stores/manage/edition/reject', body);
}

export function EditStoreShopkeeper(body: any) {
  return ApiClient.post('/admin/stores/manage/shopkeeper/edit', body);
}

export function findStoreWithSubsidiary(id: number) {
  return ApiClient.get(`/admin/stores/manage/${id}`);
}

export function findStoreWithSubsidiaryByShopkeeper(id: number) {
  return ApiClient.get(`/admin/stores/manage/shopkeeper/${id}`);
}

export function updateStores(body: any) {
  return ApiClient.post('/admin/stores/manage/update-stores', body);
}

export function AddStores(body: any) {
  return ApiClient.post('/admin/stores/manage/add-stores', body);
}

export function listProducts(body: any) {
  return ApiClient.post('/admin/stores/manage/list-products', body);
}

export function listStoresProducts(body: any) {
  return ApiClient.post('/admin/stores/manage/list-stores-products', body);
}
