/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Button,
  Col,
  Row,
  Table,
  Input,
  DatePicker,
  Popconfirm,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FilterOutlined, CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment, { Moment } from 'moment';
import { Link, useHistory } from 'react-router-dom';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { Subsegment } from '../../../../Models/Subsegment.model';

interface FilterCategoriesState {
  textSearch: string | undefined
  startDate: Moment | undefined
  finishDate: Moment | undefined
}

const { Search } = Input;

const initialFilterState: FilterCategoriesState = {
  textSearch: undefined,
  startDate: undefined,
  finishDate: undefined,
};

const PageSubsegmentsList = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [filteredSegments, setFilteredSegments] = useState<Subsegment[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingClick, setLoadingClick] = useState(false);

  const [filterState, setFilterState] = useState<FilterCategoriesState>(initialFilterState);
  const [openFilter, setOpenFilter] = useState(false);
  const rangePickerRef = useRef<any>();

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'name',
      render: (_: unknown, record: Subsegment) => `${record.name}`,
      sorter: (a: Subsegment, b: Subsegment) => a.name.localeCompare(b.name),
    },
    {
      title: t('Código'),
      dataIndex: 'code',
      render: (_: unknown, record: Subsegment) => `${record.code}`,
      sorter: (a: Subsegment, b: Subsegment) => JSON.stringify(a.code).localeCompare(JSON.stringify(b.code)),
    },
    {
      title: t('Data de criação'),
      dataIndex: 'createdAt',
      render: (text: string) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
      defaultSortOrder: 'descend' as 'descend',
      sorter: (a: Subsegment, b: Subsegment) => moment(a.createdAt).diff(b.createdAt),
    },
    {
      title: t('Ações'),
      width: 230,
      dataIndex: 'action',
      render: (text: string, record: Subsegment) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            loading={loadingClick}
            size="small"
            style={{ width: '100%' }}
            onClick={async () => {
              setLoadingClick(true);
              actions.setSubsegmentToEdit(record || []);
              setLoadingClick(false);
              history.push('/subsegments/edit');
            }}
          >
            Editar
          </Button>
          <Popconfirm
            title="Excluir?"
            onConfirm={async () => {
              try {
                setLoading(true);
                await ApiService.subsegments.DeleteSubsegments(record.id);
                const { data } = await ApiService.subsegments.ListSubsegments();
                actions.setCategories(data || []);

                setFilteredSegments(data);
              } catch (error) {
                actions.setCategories([]);
              } finally {
                setLoading(false);
              }
            }}
          >
            <Button type="primary" size="small" style={{ width: '100%' }}> Excluir</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const resetFilterState = () => {
    setFilterState(initialFilterState);
  };

  const fetchUserList = async () => {
    try {
      setLoading(true);
      const { data } = await ApiService.subsegments.ListSubsegments();
      actions.setSubsegments(data || []);

      setFilteredSegments(data);
    } catch (error) {
      actions.setSubsegments([]);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    let tempSubsegments = values.subsegments || [];

    if (filterState.startDate) {
      tempSubsegments = tempSubsegments.filter((l) => {
        const created = moment(l.createdAt);
        if (!filterState.startDate) return true;
        return filterState.startDate?.diff(created) <= 0;
      });
    }

    if (filterState.finishDate) {
      tempSubsegments = tempSubsegments.filter((l) => {
        const created = moment(l.createdAt);
        if (!filterState.finishDate) return true;
        return filterState.finishDate?.diff(created) >= 0;
      });
    }

    // if (filterState.textSearch && filterState.textSearch !== '') {
    //   const tx = filterState.textSearch?.toLowerCase();
    //   tempSubsegments = tempSubsegments.filter((l) => {
    //     const keys = Object.keys(l);
    //     const match = keys.some((k) => {
    //       const ld = l as any;
    //       const val = ld[k];

    //       if (val && typeof val === 'string') {
    //         return val?.toLowerCase().includes(tx);
    //       }
    //       return false;
    //     });

    //     return match;
    //   });
    // }

    if (filterState.textSearch && filterState.textSearch !== '') {
      const tx = filterState.textSearch?.toUpperCase();
      tempSubsegments = tempSubsegments.filter((l) => l.name.toUpperCase().includes(tx) || JSON.stringify(l.code).includes(tx));
    }

    setFilteredSegments(tempSubsegments);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filterState, values.subsegments]);

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Subsegmentos')}
        // onExtraClick={() => {
        //   const result = generateReportSheet(filteredCategories);
        //   if (result.success) {
        //     const base64Excel = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.data}`;
        //     const elem = document.getElementById('download-exportacao') as HTMLLinkElement;
        //     if (!elem) {
        //       window.location.href = base64Excel;
        //     } else {
        //       elem.href = base64Excel;
        //       elem.click();
        //     }
        //   }
        // }}
        />

        <a id="download-exportacao" style={{ display: 'none' }} href="/" download>Fazer Download</a>

        <div className="align-items-center d-flex mx-3 mt-4 mb-2">
          <Button
            type="primary"
          >
            <Link to="/subsegments/create">
              Adicionar Subsegmento
            </Link>
          </Button>
        </div>

        <div className="align-items-center justify-content-end d-flex mx-3 mt-4 mb-2">
          <Search
            onSearch={(e) => setFilterState({
              ...filterState,
              textSearch: e,
            })}
            enterButton
            allowClear
            placeholder={t('Digite para buscar')}
            size="large"
            onChange={(e) => setFilterState({
              ...filterState,
              textSearch: e.target.value,
            })}
          />

          <Button
            type="primary"
            icon={<FilterOutlined />}
            size="large"
            onClick={() => setOpenFilter((op) => !op)}
          />

        </div>

        {openFilter && (
          <Row align="middle" justify="start" style={{ padding: '1rem' }}>
            <Col style={{ marginRight: '1rem' }}>
              <DatePicker.RangePicker
                ref={rangePickerRef}
                size="large"
                locale={locale}
                onCalendarChange={(calendarValues) => {
                  if (!calendarValues) {
                    setFilterState({
                      ...filterState,
                      startDate: undefined,
                      finishDate: undefined,
                    });
                    return;
                  }

                  const [startDate, finishDate] = calendarValues as Array<Moment>;

                  setFilterState({
                    ...filterState,
                    startDate,
                    finishDate,
                  });
                }}
              />
            </Col>

            <Col>
              <Button
                size="large"
                onClick={resetFilterState}
              >
                Limpar Filtros
              </Button>
            </Col>
          </Row>
        )}

        {loading ? (
          <>
            <Row align="middle" justify="center">
              <Col style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                <LoadingOutlined style={{ fontSize: 60 }} />
              </Col>
            </Row>
          </>
        ) : (
          <Table
            columns={columns}
            dataSource={filteredSegments || []}
            loading={false}
            pagination={{ position: ['bottomCenter'] }}
          />
        )}
      </Col>
    </Row>
  );
};

export default PageSubsegmentsList;
