/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Select,
  Checkbox,
  InputNumber,
  Upload,
  TimePicker,
  message,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { MaskedInput } from 'antd-mask-input';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { addressType } from '../../../../Components/Atoms/AddressType';
import UfToSate from '../../../../Components/Molecules/UfToState/UfToState';
import GetCepLatLong from '../../../../Components/Molecules/GetLatLngByCep/GetLatLngByCep';
import { SelectUf } from '../../../../Components/Molecules/UfToState/SelectUf';

const { Option } = Select;

const containerStyle = {
  width: '400px',
  height: '400px',
  margin: 'auto',
};

const ManagerSubsidiaryCreate = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [logradouro, setlogradouro] = useState('');
  const [uf, setUf] = useState('');
  const [lati, setLati] = useState<any>(-3.745);
  const [long, setLong] = useState<any>(-38.523);
  const [image, setImage] = useState<any>(null);
  const [logo, setLogo] = useState<any>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [weekday, setWeekday] = useState(true);
  const [InitWeekday, setInitWeekday] = useState('');
  const [FinalWeekday, setFinalWeekday] = useState('');
  const [saturday, setSaturday] = useState(false);
  const [InitASaturday, setInitASaturday] = useState('');
  const [FinalASaturday, setFinalASaturday] = useState('');
  const [sunday, setSunday] = useState(false);
  const [InitASunday, setInitASunday] = useState('');
  const [FinalASunday, setFinalASunday] = useState('');
  const [holiday, setHoliday] = useState(false);
  const [InitAHoliday, setInitAHoliday] = useState('');
  const [FinalAHoliday, setFinalAHoliday] = useState('');
  // const [whats, setWhats] = useState('');

  function mphone(v: any) {
    let r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
      r = r.replace(/^(\d*)/, '($1');
    }
    return r;
  }

  function mask(o: any, f: any) {
    setTimeout(() => {
      const v = mphone(o.value);
      if (v !== o.value) {
        o.value = v;
      }
    }, 1);
  }

  const center = {
    lat: lati,
    lng: long,
  };

  const hours = [
    {
      day: 'SEMANA',
      hourInit: InitWeekday,
      hourFinal: FinalWeekday,
      order: 0,
    },
    {
      day: 'SABADO',
      hourInit: InitASaturday,
      hourFinal: FinalASaturday,
      order: 1,
    },
    {
      day: 'DOMINGO',
      hourInit: InitASunday,
      hourFinal: FinalASunday,
      order: 2,
    },
    {
      day: 'Feriado',
      hourInit: InitAHoliday,
      hourFinal: FinalAHoliday,
      order: 3,
    },
  ];

  const validateMessages = () => {
    setError(true);
    setLoading(false);
    setTimeout(() => { setError(false); }, 10000);
  };

  const onFinish = async (formValues: any) => {
    if (!!InitWeekday && !!FinalWeekday) {
      setLoading(true);
      const formData = new FormData();
      formData.append('image', image || '');
      formData.append('logo', logo || '');
      const extras = JSON.stringify({
        ...formValues,
        // whatsapp: whats,
        matrizStoreId: values.storeSelected?.id,
        lat: lati,
        isActive: true,
        long,
        image: '',
        logo: '',
        hours,
        cep,
        city: cidade,
        neigborhood: bairro,
        state: uf,
        street: logradouro,
      });
      formData.append('extras', extras);
      const result = await ApiService.manager.CreateSubsidiaries(formData);
      setLoading(false);
      if (result) history.push('/manager/store/details');
    } else {
      validateMessages();
    }
  };

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Lojas | Criar Filial')}
        />
        <Form
          name="create-stores"
          onFinish={onFinish}
          onFinishFailed={validateMessages}
          layout="vertical"
          style={{
            maxWidth: 800,
            margin: 'auto',
          }}
        >

          <Col style={{ marginTop: 20 }}>
            <h2>Inclua as informações da sua filial</h2>
          </Col>
          <Form.Item
            label="Nome Fantasia"
            name="commercialName"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Instagram"
              name="instagram"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input placeholder="Digite o link do Instagram, se houver" />
            </Form.Item>
            <Form.Item
              label="Facebook"
              name="facebook"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              <Input placeholder="Digite o link do Facebook, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Pinterest"
              name="pinterest"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input placeholder="Digite o link do Pinterest, se houver" />
            </Form.Item>
            <Form.Item
              label="Twitter"
              name="twitter"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              {/* <MaskedInput mask="(11) 11111-1111" /> */}
              <Input placeholder="Digite o link do PinterestTwitter, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Youtube "
              name="youtube"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input placeholder="Digite o link do Youtube, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Telefone"
              name="telephone"
              // validateStatus={teste ? 'error' : 'success'}
              // help={teste ? 'Deve conter todos os dígitos!' : ''}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input
                // value={whats}
                onChange={(event) => mask(event.target, mphone)}
              />
            </Form.Item>
            <Form.Item
              label="Whatsapp"
              name="whatsapp"
              // rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              {/* <MaskedInput mask="(11) 11111-1111" /> */}
              <Input
                // value={whats}
                onChange={(event) => mask(event.target, mphone)}
              />
            </Form.Item>
          </Form.Item>
          <Col style={{ marginTop: 20 }}>
            <h2>Horário de funcionamento</h2>
          </Col>
          <Form.Item>
            <Form.Item
              // valuePropName={monday}
              initialValue={weekday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={weekday} onChange={(event) => setWeekday(true)}><b>Segunda a Sexta</b></Checkbox>
            </Form.Item>
            {
              weekday && (
                <Form.Item>
                  <Form.Item
                    style={{ display: 'inline-block', width: 'calc(33.8%)' }}
                    validateStatus={!!InitWeekday && !!FinalWeekday ? '' : 'error'}
                    help={!!InitWeekday && !!FinalWeekday ? '' : 'Campo obrigatório'}
                  >
                    <TimePicker.RangePicker
                      format="HH:mm"
                      onChange={(event, timeString) => {
                        setInitWeekday(timeString[0]);
                        setFinalWeekday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }
            <Form.Item
              valuePropName="checked"
              initialValue={saturday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={saturday} onChange={(event) => setSaturday(event.target.checked)}><b>Sábado</b></Checkbox>
            </Form.Item>
            {
              saturday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      onChange={(event, timeString) => {
                        setInitASaturday(timeString[0]);
                        setFinalASaturday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }

            <Form.Item
              valuePropName="checked"
              initialValue={sunday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={sunday} onChange={(event) => setSunday(event.target.checked)}><b>Domingo</b></Checkbox>
            </Form.Item>
            {
              sunday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      onChange={(event, timeString) => {
                        setInitASunday(timeString[0]);
                        setFinalASunday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }

            <Form.Item
              valuePropName="checked"
              initialValue={holiday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={holiday} onChange={(event) => setHoliday(event.target.checked)}><b>Feriado</b></Checkbox>
            </Form.Item>
            {
              holiday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      onChange={(event, timeString) => {
                        setInitAHoliday(timeString[0]);
                        setFinalAHoliday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }
          </Form.Item>
          {/* <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="image"
              label="Foto"
              valuePropName="Foto"
              initialValue={values?.subsidiaryEdit?.image}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <div>

                {image && (
                  <div>
                    <img
                      src={
                        URL.createObjectURL(image)
                      }
                      style={{
                        height: 160,
                        borderRadius: 4,
                        marginBottom: 20,
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )}

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  name="image"
                  id="image-or-video"
                  type="file"
                  onChange={(event) => {
                    if (event.target.files) {
                      setImage(event.target.files[0]);
                    }
                  }}
                />
                <label htmlFor="image-or-video" className="select-image">
                  Selecionar Imagem
                </label>
              </div>
            </Form.Item>
            <Form.Item
              name="logo"
              label="Logo"
              initialValue={values?.subsidiaryEdit?.logo}
              valuePropName="Logo"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              <div>

                {logo && (
                  <div>
                    <img
                      src={
                        URL.createObjectURL(logo)
                      }
                      style={{
                        height: 160,
                        borderRadius: 4,
                        marginBottom: 20,
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )}

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  name="logo"
                  id="image-or-video-logo"
                  type="file"
                  onChange={(event) => {
                    if (event.target.files) {
                      setLogo(event.target.files[0]);
                    }
                  }}
                />
                <label htmlFor="image-or-video-logo" className="select-image">
                  Selecionar Logo
                </label>
              </div>
            </Form.Item>
          </Form.Item> */}
          <Form.Item
            label="CEP"
            name="cep"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <MaskedInput
              mask="11111-111"
              onChange={async (element) => {
                let cepTemp: any = parseInt(element.target.value.replace('-', ''), 10);
                if (JSON.stringify(cepTemp).length < 8) {
                  cepTemp = `0${JSON.stringify(cepTemp)}`;
                } else {
                  cepTemp = `${JSON.stringify(cepTemp)}`;
                }
                if (cepTemp.length === 8) {
                  const { data } = await axios.get(`https://viacep.com.br/ws/${cepTemp}/json`);
                  setCep(cepTemp);
                  setCidade(data.localidade);
                  setBairro(data.bairro);
                  setlogradouro(data.logradouro);
                  setUf(data.uf);
                  const LatLng = await GetCepLatLong(cepTemp);
                  if (LatLng) {
                    setLati(LatLng.results[0].geometry.location.lat);
                    setLong(LatLng.results[0].geometry.location.lng);
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Estado"
            name="state"
            valuePropName={uf}
            rules={[{ required: !uf, message: 'Campo obrigatório!' }]}
          >
            <Input value={uf} onChange={(event) => setUf(event.target.value)} readOnly />
          </Form.Item>
          <Form.Item
            label="Cidade"
            name="city"
            valuePropName={cidade}
            rules={[{ required: !cidade, message: 'Campo obrigatório!' }]}
          >
            <Input value={cidade} onChange={(event) => setCidade(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Bairro"
            name="neigborhood"
            valuePropName={bairro}
            rules={[{ required: !bairro, message: 'Campo obrigatório!' }]}
          >
            <Input value={bairro} onChange={(event) => setBairro(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Logradouro (tipo)"
            name="address"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
            style={{ textTransform: 'capitalize' }}
          >
            <Select showSearch>
              {
                addressType.map((element) => {
                  return (
                    <Option style={{ textTransform: 'capitalize' }} value={element.value}>{element.value}</Option>
                  );
                })
              }
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Logradouro"
              name="street"
              valuePropName={logradouro}
              rules={[{ required: !logradouro, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input
                placeholder="Digite o endereço da sua loja"
                value={logradouro}
                onChange={(event) => setlogradouro(event.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Número"
              name="number"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(20% - 8px)', margin: '0 0 0 8px' }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Complemento"
              name="complement"
              style={{ display: 'inline-block', width: 'calc(30%)', margin: '0 0 0 8px' }}
            >
              <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label="Latitude"
                name="lat"
                // validateStatus={teste ? 'error' : 'success'}
                // help={teste ? 'Deve conter todos os dígitos!' : ''}
                valuePropName={lati}
                initialValue={lati}
                rules={[{ required: !lati, message: 'Campo obrigatório!' }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <Input
                  readOnly
                  type="number"
                  value={lati}
                  onChange={(event) => setLati(Number(event.target.value))}
                />
              </Form.Item>
              <Form.Item
                label="Longitude"
                name="long"
                valuePropName={long}
                initialValue={long}
                rules={[{ required: !long, message: 'Campo obrigatório!' }]}
                style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
              >
                <Input
                  readOnly
                  type="number"
                  value={long}
                  onChange={(event) => setLong(Number(event.target.value))}
                />
              </Form.Item>
            </Form.Item>
            <LoadScript
              googleMapsApiKey="AIzaSyB9HUirJIS34PJTyYpgjhIVijyBZEX3_qg"
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onClick={(event) => {
                  setLati(event.latLng?.lat());
                  setLong(event.latLng?.lng());
                }}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </Form.Item>
          {
            error && (
              <>
                <Form.Item style={{
                  display: 'flex', textAlignLast: 'center', marginTop: 15,
                }}
                >
                  <Alert style={{ maxWidth: '60%', margin: 'auto', padding: '2px 10px' }} message="Há campos obrigatórios que faltam ser preenchidos!" type="error" showIcon />
                </Form.Item>
              </>
            )
          }
          <Form.Item style={{ display: 'flex', textAlignLast: 'center', marginTop: 20 }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit" style={{ width: 120, flex: 1 }}>
                Criar Filial
              </Button>
              <Button loading={loading} style={{ width: 120, flex: 1 }}>
                <Link to="/manager/store/details">
                  Cancelar
                </Link>
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ManagerSubsidiaryCreate;
