/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Upload,
  Button,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import { DataContext } from '../../../../Base/Providers/Data.context';
import ApiService from '../../../../Services/Api';

interface ItemProps {
  label: string;
  value: number;
}

const CategoryEdit = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [subsegments, setSubsegmentos] = useState<any>(values.subsegmentCatSelected);
  const [subsegmentsSelected, setSubsegmentosSelected] = useState<any>(values.subsegmentCatSelected);
  const [category, setCategory] = useState<any>(values.categoryEdit);
  const [image, setImage] = useState<any>(values.categoryEdit?.image);
  const [loading, setLoading] = useState(false);

  const file = values.categoryEdit?.image;

  const optionsSub: ItemProps[] = subsegments.map((e: any) => {
    return (
      {
        label: `${e.code} - ${e.name}`,
        value: e.id,
      }
    );
  });

  const onFinish = async (formValues: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('image', image || '');
    const extras = JSON.stringify({
      id: category.id,
      name: formValues.name,
      subsegmentsId: subsegmentsSelected,
      image: file === image ? image : '',
    });
    formData.append('extras', extras);
    await ApiService.categories.EditCategory(formData);
    setLoading(false);
    history.push('/categories');
  };

  const teste = values.subsegmentCatSelected;

  useEffect(() => {
    const fetchData = async () => {
      // const { data: selected } = await ApiService.subsegments.ListSubsegmentsSelected(category.id);
      const { data: all } = await ApiService.subsegments.ListSubsegments();

      setSubsegmentosSelected(values.subsegmentCatSelected);
      setSubsegmentos(all);
    };

    fetchData();
  }, []);

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Categorias | Editar')}
        />

        <Form
          name="create-category"
          onFinish={onFinish}
          layout="vertical"
          style={{
            maxWidth: 700,
            margin: 'auto',
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            initialValue={category.name}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            name="upload-image"
            label=""
            valuePropName="file"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Upload name="logo" listType="picture">
              <Button icon={<UploadOutlined />}>Selecione uma imagem</Button>
            </Upload>
          </Form.Item> */}
          <Form.Item
            label="Subsegmento"
            name="subsegment"
            rules={[{ required: !teste, message: 'Campo obrigatório!' }]}
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              value={subsegmentsSelected}
              defaultValue={teste}
              options={optionsSub}
              onChange={(event) => {
                setSubsegmentosSelected(event);
              }}
            />
            {/* {
                subsegments.map((e: any) => {
                  return (
                    <Option value={e.id}>{e.name}</Option>
                  );
                })
              }
            </Select> */}
          </Form.Item>
          <Form.Item
            name="image"
            label="Foto"
            valuePropName="Foto"
            style={{ alignContent: 'center' }}
            rules={[{ required: !image, message: 'Campo obrigatório!' }]}
          >
            <div>

              {image && (
                <div>
                  <img
                    src={
                      image === file
                        ? `https://assets.energiamaisvezes.com.br/category/${values.categoryEdit?.image}`
                        : URL.createObjectURL(image)
                    }
                    style={{
                      width: 153,
                      borderRadius: 4,
                      marginBottom: 20,
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}

              <input
                accept="image/*"
                style={{ display: 'none' }}
                name="image"
                id="image-or-video"
                type="file"
                onChange={(event) => {
                  if (event.target.files) {
                    setImage(event.target.files[0]);
                  }
                }}
              />
              <label htmlFor="image-or-video" className="select-image">
                Selecionar Imagem
              </label>
            </div>
          </Form.Item>
          <Form.Item style={{ display: 'flex', textAlignLast: 'center' }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit" style={{ width: 130, flex: 1 }}>
                Editar categoria
              </Button>
              <Button loading={loading} style={{ width: 130, flex: 1 }}>
                <Link to="/categories/">
                  Cancelar
                </Link>
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default CategoryEdit;
