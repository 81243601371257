import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useCountUp } from 'react-countup';
import { DateHelper } from '../Helpers/date.helper';
import { AuthContext } from './Providers/Auth.context';

type IdleMonitorProps = {
  children?: React.ReactNode;
};

const TIMEOUT_MINUTES = 15;
const TIMEOUT_SECONDS = TIMEOUT_MINUTES * 60;

function IdleMonitor({ children }: IdleMonitorProps) {
  const { actions } = useContext(AuthContext);
  const countUpRef = useRef<HTMLDivElement>(null);

  const { reset, start } = useCountUp({
    ref: countUpRef,
    end: TIMEOUT_SECONDS,
    duration: TIMEOUT_SECONDS,
    useEasing: false,
    startOnMount: false,
    onStart: () => {},
    onEnd: () => {
      actions.doLogout();
    },
  });

  const [visibleTimestamp, setVisibleTimestamp] = useState<any>();
  const [hiddenTimestamp, setHiddenTimestamp] = useState<any>();

  const handleVisibility = () => {
    if (document.visibilityState === 'hidden') {
      setVisibleTimestamp(DateHelper.now());
      return;
    }
    setHiddenTimestamp(DateHelper.now());
  };

  const handleEvent = async (ev: Event) => {
    reset();

    setTimeout(() => { start(); }, 250);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibility);
    document.addEventListener('keydown', handleEvent);
    document.addEventListener('click', handleEvent);
    document.addEventListener('scroll', handleEvent);

    start();
  }, []);

  useEffect(() => {
    if (!visibleTimestamp || !hiddenTimestamp) return;

    const diff = visibleTimestamp.diff(hiddenTimestamp, 'seconds').seconds;
    if (diff < 0) {
      const minutes: number = Math.abs(diff) / 60;
      if (minutes >= TIMEOUT_MINUTES) {
        actions.doLogout();
      }
    }
  }, [visibleTimestamp, hiddenTimestamp]);

  return (
    <>
      <div ref={countUpRef} style={{ opacity: 0, display: 'none' }} />
      {children}
    </>
  );
}

export default IdleMonitor;
