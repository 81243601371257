import React, { useContext, useState } from 'react';
import {
  Modal, ModalProps,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalSelectStatus.body';
import { LogStatus } from '../../../Models/LogStatus.model';
import { Lead } from '../../../Models/Lead.model';
import ApiService from '../../../Services/Api';
import { DataContext } from '../../../Base/Providers/Data.context';

interface Values {
  selectedStatus?: LogStatus;
  comment?: string;
}

interface ModalSelectStatusProps {
  visible? : boolean
  modalProps?: ModalProps
  onOk?: (values: Lead | undefined) => void
  lead?: Lead
}

function ModalSelectStatus(props: ModalSelectStatusProps) {
  const {
    visible, modalProps, onOk, lead,
  } = props;
  const { t } = useTranslation();
  const [val, setVal] = useState<Values>();
  const [loading, setLoading] = useState(false);
  const { actions, values } = useContext(DataContext);

  return (
    <Modal
      visible={visible}
      title={t('Adicionar evento')}
      confirmLoading={loading}
      onOk={async () => {
        try {
          setLoading(true);
          if (
            lead
            && val
            && val.selectedStatus?.id
            && val.selectedStatus?.id !== -1
          ) {
            const { data: leadLog } = await ApiService.leads.postAddLog({
              comment: val.comment,
              leadId: lead.id,
              statusId: val.selectedStatus?.id,
            });

            actions.setLeads(
              values.leads.map((l) => {
                if (l.id === lead.id) {
                  const logs = l.leadLogs || [];
                  logs.push(leadLog);
                  return {
                    ...l,
                    leadLogs: logs,
                  };
                }
                return l;
              }),
            );
            const newLeadState: Lead = { ...lead };
            newLeadState.leadLogs?.push(leadLog);
            Modal.success({
              title: 'Sucesso',
              content: 'Evento adicionado ao histórico com sucesso.',
            });

            if (onOk) {
              onOk(newLeadState);
            }
          }
        } catch (error) {
          Modal.error({
            title: 'Erro',
            content: 'Ocorreu um erro ao adicionar novo evento no histórico...',
          });
        } finally {
          setLoading(false);
        }
      }}
      {...modalProps}
    >
      <ModalBody onValueChange={setVal} />
    </Modal>
  );
}

ModalSelectStatus.defaultProps = {
  visible: false,
  modalProps: {},
  onOk: () => {},
  lead: undefined,
};

export default ModalSelectStatus;
