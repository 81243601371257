import React, { FC } from 'react';
import { MaskedInput } from 'antd-mask-input';
import { Form, Input } from 'antd';
import { InputDefaultShopkeeperProps } from './InputDefaultShopkeeper.types';
import './InputDefaultShopkeeper.less';

const InputDefaultShopkeeper: FC<InputDefaultShopkeeperProps> = ({ form, field }) => {
  const { name, title, inputProps = {} } = field || {};
  const { mask, addonAfter } = inputProps || {};

  return (
    <Form.Item
      {...title ? { label: title } : {}}
      name={name}
      labelCol={{ lg: 5, xs: 24 }}
      rules={field.rules}
      className="input-default"
    >
      {mask ? (
        <MaskedInput
          mask={mask}
          placeholder={inputProps.placeholder}
          value={form ? form.getFieldValue(name) : ''}
          prefix={inputProps.prefix}
          size="large"
          addonAfter={addonAfter || null}
          bordered={false}
          style={{ borderBottom: '0.5px solid #000', marginBottom: 10 }}
        />
      ) : (
        <Input
          size="large"
          bordered={false}
          style={{ borderBottom: '0.5px solid #000', marginBottom: 10 }}
          {...inputProps || {}}
        />
      )}
    </Form.Item>
  );
};

export default InputDefaultShopkeeper;
