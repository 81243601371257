/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  BrowserRouter, Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import {
  Avatar, Button, Dropdown, Menu, Modal, Row,
} from 'antd';
import { SettingFilled, UserOutlined } from '@ant-design/icons/lib/icons';
import { useTranslation } from 'react-i18next';
import { add } from 'date-fns';
import LanguageDropdown from '../Components/Molecules/LanguageDropdown';
import SidebarMenu from '../Components/Organisms/SidebarMenu/SidebarMenu';
import { AuthContext } from './Providers/Auth.context';
import PageHome from '../Pages/Dash/Home';
import PageUsersList from '../Pages/Dash/Users/UsersList';
import PageUsersForm from '../Pages/Dash/Users/UsersForm';
import PageLeadsList from '../Pages/Dash/Leads/LeadsList';
import PageLeadDetail from '../Pages/Dash/Leads/LeadDetail';
import PageOpenSeaLeadList from '../Pages/Dash/Open-Sea/OpenSeaList';
import { DataContext } from './Providers/Data.context';
import PageCategoriesList from '../Pages/Dash/Categories/List-Categories/CategoriesList';
import CategoryCreate from '../Pages/Dash/Categories/Create-Category/CategoryCreate';
import CategoryEdit from '../Pages/Dash/Categories/Edit-Category/CategoryEdit';
import PageSubsegmentsList from '../Pages/Dash/Subsegmentos/List-Subsegmets/SubsegmentsList';
import SubsegmentCreate from '../Pages/Dash/Subsegmentos/Create-Subsegment/SubsegmentCreate';
import SubsegmentEdit from '../Pages/Dash/Subsegmentos/Edit-Subsegment/SubsegmentEdit';
import PageEconomicGroupList from '../Pages/Dash/Economic-Group/List-Group/GroupList';
import EconomicGroupCreate from '../Pages/Dash/Economic-Group/Create-Group/GroupCreate';
import EconomicGroupEdit from '../Pages/Dash/Economic-Group/Edit-Group/GroupEdit';
import PageStoresList from '../Pages/Dash/Stores/List-Store/StoresList';
import StoreCreate from '../Pages/Dash/Stores/Create-Store/StoreCreate';
import StoreDetails from '../Pages/Dash/Stores/Details-Store/Store-Datail';
import StoreEdit from '../Pages/Dash/Stores/Edit-Store/StoreEdit';
import StoreUpdate from '../Pages/Dash/Stores/Update-Store/StoreUpdate';
import StoreAdd from '../Pages/Dash/Stores/Add-Store/StoreAdd';
import SubsidiaryCreate from '../Pages/Dash/Subsidiaries/Create-Subsidiary/SubsidiaryCreate';
import SubsidiaryEdit from '../Pages/Dash/Subsidiaries/Edit-Subsidiary/SubsidiaryEdit';
import ShopkeeperMenu from '../Pages/Dash/Shopkeeper-Options/Menu-Shopkeeper/ShopkeeperMenu';
import ShopkeeperStoreEdit from '../Pages/Dash/Shopkeeper-Options/Edit-Shopkeeper/ShopkeeperEdit';
import ShopkeeperSubsidiaryEdit from '../Pages/Dash/Shopkeeper-Options/Edit-Shopkeeper-Subsidiary/ShopkeeperSubsidiaryEdit';
import AddShopkeeperSubsidiaries from '../Pages/Dash/Shopkeeper-Options/Add-Shopkeeper-Subsidiaries/ShopkeeperAddSubsidiaries';
import PageModerationStoresList from '../Pages/Dash/Moderation-Panel/Moderation-Store/Md-List-Store/MdStoreList';
import ModerateStore from '../Pages/Dash/Moderation-Panel/Moderation-Store/Md-Moderate-Store/ModerateStore';
import PageSubsidiaryInModeration from '../Pages/Dash/Moderation-Panel/Moderation-Subsidiary/Md-List-Subsidiary/MdSubsidiaryList';
import SubsidiaryModeration from '../Pages/Dash/Moderation-Panel/Moderation-Subsidiary/Md-Moderate-Subsidiary/ModerationSubsidiary';
import SubsidiariesAdd from '../Pages/Dash/Subsidiaries/Add-Subsidiary/SubsidiaryAdd';
import { getHomepage } from '../Helpers/functions';

import ManagerStoresList from '../Pages/Dash/Manager/Store/ManagerStoreList';
import ManageStoreDetails from '../Pages/Dash/Manager/Store/ManagerStoreDatails';
import ManagerStoreCreate from '../Pages/Dash/Manager/Store/ManagerStoreCreate';
import ManagerStoresAdd from '../Pages/Dash/Manager/Store/ManagerStoreAdd';
import ManagerStoresUpdate from '../Pages/Dash/Manager/Store/ManagerStoreUpdate';
import ManagerStoreEdit from '../Pages/Dash/Manager/Store/ManagerStoreEdit';
import ManagerSubsidiaryCreate from '../Pages/Dash/Manager/Store/ManagerSubsidiaryCreate';
import ManagerSubsidiaryEdit from '../Pages/Dash/Manager/Store/ManagerSubsidiaryEdit';
import ManagerModerationList from '../Pages/Dash/Manager/Moderation/ManagerModerationList';
import ManagerSubsidiariesAdd from '../Pages/Dash/Manager/Store/ManagerSubsidiaryAdd';
import ApiService from '../Services/Api';
import PageFeesList from '../Pages/Dash/Consultant-Plus/Fees/Fees-List/FeesList';
import FeesEdit from '../Pages/Dash/Consultant-Plus/Fees/Fees-Edit/FeesEdit';
import PageLeadsPlusList from '../Pages/Dash/Consultant-Plus/Leads/Leads-List/LeadsList';
import LeadsPlusDetails from '../Pages/Dash/Consultant-Plus/Leads/Leads-Details/Leads-Datail';
import FeesImport from '../Pages/Dash/Consultant-Plus/Fees/Fees-Import/FeesImport';
import FeesAtt from '../Pages/Dash/Consultant-Plus/Fees/Fees-Att/FeesAtt';

const LoggedInRouter = () => {
  const { actions } = useContext(AuthContext);
  const { values } = useContext(DataContext);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [day, setDay] = useState(90);

  const userUpdate = add(new Date(values.currentUser?.updatedAt), { days: day });
  const today = (new Date());

  useEffect(() => {
    const fetchUserList = async () => {
      const { data } = await ApiService.auth.listExpirePassword();

      if (data && data?.length > 0) setDay(data[0].days);
    };
    fetchUserList();
  }, []);

  const enableSidebar = useMemo(() => {
    if (values.currentUser?.isShopkeeper && !values.currentUser?.provider) {
      return false;
    }

    if (values.currentUser?.provider) {
      return userUpdate > today;
    }

    return userUpdate < today || (!values.currentUser?.isShopkeeper && values.currentUser?.provider)
    || (values.currentUser?.isManager && !values.currentUser?.firstLogin);
  }, [userUpdate, today, values.currentUser]);

  const enableRoutes = useMemo(() => {
    if (values.currentUser?.provider) {
      return userUpdate > today;
    }

    return userUpdate < today
    || (!!values.currentUser?.isShopkeeper && !values.currentUser?.provider)
      || (values.currentUser?.isManager && !values.currentUser?.firstLogin);
  }, [userUpdate, today, values.currentUser]);

  return (
    <BrowserRouter basename={getHomepage()}>
      <Layout style={{ minHeight: '100vh' }}>

        {
          enableSidebar && (
            <SidebarMenu />
          )
        }

        <Layout>
          <Header className="px-3">
            <div className="div text-right">
              <Row align="middle" justify="end">
                <p style={{ margin: 0 }}>{values.currentUser?.name}</p>
                <Dropdown
                  className="ml-3"
                  overlay={(
                    <Menu>
                      {/* <Menu.Item onClick={() => history.push('/my-profile')}>
                      <span>Meu perfil</span>
                    </Menu.Item> */}

                      <Menu.Item danger onClick={actions.doLogout}>
                        <span>{t('Sair')}</span>
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Avatar
                    size={35}
                    icon={<UserOutlined />}
                    style={{ cursor: 'pointer' }}
                  />
                </Dropdown>
              </Row>
            </div>
          </Header>

          <Content>
            <Switch>

              <Route exact path={path} component={PageHome} />

              {/* <Route exact path="/users" component={PageUsersList} />
              <Route exact path="/users/register" component={PageUsersForm} />
              <Route exact path="/users/edit/:id" component={PageUsersForm} /> */}

              {
                enableRoutes && (
                  <>
                    <Route exact path="/leads" component={PageLeadsList} />
                    <Route exact path="/leads/details/:id" component={PageLeadDetail} />
                    <Route exact path="/opensealeads" component={PageOpenSeaLeadList} />
                    <Route exact path="/categories" component={PageCategoriesList} />
                    <Route exact path="/categories/create" component={CategoryCreate} />
                    <Route exact path="/categories/edit" component={CategoryEdit} />
                    <Route exact path="/subsegments" component={PageSubsegmentsList} />
                    <Route exact path="/subsegments/create" component={SubsegmentCreate} />
                    <Route exact path="/subsegments/edit" component={SubsegmentEdit} />
                    <Route exact path="/economics-groups" component={PageEconomicGroupList} />
                    <Route exact path="/economics-groups/create" component={EconomicGroupCreate} />
                    <Route exact path="/economics-groups/edit" component={EconomicGroupEdit} />
                    <Route exact path="/stores" component={PageStoresList} />
                    <Route exact path="/stores/create" component={StoreCreate} />
                    <Route exact path="/stores/details" component={StoreDetails} />
                    <Route exact path="/stores/edit" component={StoreEdit} />
                    <Route exact path="/stores/update" component={StoreUpdate} />
                    <Route exact path="/stores/add" component={StoreAdd} />
                    <Route exact path="/subsidiaries/create" component={SubsidiaryCreate} />
                    <Route exact path="/subsidiaries/edit" component={SubsidiaryEdit} />
                    <Route exact path="/subsidiaries/add" component={SubsidiariesAdd} />
                    {/* Prospecta + */}
                    <Route exact path="/fees" component={PageFeesList} />
                    <Route exact path="/fees/edit" component={FeesEdit} />
                    {/* <Route exact path="/fees/import" component={FeesImport} /> */}
                    <Route exact path="/fees/import" component={FeesAtt} />
                    <Route exact path="/consultant-plus/leads" component={PageLeadsPlusList} />
                    <Route exact path="/consultant-plus/leads/details" component={LeadsPlusDetails} />
                    {/* Prospecta + */}
                    <Route exact path="/shopkeeper-options" component={ShopkeeperMenu} />
                    <Route exact path="/shopkeeper-options/edit" component={ShopkeeperStoreEdit} />
                    <Route exact path="/shopkeeper-options/subsidiary/edit" component={ShopkeeperSubsidiaryEdit} />
                    <Route exact path="/shopkeeper-options/subsidiary/add" component={AddShopkeeperSubsidiaries} />
                    {/* <Route exact path="/moderation/store/list" component={PageModerationStoresList} />
              <Route exact path="/moderation/store/moderate" component={ModerateStore} /> */}
                    <Route exact path="/moderation/list" component={PageSubsidiaryInModeration} />
                    <Route exact path="/moderation/subsidiary/moderate" component={SubsidiaryModeration} />

                    <Route exact path="/manager/store" component={ManagerStoresList} />
                    <Route exact path="/manager/store/create" component={ManagerStoreCreate} />
                    <Route exact path="/manager/store/details" component={ManageStoreDetails} />
                    <Route exact path="/manager/store/edit" component={ManagerStoreEdit} />
                    <Route exact path="/manager/store/update" component={ManagerStoresUpdate} />
                    <Route exact path="/manager/store/add" component={ManagerStoresAdd} />
                    <Route exact path="/manager/subsidiary/create" component={ManagerSubsidiaryCreate} />
                    <Route exact path="/manager/subsidiary/edit" component={ManagerSubsidiaryEdit} />
                    <Route exact path="/manager/subsidiary/add" component={ManagerSubsidiariesAdd} />
                    <Route exact path="/manager/moderation" component={ManagerModerationList} />
                    <Route exact path="/manager/moderation/moderate" component={SubsidiaryModeration} />
                  </>
                )
              }
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};

export default LoggedInRouter;
