import { User } from '../../Models/User.model';
import { ApiClient } from './client';

export function listUsers() {
  return ApiClient.get<User[]>('/admin/users/manage');
}

export function getMe() {
  return ApiClient.get<User>('/admin/users/manage/me');
}
