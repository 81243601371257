/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { Lead } from '../Models/Lead.model';
import { LogStatusEnum } from '../Models/LeadLog.model';
import { ADM_PROVIDER_ID } from './consts';

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  if (width > 1200) return 'desktop';
  if (width > 992) return 'tablet';
  return 'mobile';
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue((num: number) => num + 1); // update the state to force render
}

export function leadWasContacted(lead: Lead) {
  const finishedLen = lead.leadLogs?.filter((l) => {
    return l.logStatus?.id === LogStatusEnum.CONTACTED && l.user?.provider?.id !== ADM_PROVIDER_ID;
  }).length || 0;

  const santanderLen = lead.leadLogs?.filter((l) => {
    return l.logStatus?.id === LogStatusEnum.CONTACTED && l.user?.provider?.id === ADM_PROVIDER_ID;
  }).length || 0;

  let partial = finishedLen > 0;

  const allLen = (lead.providers?.length || 0);

  const all = finishedLen >= allLen;

  if (santanderLen > 0 && partial === false) {
    partial = true;
  }

  return { partial, all };
}

export function addressIsCep(addressType: string | undefined) {
  if (!addressType) return false;

  return addressType.toUpperCase() === 'CEP';
}

export function providerContactedLead(lead: Lead, providerId: number | undefined) {
  const finishedStatus = lead.leadLogs?.filter((log) => log.logStatus?.id === LogStatusEnum.CONTACTED);

  // eslint-disable-next-line max-len
  const thisProvider = finishedStatus?.filter((f) => f.user?.provider?.id === providerId);

  return thisProvider ? thisProvider.length > 0 : false;
}

export function getHomepage() {
  try {
    const base = process.env.REACT_APP_HOMEPAGE || '';

    const replace = ['http://', 'https://', 'http:', 'https:'];

    let temp = base.toLowerCase();
    replace.forEach((r) => {
      temp = temp.replace(r, '');
    });

    return temp;
  } catch (error) {
    return '';
  }
}
