/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Button,
  Col,
  Row,
  Input,
  Select,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseSquareOutlined,
} from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import * as XLSX from 'xlsx';
import SectionTitle from '../../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../../Services/Api';
import { DataContext } from '../../../../../Base/Providers/Data.context';
import { getHomepage } from '../../../../../Helpers/functions';

interface FilterCategoriesState {
  textSearch: string | undefined
  startDate: Moment | undefined
  finishDate: Moment | undefined
  groupSearch: number | undefined
}

const { Search } = Input;
const { Option } = Select;

const initialFilterState: FilterCategoriesState = {
  textSearch: undefined,
  startDate: undefined,
  finishDate: undefined,
  groupSearch: undefined,
};

const FeesAtt = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [planilha, setPlanilha] = useState<any>();
  const [allPlanilha, setAllPlanilha] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const [successCountFinal, setSuccessCountFinal] = useState(0);
  // let successCount = 0;
  const [sent, setSent] = useState(false);

  const readUploadFile = (e: any) => {
    setSent(false);
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target!.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setAllPlanilha(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const uploadXlsx = async () => {
    setLoading(true);
    const dividedArrays = [];

    let temp = [];
    temp = allPlanilha.filter((a: any) => {
      return !a.Linha || !a.Subsegmento || !a.Produto;
    });
    if (temp.length > 0) {
      setErrorArray(temp);
      setLoading(false);
    }

    if (temp.length === 0) {
      if (!!allPlanilha) {
        for (let i = 0; i < allPlanilha.length; i += 5) {
          dividedArrays.push(allPlanilha.slice(i, i + 5));
        }
      }
      let temp = 0;
      await ApiService.subsegments.FeesDeleteMany({
        file: allPlanilha.map((a: any) => a.Subsegmento),
      });
      for (const frags of dividedArrays) {
        const { data }: any = await ApiService.subsegments.FeesAttOrImport({ file: frags });
        temp += data.count;
        setSuccessCount(temp);
      }
      setSuccessCountFinal(temp);
      setPlanilha(null);
      $('#update-store').val('');
      setLoading(false);
      // history.push('/stores');
      setSent(true);
    }
  };

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Taxas | Atualização em Lote')}
          renderExtraButton
        />
        <div className="d-flex mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto' }}>
          <Alert
            banner
            message="Baixe a planilha em Excel e utilize como base para a atualização.
            Lembre-se! É obrigatório preencher todos os dados."
          />
        </div>
        <div className="d-flex mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto' }}>
          <div style={{ display: 'flex', marginRight: 20 }}>
            <a
              href={`${getHomepage()}/modelo_taxas.xlsx`}
              download
              target="_blank"
            >
              <Button
                type="primary"
              >
                Baixar modelo de planilha
              </Button>
            </a>
          </div>
          <div style={{ display: 'flex', marginRight: 20 }}>
            <input
              disabled={loading}
              style={{ display: 'none' }}
              accept=".ods, .xlsx, .xls. xlt, .xml"
              name="updateStore"
              id="update-store"
              type="file"
              onChange={(e) => {
                setPlanilha(e.target.files![0]);
                readUploadFile(e);
              }}
            />

            <div style={{ display: 'flex' }}>
              <label
                htmlFor="update-store"
                className="select-image"
                style={{ padding: '5px 30px' }}
              >
                Selecionar planilha preenchida
              </label>
            </div>
          </div>

          {
            !!planilha && (
              <div style={{ display: 'flex' }}>
                <Button
                  loading={loading}
                  style={{ padding: '0 30px' }}
                  type="primary"
                  onClick={async () => {
                    uploadXlsx();
                  }}
                >
                  Enviar
                </Button>
              </div>
            )
          }

        </div>
        {
          !!planilha && (
            <div className="d-flex mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto', alignItems: 'center' }}>
              <p>{planilha.name}</p>
              <CloseSquareOutlined
                style={{ fontSize: 18, marginLeft: 10 }}
                onClick={(e) => {
                  setPlanilha(null);
                  $('#update-store').val('');
                }}
              />
            </div>
          )
        }

        {errorArray.length > 0 && (
          <>
            <div className="mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto', alignItems: 'center' }}>
              <p>
                {errorArray.length === 0 ? 'ERRO na seguinte linha, verifique se todos os dados foram preenchidos' : 'ERRO nas seguintes linhas, verifique se todos os dados foram preenchidos'}
              </p>
              {
                errorArray.map((a: any) => {
                  return <p>{`${a.Linha} - ${a.Subsegmento} - ${a.Produto}`}</p>;
                })
              }
            </div>
          </>
        )}
        {successCountFinal === 0 && sent === true && (
          <>
            <div className="mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto', alignItems: 'center' }}>
              <p>
                Verifique se o(s) subsegmento(s) indicado(s) existe(m)
              </p>
            </div>
          </>
        )}
        {successCountFinal > 0 && (
          <>
            <div className="mx-3 mt-4 mb-2" style={{ padding: '0 5%', margin: 'auto', alignItems: 'center' }}>
              <p>
                {successCountFinal === 1
                  ? `SUCESSO na adição e/ou atualização de ${successCountFinal} taxa`
                  : `SUCESSO na adição e/ou atualização de ${successCountFinal} taxa(s)`}
              </p>
            </div>
          </>
        )}

      </Col>
    </Row>
  );
};

export default FeesAtt;
