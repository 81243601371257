/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';

const EconomicGroupCreate = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [stores, setStores] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);

  interface ItemProps {
    label: string;
    value: number;
  }
  const [options, setOptions] = useState<ItemProps[]>([]);

  const onFinish = async (formValues: any) => {
    setLoading(true);
    await ApiService.groups.CreateEconomicGroup(formValues);
    setLoading(false);
    history.push('/economics-groups');
  };

  const listSomeStores = async (search: any) => {
    if (search.length >= 3) {
      const { data } = await ApiService.stores.ListStores({ search });
      setStores(data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setOptions(
        stores.map((e: any) => {
          return (
            {
              label: `${e.tab} - ${e.commercialName}`,
              value: e.id,
            }
          );
        }),
      );
    };

    fetchData();
  }, [stores]);

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Grupos Econômicos | Criar')}
        />
        <Form
          name="create-economics-groups"
          onFinish={onFinish}
          layout="vertical"
          style={{
            maxWidth: 700,
            margin: 'auto',
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Lojas"
            name="econmicGroup"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Select
              placeholder="Digite o TAB"
              value={selectedStores}
              options={options}
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="label"
              onChange={(event) => {
                setSelectedStores(event);
              }}
              onSearch={(element) => {
                listSomeStores(element);
              }}
            />
          </Form.Item>
          <Form.Item style={{ display: 'flex', textAlignLast: 'center' }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit" style={{ width: 175, flex: 1 }}>
                Criar Grupo Econômico
              </Button>
              <Button loading={loading} style={{ width: 175, flex: 1 }}>
                <Link to="/economics-groups">
                  Cancelar
                </Link>
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default EconomicGroupCreate;
