import * as auth from './auth';
import * as users from './users';
import * as leads from './leads';
import * as leadsopensea from './leadsopensea';
import * as categories from './categories';
import * as subsegments from './subsegments';
import * as groups from './economics-groups';
import * as stores from './store';
import * as subsidiaries from './subisidiaries';
import * as manager from './manager';

const ApiService = {
  auth,
  users,
  leads,
  leadsopensea,
  categories,
  subsegments,
  groups,
  stores,
  subsidiaries,
  manager,
};

export default ApiService;
