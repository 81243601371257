/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Upload,
  Button,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import { DataContext } from '../../../../Base/Providers/Data.context';
import ApiService from '../../../../Services/Api';

const { Option } = Select;

const CategoryCreate = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [subsegments, setSubsegmentos] = useState<any>([]);
  const [image, setImage] = useState<any>();
  const [loading, setLoading] = useState(false);

  const onFinish = async (formValues: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('image', image || '');
    const extras = JSON.stringify({
      name: formValues.name,
      subsegmentsId: formValues.subsegment,
      image: '',
    });
    formData.append('extras', extras);
    // await ApiService.categories.saveImage(formData);
    await ApiService.categories.CreateCategory(formData);
    setLoading(false);
    history.push('/categories');
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await ApiService.subsegments.ListSubsegments();

      setSubsegmentos(data);
    };
    fetchData();
  }, []);

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Categorias | Criar')}
        />

        <Form
          name="create-category"
          onFinish={onFinish}
          layout="vertical"
          style={{
            maxWidth: 700,
            margin: 'auto',
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Subsegmento"
            name="subsegment"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Select mode="multiple" maxTagCount="responsive">
              {
                subsegments.map((e: any) => {
                  return (
                    <Option value={e.id}>{`${e.code} - ${e.name}`}</Option>
                  );
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="image"
            label="Foto"
            valuePropName="Foto"
            style={{ alignContent: 'center' }}
            rules={[{ required: !image, message: 'Campo obrigatório!' }]}
          >
            <div>

              {image && (
                <div>
                  <img
                    src={
                      URL.createObjectURL(image)
                    }
                    style={{
                      width: 153,
                      borderRadius: 4,
                      marginBottom: 20,
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}

              <input
                accept="image/*"
                style={{ display: 'none' }}
                name="image"
                id="image-or-video"
                type="file"
                onChange={(event) => {
                  if (event.target.files) {
                    setImage(event.target.files[0]);
                  }
                }}
              />
              <label htmlFor="image-or-video" className="select-image">
                Selecionar Imagem
              </label>
            </div>
          </Form.Item>
          <Form.Item style={{ display: 'flex', textAlignLast: 'center' }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit" style={{ width: 130, flex: 1 }}>
                Criar categoria
              </Button>
              <Button loading={loading} style={{ width: 130, flex: 1 }}>
                <Link to="/categories/">
                  Cancelar
                </Link>
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default CategoryCreate;
