import { EconomicGroup } from '../../Models/Group.model';
import { ApiClient } from './client';

export function ListEconomicsGroups() {
  return ApiClient.get<EconomicGroup[]>('/admin/economics-groups/manage');
}

export function ListEconomicsGroupsPublic() {
  return ApiClient.get<EconomicGroup[]>('/public/clients/economics-groups');
}

export function DeleteEconomicGroup(id: number) {
  return ApiClient.httpDelete<EconomicGroup>(`/admin/economics-groups/manage/delete/${id}`);
}

export function CreateEconomicGroup(body: any) {
  return ApiClient.post<EconomicGroup>('/admin/economics-groups/manage/create', body);
}

export function EditEconomicGroup(body: any) {
  return ApiClient.post<EconomicGroup>('/admin/economics-groups/manage/edit', body);
}
