/* eslint-disable no-extra-boolean-cast */
/* eslint-disable consistent-return */
/* eslint-disable no-duplicate-case */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Select,
  Popconfirm,
  Table,
  Modal,
  Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import SectionTitle from '../../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../../Services/Api';
import { DataContext } from '../../../../../Base/Providers/Data.context';

function verifyFeeType(entry: any) {
  switch (entry) {
    case '1':
      return 'CSC';
    case '2':
      return 'CDC';
    case '3':
      return 'CDC';
    case '4':
      return 'CDCSUB';
  }
}

const { TabPane } = Tabs;
let feeType: any = 'CSC';

function callback(key: any) {
  feeType = verifyFeeType(key);
}

const FeesEdit = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [csc, setCsc] = useState<any[]>(values.subsegmentEdit?.fees.filter((a: any) => a.feeType === 'CSC' && !a.deletedAt));
  const [cdc, setCdc] = useState<any[]>(values.subsegmentEdit?.fees.filter((a: any) => a.feeType === 'CDC' && !a.deletedAt));
  const [cdcSub, setCdcSub] = useState<any[]>(values.subsegmentEdit?.fees.filter((a: any) => a.feeType === 'CDCSUB' && !a.deletedAt));
  const [subsegment, setSubsegment] = useState<any>(values.subsegmentEdit);
  const [loading, setLoading] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [subsegmentId, setSubsegmentId] = useState('');
  const [parcel, setParcel] = useState('');
  const [feeBank, setFeeBank] = useState('');
  const [feeClient, setFeeClient] = useState('');

  const showModalEdit = () => {
    setIsModalEdit(true);
  };

  const handleOkEdit = async () => {
    setLoading(true);
    setIsModalEdit(false);
    await ApiService.subsegments.EditFee({
      id: subsegmentId, parcel, feeBank, feeClient,
    });
    const { data } = await ApiService.subsegments.ListFeeBySubsgment(values?.subsegmentEdit?.id!);

    const dataFiltered = data.fees.filter((a: any) => !a.deletedAt);

    if (dataFiltered.length > 0) {
      setCsc(dataFiltered.filter((a: any) => a.feeType === 'CSC'));
      setCdc(dataFiltered.filter((a: any) => a.feeType === 'CDC'));
      setCdcSub(dataFiltered.filter((a: any) => a.feeType === 'CDCSUB'));
    }
    setParcel('');
    setFeeBank('');
    setFeeClient('');
    setSubsegmentId('');
    setLoading(false);
  };

  const handleCancelEdit = () => {
    setIsModalEdit(false);
    setParcel('');
    setFeeBank('');
    setFeeClient('');
    setSubsegmentId('');
  };

  const showModalAdd = () => {
    setIsModalAdd(true);
  };

  const handleOkAdd = async () => {
    setLoading(true);
    setIsModalAdd(false);
    await ApiService.subsegments.CreateFee({
      subsegmentId: values?.subsegmentEdit?.id, parcel, feeBank, feeType, feeClient,
    });
    const { data } = await ApiService.subsegments.ListFeeBySubsgment(values?.subsegmentEdit?.id!);

    const dataFiltered = data.fees.filter((a: any) => !a.deletedAt);

    if (dataFiltered.length > 0) {
      setCsc(dataFiltered.filter((a: any) => a.feeType === 'CSC'));
      setCdc(dataFiltered.filter((a: any) => a.feeType === 'CDC'));
      setCdcSub(dataFiltered.filter((a: any) => a.feeType === 'CDCSUB'));
    }
    setParcel('');
    setFeeBank('');
    setFeeClient('');
    setSubsegmentId('');
    setLoading(false);
  };

  const handleCancelAdd = () => {
    setIsModalAdd(false);
    setParcel('');
    setFeeBank('');
    setFeeClient('');
    setSubsegmentId('');
  };

  const columns = [
    {
      title: t('Prazo'),
      dataIndex: 'parcel',
      render: (_: unknown, record: any) => `${record.parcel || ''}`,
      sorter: (a: any, b: any) => a.parcel.localeCompare(b.parcel),
    },
    {
      title: t('Taxa Cliente'),
      dataIndex: 'feeClient',
      render: (_: unknown, record: any) => `${!!record.feeClient ? `${parseFloat(record.feeClient).toFixed(2)}%` : ''}`,
      sorter: (a: any, b: any) => a.feeClient.localeCompare(b.feeClient),
    },
    {
      title: t('Taxa Banco'),
      dataIndex: 'feeBank',
      render: (_: unknown, record: any) => `${!!record.feeBank ? `${parseFloat(record.feeBank).toFixed(2)}%` : ''}`,
      sorter: (a: any, b: any) => a.feeBank.localeCompare(b.feeBank),
    },
    {
      title: t('Ações'),
      width: 230,
      dataIndex: 'action',
      render: (text: string, record: any) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            loading={loading}
            size="small"
            style={{ width: '100%' }}
            onClick={async () => {
              showModalEdit();
              setSubsegmentId(record.id);
              setParcel(record.parcel);
              setFeeBank(record.feeBank);
              setFeeClient(record.feeClient);
            }}
          >
            Editar
          </Button>
          <Popconfirm
            title="Excluir?"
            onConfirm={async () => {
              try {
                setLoading(true);
                await ApiService.subsegments.DeleteFee(record.id);
                const { data } = await ApiService.subsegments.ListFeeBySubsgment(values?.subsegmentEdit?.id!);

                const dataFiltered = data.fees.filter((a: any) => !a.deletedAt);

                if (dataFiltered.length > 0) {
                  setCsc(dataFiltered.filter((a: any) => a.feeType === 'CSC'));
                  setCdc(dataFiltered.filter((a: any) => a.feeType === 'CDC'));
                  setCdcSub(dataFiltered.filter((a: any) => a.feeType === 'CDCSUB'));
                }

                // setFilteredSegments(data);
              } catch (error) {
                //
              } finally {
                setLoading(false);
              }
            }}
          >
            <Button type="primary" size="small" style={{ width: '100%' }}> Excluir</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onFinish = async (formValues: any) => {
    setLoading(true);
    await ApiService.subsegments.EditSubsegment({
      id: subsegment.id,
      code: formValues.code,
      name: formValues.name.toUpperCase(),
      text: formValues.text,
      parcelCsc: formValues.parcelCsc,
      parcelCdc: formValues.parcelCdc,
      parcelCdcSub: formValues.parcelCdcSub,
    });
    setLoading(false);
    // history.push('/subsegments');
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await ApiService.subsegments.ListFeeBySubsgment(values?.subsegmentEdit?.id!);

      const dataFiltered = data.fees.filter((a: any) => !a.deletedAt);

      // console.log(dataFiltered);

      if (dataFiltered.length > 0) {
        setCsc(dataFiltered.filter((a: any) => a.feeType === 'CSC'));
        setCdc(dataFiltered.filter((a: any) => a.feeType === 'CDC'));
        setCdcSub(dataFiltered.filter((a: any) => a.feeType === 'CDCSUB'));
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <SectionTitle
            title={t(`Subsegmentos | ${values?.subsegmentEdit?.code} - ${values?.subsegmentEdit?.name}`)}
          />

          {/* <Form
            name="edit-subsegment"
            onFinish={onFinish}
            layout="vertical"
            style={{
              maxWidth: 900,
              margin: 'auto',
            }}
          >
            <Form.Item
              label="Código"
              name="code"
              initialValue={values?.subsegmentEdit?.code}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            // validateStatus={validate ? 'error' : 'success'}
            // help={validate ? 'Should be combination of numbers & alphabets' : ''}
            >
              <Input
                readOnly
              />
            </Form.Item>
            <Form.Item
              label="Nome"
              name="name"
              initialValue={values?.subsegmentEdit?.name}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input style={{ textTransform: 'uppercase' }} readOnly />
            </Form.Item>
          </Form> */}
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{ maxWidth: 900, margin: 'auto' }}
        >
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="CSC" key="1">

              <div
                style={{
                  marginTop: 40,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {/* <h2>Produtos</h2> */}

                <div className="align-items-center d-flex mx-3 mb-2">
                  <Button
                    type="primary"
                    onClick={() => showModalAdd()}
                  >
                    Adicionar Taxa
                  </Button>
                </div>
              </div>

              {loading ? (
                <>
                  <Row align="middle" justify="center">
                    <Col style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    >
                      <LoadingOutlined style={{ fontSize: 60 }} />
                    </Col>
                  </Row>
                </>
              ) : (
                <Table
                  columns={columns}
                  dataSource={csc || []}
                  loading={false}
                  pagination={{ position: ['bottomCenter'] }}
                />
              )}

            </TabPane>
            <TabPane tab="CDC" key="2">
              <Tabs defaultActiveKey="3" onChange={callback}>
                <TabPane tab="TRADICIONAL" key="3">

                  <div
                    style={{
                      marginTop: 40,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {/* <h2>Produtos</h2> */}

                    <div className="align-items-center d-flex mx-3 mb-2">
                      <Button
                        type="primary"
                        onClick={() => showModalAdd()}
                      >
                        Adicionar Taxa
                      </Button>
                    </div>
                  </div>

                  {loading ? (
                    <>
                      <Row align="middle" justify="center">
                        <Col style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        >
                          <LoadingOutlined style={{ fontSize: 60 }} />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={cdc || []}
                      loading={false}
                      pagination={{ position: ['bottomCenter'] }}
                    />
                  )}

                </TabPane>
                <TabPane tab="SUBSIDIADO" key="4">

                  <div
                    style={{
                      marginTop: 40,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {/* <h2>Produtos</h2> */}

                    <div className="align-items-center d-flex mx-3 mb-2">
                      <Button
                        type="primary"
                        onClick={() => showModalAdd()}
                      >
                        Adicionar Taxa
                      </Button>
                    </div>
                  </div>

                  {loading ? (
                    <>
                      <Row align="middle" justify="center">
                        <Col style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        >
                          <LoadingOutlined style={{ fontSize: 60 }} />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={cdcSub || []}
                      loading={false}
                      pagination={{ position: ['bottomCenter'] }}
                    />
                  )}

                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      <Modal title="Editar Produto/Serviço" visible={isModalEdit} onOk={handleOkEdit} onCancel={handleCancelEdit}>
        <Space>
          <Form.Item
            label="Prazo"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input
              value={parcel}
              placeholder="Prazo"
              type="number"
              onChange={(element) => setParcel(element.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Taxa Cliente"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input
              value={feeClient}
              placeholder="Taxa Cliente"
              onChange={(element) => setFeeClient(element.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Taxa Banco"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input
              value={feeBank}
              placeholder="Taxa Banco"
              onChange={(element) => {
                setFeeBank(element.target.value);
              }}
            />
          </Form.Item>
        </Space>
      </Modal>
      <Modal title="Adicionar Produto/Serviço" visible={isModalAdd} onOk={handleOkAdd} onCancel={handleCancelAdd}>
        <Space>
          <Form.Item
            label="Prazo"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input
              value={parcel}
              placeholder="Prazo"
              type="number"
              onChange={(element) => setParcel(element.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Taxa Cliente"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input
              value={feeClient}
              placeholder="Taxa Cliente"
              onChange={(element) => setFeeClient(element.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Taxa Banco"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input
              value={feeBank}
              placeholder="Taxa Banco"
              onChange={(element) => setFeeBank(element.target.value)}
            />
          </Form.Item>
        </Space>
      </Modal>
    </>
  );
};

export default FeesEdit;
