/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Popconfirm,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SectionTitle from '../../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../../Services/Api';
import { DataContext } from '../../../../../Base/Providers/Data.context';

const containerStyle = {
  width: '400px',
  height: '400px',
  margin: 'auto',
};

const SubsidiaryModeration = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [subsidiary, setSubsdiary] = useState(values?.subsidiaryEdit);
  const [description, setDescription] = useState(values?.subsidiaryEdit?.description);
  const [status, setStatus] = useState<'approve' | 'reject'>('approve');
  const [textReject, setTextReject] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (formValues: any) => {
    setLoading(true);
    if (status === 'approve') {
      const formData = new FormData();
      const extras = JSON.stringify({
        ...formValues,
        description,
        id: values?.subsidiaryEdit?.id,
        isAnalysis: false,
        storeId: values?.subsidiaryEdit?.store.id,
      });
      formData.append('extras', extras);
      const result = await ApiService.subsidiaries.ApproveEdition(formData);
      setLoading(false);
      if (result) history.push('/moderation/list');
    } else {
      const formData = new FormData();
      const extras = JSON.stringify({
        ...formValues,
        description,
        id: values?.subsidiaryEdit?.id,
        isAnalysis: false,
        storeId: values?.subsidiaryEdit?.store.id,
      });
      formData.append('extras', extras);
      const result = await ApiService.subsidiaries.RejectEdition(formData);
      setLoading(false);
      if (result) history.push('/moderation/list');
    }
  };

  const handleOk = () => {
    setStatus('reject');
    onFinish({
      commercialName: values?.subsidiaryEdit?.commercialName,
      description,
      id: values?.subsidiaryEdit?.id,
      isAnalysis: false,
      storeId: values?.subsidiaryEdit?.store.id,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setStatus('reject');
    setIsModalVisible(false);
  };

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Moderar | Filial')}
        />
        <Form
          name="create-stores"
          onFinish={onFinish}
          layout="vertical"
          style={{
            maxWidth: 800,
            margin: 'auto',
          }}
        >
          <Form.Item
            label="Nome Fantasia"
            name="commercialName"
            initialValue={subsidiary?.commercialName}
          // rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            valuePropName={description}
            initialValue={description}
          // rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input.TextArea
              placeholder="Descreva as características da loja"
              rows={5}
              value={description}
              maxLength={432}
              showCount
              onChange={
                (event) => { setDescription(event.target.value); }
              }
            />
          </Form.Item>

          <Form.Item style={{ display: 'flex', textAlignLast: 'center', marginTop: 20 }}>
            <Space>
              <Popconfirm
                title="Aprovar descrição!"
                onConfirm={() => {
                  setStatus('approve');
                  onFinish({
                    commercialName: values?.subsidiaryEdit?.commercialName,
                    description,
                    id: values?.subsidiaryEdit?.id,
                    isAnalysis: false,
                    storeId: values?.subsidiaryEdit?.store.id,
                  });
                }}
              >
                <Button loading={loading} type="primary" style={{ width: 130, flex: 1 }}>
                  Aprovar
                </Button>
              </Popconfirm>
              <Button loading={loading} style={{ width: 130, flex: 1 }} onClick={() => { setStatus('reject'); setIsModalVisible(true); }}>
                Rejeitar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
      <>
        <Modal
          title="Rejeitar descrição!"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={() => {
            setStatus('reject');
            handleCancel();
          }}
          footer={[
            <Button loading={loading} key="back" onClick={handleCancel}>
              Voltar
            </Button>,
            <Button loading={loading} key="submit" type="primary" onClick={handleOk} disabled={!textReject}>
              Enviar
            </Button>,
          ]}
        >
          <label>Digite o motivo da rejeição</label>
          <Input.TextArea
            rows={5}
            value={textReject}
            maxLength={432}
            showCount
            onChange={(event) => {
              setTextReject(event.target.value);
            }}
          />
        </Modal>
      </>
    </Row>
  );
};

export default SubsidiaryModeration;
