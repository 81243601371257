import { Category } from '../../Models/Category.model';
import { ApiClient } from './client';

export function ListCategories() {
  return ApiClient.get<Category[]>('/admin/categories/manage');
}

export function ListCategoriesPagination(body: any) {
  return ApiClient.post<any>('/public/clients/categories', body);
}

export function DeleteCategory(id: number) {
  return ApiClient.httpDelete<Category>(`/admin/categories/manage/delete/${id}`);
}

export function CreateCategory(body: any) {
  return ApiClient.post('/admin/categories/manage/create', body);
}

export function saveImage(body: any) {
  return ApiClient.post('/admin/categories/manage/image', body);
}

export function EditCategory(body: any) {
  return ApiClient.post('/admin/categories/manage/edit', body);
}
