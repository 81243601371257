import { LeadOpenSea } from '../../Models/LeadOpenSea.model';
import { ApiClient } from './client';

export function listLeads() {
  return ApiClient.get<LeadOpenSea[]>('/admin/opensealeads/manage');
}

export function getById(id: any) {
  return ApiClient.get<LeadOpenSea>(`/admin/opensealeads/manage${id}`);
}
