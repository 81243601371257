/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext, useRef, useCallback,
} from 'react';
import {
  Button,
  Col,
  Row,
  Table,
  Input,
  Menu,
  Dropdown,
  DatePicker,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FilterOutlined, CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment, { Moment } from 'moment';
import SectionTitle from '../../../Components/Molecules/SectionTitle';
import TableActionButtons from '../../../Components/Molecules/TableActionButtons';
import IsActiveTag from '../../../Components/Atoms/IsActiveTag';
import ApiService from '../../../Services/Api';

import { DataContext } from '../../../Base/Providers/Data.context';
import { Lead } from '../../../Models/Lead.model';
import { leadWasContacted, providerContactedLead } from '../../../Helpers/functions';
import { ADM_PROVIDER_ID } from '../../../Helpers/consts';
import { generateReportSheet, getAllLeads } from '../../../Helpers/sheet';
import { SearchLeadResponse } from '../../../Services/Api/leads';
import ModalLoading from '../../../Components/Atoms/ModalLoading/ModalLoading';

enum FilterStatus {
  ALL = 0,
  FINISHED = 1,
  NOT_FINISHED = 2,
}

interface FilterLeadsState {
  textSearch: string | undefined
  providerId: number | undefined
  status: FilterStatus
  startDate: Moment | undefined
  finishDate: Moment | undefined
}

const { Search } = Input;

const initialFilterState: FilterLeadsState = {
  textSearch: undefined,
  providerId: undefined,
  status: FilterStatus.ALL,
  startDate: undefined,
  finishDate: undefined,
};

const PageLeadsList = () => {
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(true);

  const [filterButtonText, setFilterButtonText] = useState('Todos Distribuidores');
  const [filterStatusText, setFilterStatusText] = useState('Todos os status');

  const [filterState, setFilterState] = useState<FilterLeadsState>(initialFilterState);
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [searchResult, setSearchResult] = useState<SearchLeadResponse>();
  const [exporting, setExporting] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);

  const rangePickerRef = useRef<any>();

  const getTooltipText = (lead: Lead) => {
    return (
      <Col>
        {lead.providers?.map((p) => {
          return (
            <Row
              align="middle"
              justify="start"
              key={`lead-tooltip-${p.id}-${lead.id}`}
            >
              <p style={{ margin: 0, marginRight: '5px' }}>{p.name}</p>
              <IsActiveTag
                partial={false}
                isActive={providerContactedLead(lead, p.id)}
              />
            </Row>
          );
        })}
        <Row
          align="middle"
          justify="start"
          key={`lead-tooltip-std-${lead.id}`}
        >
          <p style={{ margin: 0, marginRight: '5px' }}>Santander</p>
          <IsActiveTag
            partial={false}
            isActive={providerContactedLead(lead, ADM_PROVIDER_ID)}
          />
        </Row>
      </Col>
    );
  };

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'name',
      render: (_: unknown, record: Lead) => `${record.name}`,
      sorter: (a: Lead, b: Lead) => a.name.localeCompare(b.name),
    },
    {
      title: t('E-mail'),
      dataIndex: 'email',
      render: (text: string) => text,
      sorter: (a: Lead, b: Lead) => a.email.localeCompare(b.email),
    },
    {
      title: t('Telefone'),
      dataIndex: 'phoneNumber',
      render: (text: string) => text,
      sorter: (a: Lead, b: Lead) => a.phoneNumber.localeCompare(b.phoneNumber),
    },
    {
      title: t('Concessionária'),
      dataIndex: 'dealership',
      render: (text: string) => text.toUpperCase(),
      sorter: (a: Lead, b: Lead) => a.dealership.localeCompare(b.dealership),
    },
    {
      title: t('Data de criação'),
      dataIndex: 'createdAt',
      render: (text: string) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
      defaultSortOrder: 'descend' as 'descend',
      sorter: (a: Lead, b: Lead) => moment(a.createdAt).diff(b.createdAt),
    },
    {
      title: t('Contatado'),
      dataIndex: 'isActive',
      render: (_: unknown, record: Lead) => (
        <Tooltip
          title={getTooltipText(record)}
          placement="top"
        >
          <Row align="middle" justify="center" style={{ flex: 1 }}>
            <IsActiveTag
              partial={filterState.providerId ? false : leadWasContacted(record).partial && !leadWasContacted(record).all}
              isActive={filterState.providerId ? providerContactedLead(record, filterState.providerId) : leadWasContacted(record).partial}
            />
          </Row>
        </Tooltip>
      ),
    },
    {
      title: t('Ações'),
      dataIndex: 'action',
      render: (text:string, record: Lead) => (
        <TableActionButtons
          onDetails={`/leads/details/${record.id}`}
          lead={record}
          disableFinishButton={false}
        />
      ),
    },
  ];

  const dropdownProviders = (
    <Menu>
      <Menu.Item
        key="menu-drop-all"
        onClick={(e) => {
          setFilterState({
            ...filterState,
            providerId: undefined,
          });
          setFilterButtonText('Todos Distribuidores');
        }}
      >
        Todos Distribuidores
      </Menu.Item>
      {values.providers.map((prov) => (
        <Menu.Item
          key={`menu-drop-${prov.id}`}
          onClick={(e) => {
            setFilterState(
              {
                ...filterState,
                providerId: prov.id,
              },
            );

            setFilterButtonText(prov.name);
          }}
        >
          {prov.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const dropdownFinished = (
    <Menu>
      <Menu.Item
        key="menu-dropf-all"
        onClick={(e) => {
          setFilterState({
            ...filterState,
            status: FilterStatus.ALL,
          });
          setFilterStatusText('Todos os status');
        }}
      >
        Todos os status
      </Menu.Item>
      <Menu.Item
        key="menu-dropf-true"
        onClick={(e) => {
          setFilterState({
            ...filterState,
            status: FilterStatus.FINISHED,
          });
          setFilterStatusText('Apenas contatados');
        }}
      >
        Apenas contatados
      </Menu.Item>
      <Menu.Item
        key="menu-dropf-false"
        onClick={(e) => {
          setFilterState({
            ...filterState,
            status: FilterStatus.NOT_FINISHED,
          });
          setFilterStatusText('Não contatados');
        }}
      >
        Não contatados
      </Menu.Item>
    </Menu>
  );

  const resetFilterState = () => {
    setFilterButtonText('Todos Distribuidores');
    setFilterStatusText('Todos os status');
    setFilterState(initialFilterState);
  };

  const fetchExtraData = async () => {
    try {
      // const { data: providers } = await ApiService.leads.getProviders();
      // const { data: status } = await ApiService.leads.getStatus();

      const [resProviders, resStatus] = await Promise.all([ApiService.leads.getProviders(), ApiService.leads.getStatus()]);
      const providers = resProviders.data;
      const status = resStatus.data;
      actions.setProviders(providers || []);
      actions.setStatus(status);
    } catch (error) {
      //
    }
  };

  const fetchUserListV2 = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await ApiService.leads.searchLeads({
        page: Math.max(page - 1, 0),
        endDate: filterState.finishDate?.toISOString(),
        startDate: filterState.startDate?.toISOString(),
        search: filterState.textSearch,
        offset,
        providers: filterState.providerId ? [filterState.providerId] : [],
        status: filterState.status,
      });
      setSearchResult(data);
      setFilteredLeads(data.items);
    } catch (error) {
      setSearchResult(undefined);
    } finally {
      setLoading(false);
    }
  }, [filterState, page, offset]);

  useEffect(() => {
    fetchUserListV2();
    fetchExtraData();
  }, []);

  useEffect(() => {
    fetchUserListV2();
  }, [filterState, values.leads, offset, page]);

  return (
    <Row>
      <ModalLoading visible={exporting} okText={`${Math.ceil(exportProgress)}%`} />
      <Col span={24}>
        <SectionTitle
          title={t('Leads')}
          to="/leads/register"
          renderExtraButton
          extraText="Exportar"
          renderRefreshButton
          onRefreshClick={() => {
            fetchUserListV2();
          }}
          onExtraClick={() => {
            setExporting(true);
            getAllLeads({
              ...filterState,
              startDate: filterState.startDate?.toISOString(),
              endDate: filterState.finishDate?.toISOString(),
            }, (progress) => {
              setExportProgress(progress);
            }).then((allLeads) => {
              const result = generateReportSheet(allLeads);
              if (result.success) {
                const base64Excel = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.data}`;
                const elem = document.getElementById('download-exportacao') as HTMLLinkElement;
                if (!elem) {
                  window.location.href = base64Excel;
                } else {
                  elem.href = base64Excel;
                  elem.click();
                }
              }
            }).finally(() => {
              setExporting(false);
              setExportProgress(0);
            });
          }}
        />

        <a id="download-exportacao" style={{ display: 'none' }} href="/" download>Fazer Download</a>

        <div className="align-items-center justify-content-end d-flex mx-3 mt-4 mb-2">
          <Search
            onSearch={(e) => {
              setFilterState({
                ...filterState,
                textSearch: e,
              });
              setPage(1);
            }}
            enterButton
            allowClear
            placeholder={t('Digite para buscar')}
            size="large"
            // onChange={(e) => setFilterState({
            //   ...filterState,
            //   textSearch: e.target.value,
            // })}
          />

          <Button
            type="primary"
            icon={<FilterOutlined />}
            size="large"
            onClick={() => setOpenFilter((op) => !op)}
          />

        </div>

        {openFilter && (
          <Row align="middle" justify="start" style={{ padding: '1rem' }}>
            {values.currentUser && values.currentUser.isMaster && (
              <Col style={{ marginRight: '1rem' }}>
                <Dropdown
                  overlay={dropdownProviders}
                  trigger={['click']}
                  placement="bottomRight"
                  arrow
                >
                  <Button
                    type="ghost"
                    icon={<CaretDownOutlined />}
                    size="large"
                  >
                    {filterButtonText}
                  </Button>
                </Dropdown>
              </Col>
            )}
            <Col style={{ marginRight: '1rem' }}>
              <Dropdown
                trigger={['click']}
                placement="bottomRight"
                arrow
                overlay={dropdownFinished}
              >
                <Button
                  type="ghost"
                  icon={<CaretDownOutlined />}
                  size="large"
                >
                  {filterStatusText}
                </Button>
              </Dropdown>
            </Col>

            <Col style={{ marginRight: '1rem' }}>
              <DatePicker.RangePicker
                ref={rangePickerRef}
                size="large"
                locale={locale}
                onCalendarChange={(calendarValues) => {
                  if (!calendarValues) {
                    setFilterState({
                      ...filterState,
                      startDate: undefined,
                      finishDate: undefined,
                    });
                    return;
                  }

                  const [startDate, finishDate] = calendarValues as Array<Moment>;

                  setFilterState({
                    ...filterState,
                    startDate,
                    finishDate,
                  });
                }}
              />
            </Col>

            <Col>
              <Button
                size="large"
                onClick={resetFilterState}
              >
                Limpar Filtros
              </Button>
            </Col>
          </Row>
        )}

        {loading ? (
          <>
            <Row align="middle" justify="center">
              <Col style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                <LoadingOutlined style={{ fontSize: 60 }} />
              </Col>
            </Row>
          </>
        ) : (
          <Table
            columns={columns}
            dataSource={filteredLeads || []}
            loading={false}
            pagination={{
              position: ['bottomCenter'],
              onChange: (event) => { setPage(event); },
              current: page,
              total: searchResult?.totalItems || 0,
              pageSize: offset,
              showSizeChanger: true,
              onShowSizeChange: ((p, o) => {
                setPage(p);
                setOffset(o);
              }),
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default PageLeadsList;
