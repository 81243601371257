/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useEffect, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useParams } from 'react-router-dom';
import {
  Col, Row, Divider, Button, Table, Modal, Tooltip,
} from 'antd';
import {
  LoadingOutlined, PlusOutlined, DeleteOutlined, CommentOutlined,
} from '@ant-design/icons';
import './LeadDetail.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../../Base/Providers/Data.context';
import { Lead } from '../../../Models/Lead.model';
import ApiService from '../../../Services/Api';
import { addressIsCep, providerContactedLead } from '../../../Helpers/functions';
import SectionTitle from '../../../Components/Molecules/SectionTitle';
import IsActiveTag from '../../../Components/Atoms/IsActiveTag';
import { Provider } from '../../../Models/Provider.model';
import { LeadLog } from '../../../Models/LeadLog.model';
import { ADM_PROVIDER_ID } from '../../../Helpers/consts';
import ModalSelectStatus from '../../../Components/Organisms/ModalSelectStatus';

const PageLeadDetail = () => {
  const { values, actions } = useContext(DataContext);
  const [lead, setLead] = useState<Lead>();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const url: any = useParams();
  const { t } = useTranslation();

  const providerColumns = [
    {
      title: t('Nome'),
      dataIndex: 'name',
      render: (_: unknown, record: Provider) => `${record.name}`,
      sorter: (a: Provider, b: Provider) => a.name.localeCompare(b.name),
    },
    {
      title: t('Contatado'),
      dataIndex: 'isActive',
      render: (_: unknown, record: Provider) => (
        <IsActiveTag
          partial={false}
          isActive={lead ? providerContactedLead(lead, record.id) : false}
        />
      ),
    },
  ];

  const historyColumns = [
    {
      title: t('Evento'),
      dataIndex: 'status',
      render: (_: unknown, record: any) => record.status,
    },
    {
      title: t('Data'),
      dataIndex: 'createdAt',
      render: (_: unknown, record: LeadLog) => moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss'),
      sorter: (a: LeadLog, b: LeadLog) => moment(a.createdAt).diff(b.createdAt),
    },
    {
      title: t('Usuário'),
      dataIndex: 'user.name',
      render: (_: unknown, record: LeadLog) => record.user?.name || '',
      // sorter: (a: LeadLog, b: LeadLog) => a.user?.name.localeCompare(b.user?.name || '') || 0,
    },
    {
      title: t('Fornecedor'),
      dataIndex: 'user.provider.name',
      render: (_: unknown, record: LeadLog) => record.user?.provider?.name || '',
      // sorter: (a: LeadLog, b: LeadLog) => a.user?.provider?.name.localeCompare(b.user?.provider?.name || '') || 0,
    },
    {
      title: t('Ações'),
      dataIndex: '',
      render: (_: unknown, record: LeadLog) => {
        const canDelete = record.user?.id === values.currentUser?.id;
        const hasText = !!record.comment;

        return (
          <>
            {hasText && (
              <Tooltip
                title={t('Ver observações')}
              >
                <Button
                  type="ghost"
                  size="small"
                  className="mr-1"
                  icon={<CommentOutlined />}
                  onClick={() => {
                    Modal.info({
                      title: t('Observações'),
                      content: record.comment,
                    });
                  }}
                />
              </Tooltip>
            )}

            {canDelete && (
            <Tooltip
              title={t('Excluir')}
            >
              <Button
                type="ghost"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: 'Exclusão',
                    content: 'Deseja excluir esse item do histórico?',
                    onOk: async () => {
                      try {
                        if (!lead) return;

                        await ApiService.leads.deleteLog(record.id);

                        actions.setLeads(
                          values.leads.map((l) => {
                            if (l.id === lead.id) {
                              const logs = l.leadLogs?.filter((log) => log.id !== record.id);
                              return {
                                ...l,
                                leadLogs: logs,
                              };
                            }
                            return l;
                          }),
                        );

                        setLead({
                          ...lead,
                          leadLogs: lead.leadLogs?.filter((log) => log.id !== record.id),
                        });
                      } catch (error) {
                        Modal.error({
                          title: 'Erro',
                          content: 'Ocorreu um erro ao tentar remove o item do histórico',
                        });
                      }
                    },
                  });
                }}
              />
            </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  function getProviders(): any[] {
    const base = lead?.providers || [];
    const std = { name: 'Santander', id: ADM_PROVIDER_ID };

    return [...base, std];
  }

  function getLogs(): any[] {
    try {
      const creation = { status: t('Criação'), createdAt: lead?.createdAt };

      const remaining: any[] = lead?.leadLogs || [];

      const filtered = remaining.map((r) => {
        return {
          ...r,
          status: t(r.logStatus?.name || ''),
        };
      });

      return [creation, ...filtered];
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    const fetchLead = async () => {
      try {
        const { data } = await ApiService.leads.getById(url.id);
        // eslint-disable-next-line no-console
        setLead(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchLead();
  }, []);

  if (loading) {
    return (
      <>
        <Row>
          <Col span={24}>
            <SectionTitle title={t('Leads | Detalhes')} to="/leads/register" />
          </Col>
        </Row>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <LoadingOutlined style={{ fontSize: 60 }} />
        </Content>
      </>
    );
  }

  return (
    <Col>
      <Row>
        <Col span={24}>
          <SectionTitle title={t('Leads | Detalhes')} to="/leads/register" />
        </Col>
      </Row>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
          background: 'white',
          borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h2 style={{ fontSize: '28px' }}>Dados Pessoais</h2>
          </Col>

          <Col span={12}>
            <p>Nome: {lead?.name}</p>
          </Col>

          <Col span={12}>
            <p>Documento: {lead?.document}</p>
          </Col>

          <Col span={12}>
            <p>Email: {lead?.email}</p>
          </Col>

          <Col span={12}>
            <p>Telefone: {lead?.phoneNumber}</p>
          </Col>

          <Col span={24}>
            <p>Nome do Contato: {lead?.contact}</p>
          </Col>

          <Col span={24}>
            <p>Cooperativa/Indústria Integrada: {lead?.management}</p>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h2 style={{ fontSize: '28px' }}>Dados de Endereço</h2>
          </Col>
          {addressIsCep(lead?.addressType) ? (
            <Row style={{ width: '100%' }} gutter={[16, 16]}>
              <Col style={{ flex: 1 }}>
                <Col>
                  <p>CEP: {lead?.zipCode}</p>
                </Col>
                <Col>
                  <p>Tipo de Endereço: {lead?.addressType}</p>
                </Col>
                <Col>
                  <p>Logradouro: {lead?.address}</p>
                </Col>
                <Col>
                  <p>Número: {lead?.addressNumber}</p>
                </Col>
                <Col>
                  <p>Complemento: {lead?.complement}</p>
                </Col>
                <Col>
                  <p>Bairro: {lead?.district}</p>
                </Col>
              </Col>
              <Col style={{ flex: 1 }}>
                <Col>
                  <p>Cidade: {lead?.city}</p>
                </Col>
                <Col>
                  <p>Estado: {lead?.state}</p>
                </Col>
                <Col>
                  <p>País: {lead?.country}</p>
                </Col>
              </Col>
            </Row>
          ) : (
            <>
              <Col span={16}>
                <p>Tipo de Endereço: {lead?.addressType}</p>
              </Col>
              <Col span={12}>
                <p>Latitude: {lead?.latitude}</p>
              </Col>
              <Col span={12}>
                <p>Longitude: {lead?.longitude}</p>
              </Col>
            </>
          )}
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h2 style={{ fontSize: '28px' }}>Dados de Energia Elétrica</h2>
          </Col>

          <Col span={24}>
            <p>Concessionária de Energia Elétrica: {lead?.dealership}</p>
          </Col>

          <Col span={8}>
            <p>R$ {lead?.lastBill1}</p>
          </Col>

          <Col span={8}>
            <p>R$ {lead?.lastBill2}</p>
          </Col>

          <Col span={8}>
            <p>R$ {lead?.lastBill3}</p>
          </Col>
        </Row>

        {values.currentUser && values.currentUser.isMaster && (
          <>
            <Divider />
            <Col>
              <Col span={24}>
                <h2 style={{ fontSize: '28px' }}>Fornecedores</h2>
              </Col>
              <Table
                columns={providerColumns}
                dataSource={getProviders()}
                loading={false}
                pagination={{ position: ['bottomCenter'] }}
              />
            </Col>
          </>
        )}

        <Divider />
        <Col>
          <Row align="middle" justify="space-between" className="mb-3">
            <Col>
              <h2 className="m-0" style={{ fontSize: '28px' }}>
                Histórico
              </h2>
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowModal((visible) => !visible);
                }}
              >
                Adicionar Evento
              </Button>
            </Col>
          </Row>
          <Table
            columns={historyColumns}
            dataSource={getLogs()}
            loading={false}
            pagination={{ position: ['bottomCenter'] }}
          />
        </Col>
      </Content>
      <ModalSelectStatus
        visible={showModal}
        lead={lead}
        onOk={async (newLeadState) => {
          try {
            if (newLeadState) {
              setLead(newLeadState);
            }
          } catch (error) {
            //
          } finally {
            setShowModal(false);
          }
        }}
        modalProps={{
          onCancel: () => {
            setShowModal(false);
          },
        }}
      />
    </Col>
  );
};

export default PageLeadDetail;
