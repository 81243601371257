import React from 'react';
import { getHomepage } from '../../../Helpers/functions';

const DefaultLogo = () => (
  <div className="mb-4 d-flex justify-content-center align-items-center">
    <img
      src={`${getHomepage()}/images/logo.svg`}
      style={{ maxWidth: '550px' }}
      alt="Logo"
    />
  </div>
);

export default DefaultLogo;
