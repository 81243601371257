import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import ApiService from '../../Services/Api';

export type ContextDispatchProps = {
  doLogin: (token: string) => void,
  doLogout: () => void,
};

type ContextProps = {
  value: any,
  actions: ContextDispatchProps
};

export const AuthContext = React.createContext({
  value: {},
  actions: {},
} as ContextProps);

const AUTH_KEY: string = process.env.REACT_APP_AUTH_KEY || '';

export const AuthProvider = ({ children }: { children: (token: any) => JSX.Element }) => {
  const item = sessionStorage.getItem(AUTH_KEY);
  const [authData, setAuthData] = useState<string>(item || '');

  const doLogin = (token: string): void => {
    sessionStorage.setItem(AUTH_KEY, token);
    setAuthData(token);
  };

  const doLogout = (): void => {
    try {
      const oldToken = sessionStorage.getItem(process.env.REACT_APP_AUTH_KEY || '');
      ApiService.auth.logout(oldToken || '').finally(() => {
        Sentry.configureScope((scope) => scope.clear());
        sessionStorage.removeItem(AUTH_KEY);
        sessionStorage.removeItem('shopkeeperLoged');

        setAuthData('');
        try {
          window.parent.postMessage({
            panelHeight: 700,
            action: 'update-iframe-height',
          }, '*');
        } catch (error) {
        //
        }
      });
    } catch (e) {
      //
    }
  };

  return (
    <AuthContext.Provider
      value={{
        value: authData,
        actions: { doLogin, doLogout },
      }}
    >
      {(
        children({
          state: authData,
          dispatch: { doLogin, doLogout },
        })
      )}
    </AuthContext.Provider>
  );
};
