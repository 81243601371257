/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import {
  Button, Col, Form, message,
} from 'antd';
import { TemplateLogin, TemplateLoginProps } from '../Components/Templates/TemplateLogin';
import { FormInputProps } from '../Components/Molecules/InputDefault';
import ApiService from '../Services/Api';
import '../Components/Templates/TemplateLogin/TemplateLogin.css';
import DefaultLogo from '../Components/Atoms/DefaultLogo';

const getFields = (t: TFunction): FormInputProps[] => ([
  {
    name: 'email',
    inputProps: {
      placeholder: t('E-mail'),
      prefix: <MailOutlined />,
      type: 'email',
    },
    rules: [{
      type: 'email',
      required: true,
      message: t('Informe um e-mail válido'),
    }],
  },
]);

type IForgetPassword = {
  email: string
};

const PageForgetPassword = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [mailSent, setMailSent] = useState(false);
  const [email, setEmail] = useState('');

  const fields: TemplateLoginProps<IForgetPassword> = {
    onSubmit: async (model) => {
      setEmail(model.email);
      const { data } = await ApiService.auth.recoverPassword('pt', model.email);
      if (!!data) {
        setMailSent(true);
      } else {
        message.error({
          content: 'E-mail inválido!',
          style: {
            color: '#CC0000',
          },
        });
      }
    },
    loading: false,
    form,
    fields: getFields(t),
    title: t('Esqueci minha senha'),
    subtitle: t('Digite seu e-mail para recuperar sua senha'),
    actionButtons: {
      leftButton: {
        title: t('Cancelar'),
        onClick: useHistory().goBack,
      },
    },
  };

  const changeLetter = (entry: string) => {
    const first = entry.split('@')[0];
    const second = entry.split('@')[1];
    let asterisco = '';
    let count = 0;

    for (const x of first.split('')) {
      if (count === 0) {
        count += 1;
        asterisco += x;
      } else if (count > 0) {
        asterisco += '*';
      }
    }
    return `${asterisco}@${second}`;
  };

  return (
    <>
      {!mailSent ? (
        <TemplateLogin {...fields} />
      ) : (
        <Col className="box-help">
          <Link to="/" title="Santander">
            <DefaultLogo />
          </Link>
          <div className="content">
            <h1>Vamos te ajudar a</h1>
            <h2>RECUPERAR SUA SENHA</h2>
            <p>
              {'As instruções para recuperar sua senha foram enviadas para o e-mail: '}
              <b>{changeLetter(email)}</b>
            </p>
            <Button className="btn-help" onClick={() => history.push('/login')}>OK</Button>
          </div>
        </Col>
      )}
    </>
  );
};

export default PageForgetPassword;
