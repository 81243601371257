/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Select,
  Checkbox,
  TimePicker,
  message,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { MaskedInput } from 'antd-mask-input';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';
import moment from 'moment';
import SectionTitle from '../../../../Components/Molecules/SectionTitle';
import ApiService from '../../../../Services/Api';
import { DataContext } from '../../../../Base/Providers/Data.context';
import { addressType } from '../../../../Components/Atoms/AddressType';
import GetCepLatLong from '../../../../Components/Molecules/GetLatLngByCep/GetLatLngByCep';

const { Option } = Select;

const containerStyle = {
  width: '400px',
  height: '400px',
  margin: 'auto',
};

const SubsidiaryEdit = () => {
  const history = useHistory();
  const { values, actions } = useContext(DataContext);
  const { t } = useTranslation();
  const [subsidiary, setSubsdiary] = useState(values?.subsidiaryEdit);
  const [loading, setLoading] = useState(false);

  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState(values?.subsidiaryEdit?.city);
  const [bairro, setBairro] = useState(values?.subsidiaryEdit?.neigborhood);
  const [logradouro, setlogradouro] = useState(values?.subsidiaryEdit?.street);
  const [uf, setUf] = useState(values?.subsidiaryEdit?.state);
  const [lati, setLati] = useState<any>(Number(values.subsidiaryEdit?.lat.replace(',', '.')!));
  const [long, setLong] = useState<any>(Number(values.subsidiaryEdit?.long.replace(',', '.')!));
  const [status, setStatus] = useState(true);
  const [image, setImage] = useState<any>(values.subsidiaryEdit?.image);
  const [logo, setLogo] = useState<any>(values.subsidiaryEdit?.logo);
  const [sameFileImage, setSameFileImage] = useState(true);
  const [sameFileLogo, setSameFileLogo] = useState(true);
  const [error, setError] = useState(false);

  const hoursSub = values.subsidiaryEdit?.openingHour;

  const [weekday, setWeekday] = useState(true);
  const weekdayA = !!values.storeSelected.subsidiary ? hoursSub.find((a: any) => a.order === 0) : false;
  const [InitWeekday, setInitWeekday] = useState<any>(!!weekdayA ? weekdayA.hourInit : false);
  const [FinalWeekday, setFinalWeekday] = useState<any>(!!weekdayA ? weekdayA.hourFinal : false);
  const [saturday, setSaturday] = useState<any>(!!values.storeSelected.subsidiary ? subsidiary?.openingHour.find((a: any) => a.day === 'SABADO' && !!a.hourInit) : false);
  const saturdayA = !!values.storeSelected.subsidiary ? hoursSub.find((a: any) => a.order === 1) : false;
  const [InitASaturday, setInitASaturday] = useState<any>(!!saturdayA ? saturdayA.hourInit : false);
  const [FinalASaturday, setFinalASaturday] = useState<any>(!!saturdayA ? saturdayA.hourFinal : false);
  const [sunday, setSunday] = useState<any>(!!values.storeSelected.subsidiary ? subsidiary?.openingHour.find((a: any) => a.day === 'DOMINGO' && !!a.hourInit) : false);
  const sundayA = !!values.storeSelected.subsidiary ? hoursSub.find((a: any) => a.order === 2) : false;
  const [InitASunday, setInitASunday] = useState<any>(!!sundayA ? sundayA.hourInit : false);
  const [FinalASunday, setFinalASunday] = useState<any>(!!sundayA ? sundayA.hourFinal : false);
  const [holiday, setHoliday] = useState(!!values.storeSelected.subsidiary ? subsidiary?.openingHour.find((a: any) => a.day === 'FERIADO' && !!a.hourInit) : false);
  const holidayA = !!values.storeSelected.subsidiary ? hoursSub.find((a: any) => a.order === 3) : false;
  const [InitAHoliday, setInitAHoliday] = useState<any>(!!holidayA ? holidayA.hourInit : false);
  const [FinalAHoliday, setFinalAHoliday] = useState<any>(!!holidayA ? holidayA.hourFinal : false);
  const [whats, setWhats] = useState(values?.subsidiaryEdit?.whatsapp);

  function mphone(v: any) {
    let r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
      r = r.replace(/^(\d*)/, '($1');
    }
    return r;
  }

  function mask(o: any, f: any) {
    setTimeout(() => {
      const v = mphone(o.value);
      if (v !== o.value) {
        o.value = v;
      }
    }, 1);
  }

  const fileImage = values.subsidiaryEdit?.image;
  const fileLogo = values.subsidiaryEdit?.logo;

  const center = {
    lat: lati,
    lng: long,
  };

  const hours = [
    {
      day: 'SEMANA',
      hourInit: !!InitWeekday ? InitWeekday : '',
      hourFinal: !!FinalWeekday ? FinalWeekday : '',
      order: 0,
    },
    {
      day: 'SABADO',
      hourInit: !!InitASaturday ? InitASaturday : '',
      hourFinal: !!FinalASaturday ? FinalASaturday : '',
      order: 1,
    },
    {
      day: 'DOMINGO',
      hourInit: !!InitASunday ? InitASunday : '',
      hourFinal: !!FinalASunday ? FinalASunday : '',
      order: 2,
    },
    {
      day: 'FERIADO',
      hourInit: !!InitAHoliday ? InitAHoliday : '',
      hourFinal: !!FinalAHoliday ? FinalAHoliday : '',
      order: 3,
    },
  ];

  const validateMessages = () => {
    setError(true);
    setLoading(false);
    setTimeout(() => { setError(false); }, 10000);
  };

  const onFinish = async (formValues: any) => {
    if (!!InitWeekday && !!FinalWeekday) {
      setLoading(true);
      if (sameFileImage === false && sameFileLogo === true) {
        const formData = new FormData();
        formData.append('image', image || '');
        formData.append('logo', logo || '');
        const extras = JSON.stringify({
          ...formValues,
          // whatsapp: whats,
          matrizStoreId: values.storeSelected?.id,
          id: subsidiary?.id,
          originalSubsidiary: subsidiary?.originalSubsidiary,
          isAnalysis: false,
          isActive: true,
          lat: lati,
          long,
          cep,
          city: cidade,
          neigborhood: bairro,
          state: uf,
          street: logradouro,
          logo: '',
          hours,
        });
        formData.append('extras', extras);
        const result = await ApiService.subsidiaries.EditSubsidiaries(formData);
        setLoading(false);
        if (result) history.push('/stores/details');
      } else if (sameFileLogo === false && sameFileImage === true) {
        const formData = new FormData();
        formData.append('image', image || '');
        formData.append('logo', logo || '');
        const extras = JSON.stringify({
          ...formValues,
          // whatsapp: whats,
          matrizStoreId: values.storeSelected?.id,
          id: subsidiary?.id,
          originalSubsidiary: subsidiary?.originalSubsidiary,
          isAnalysis: false,
          isActive: true,
          lat: lati,
          long,
          city: cidade,
          neigborhood: bairro,
          state: uf,
          street: logradouro,
          image: '',
          hours,
        });
        formData.append('extras', extras);
        const result = await ApiService.subsidiaries.EditSubsidiaries(formData);
        setLoading(false);
        if (result) history.push('/stores/details');
      } else {
        const formData = new FormData();
        formData.append('image', image || '');
        formData.append('logo', logo || '');
        const extras = JSON.stringify({
          ...formValues,
          // whatsapp: whats,
          matrizStoreId: values.storeSelected?.id,
          id: subsidiary?.id,
          originalSubsidiary: subsidiary?.originalSubsidiary,
          isAnalysis: false,
          isActive: true,
          lat: lati,
          long,
          city: cidade,
          neigborhood: bairro,
          state: uf,
          street: logradouro,
          hours,
        });
        formData.append('extras', extras);
        const result = await ApiService.subsidiaries.EditSubsidiaries(formData);
        setLoading(false);
        if (result) history.push('/stores/details');
      }
    } else {
      validateMessages();
    }
  };

  return (
    <Row>
      <Col span={24}>
        <SectionTitle
          title={t('Lojas | Editar | Filial')}
        />
        <Form
          name="create-filial"
          onFinish={onFinish}
          onFinishFailed={validateMessages}
          layout="vertical"
          style={{
            maxWidth: 800,
            margin: 'auto',
          }}
        >
          <Col style={{ marginTop: 20 }}>
            <h2>Atualize as informações da sua filial</h2>
          </Col>
          <Form.Item
            label="NomeFantasia"
            name="commercialName"
            initialValue={subsidiary?.commercialName}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Instagram"
              name="instagram"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              initialValue={subsidiary?.instagram}
            >
              <Input placeholder="Digite o link do Instagram, se houver" />
            </Form.Item>
            <Form.Item
              label="Facebook"
              name="facebook"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
              initialValue={subsidiary?.facebook}
            >
              <Input placeholder="Digite o link do Facebook, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Pinterest"
              name="pinterest"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              initialValue={subsidiary?.pinterest}
            >
              <Input placeholder="Digite o link do Pinterest, se houver" />
            </Form.Item>
            <Form.Item
              label="Twitter"
              name="twitter"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
              initialValue={subsidiary?.twitter}
            >
              <Input placeholder="Digite o link do PinterestTwitter, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Youtube "
              name="youtube"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              initialValue={subsidiary?.youtube}
            >
              <Input placeholder="Digite o link do Youtube, se houver" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Telefone"
              name="telephone"
              initialValue={subsidiary?.telephone}
              // validateStatus={teste ? 'error' : 'success'}
              // help={teste ? 'Deve conter todos os dígitos!' : ''}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <MaskedInput mask="(11) 1111-1111" readOnly />
            </Form.Item>
            <Form.Item
              label="Whatsapp"
              name="whatsapp"
              initialValue={subsidiary?.whatsapp}
              // rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              {/* <MaskedInput mask="(11) 11111-1111" /> */}
              <Input
                readOnly
                // value={whats}
                onChange={(event) => mask(event.target, mphone)}
              />
            </Form.Item>
          </Form.Item>
          <Col style={{ marginTop: 20 }}>
            <h2>Horário de funcionamento</h2>
          </Col>
          <Form.Item>
            <Form.Item
              // valuePropName={monday}
              initialValue={weekday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={weekday} onChange={(event) => setWeekday(true)}><b>Segunda a Sexta-Feira</b></Checkbox>
            </Form.Item>
            {
              weekday && (
                <Form.Item>
                  <Form.Item
                    style={{ display: 'inline-block', width: 'calc(33.8%)' }}
                    validateStatus={!!InitWeekday && !!FinalWeekday ? '' : 'error'}
                    help={!!InitWeekday && !!FinalWeekday ? '' : 'Campo obrigatório'}
                  >
                    <TimePicker.RangePicker
                      format="HH:mm"
                      defaultValue={InitWeekday
                        ? [moment(InitWeekday, 'HH:mm'), moment(FinalWeekday, 'HH:mm')]
                        : [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]}
                      onChange={(event, timeString) => {
                        setInitWeekday(timeString[0]);
                        setFinalWeekday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }
            <Form.Item
              valuePropName="checked"
              initialValue={saturday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={saturday} onChange={(event) => setSaturday(event.target.checked)}><b>Sábado</b></Checkbox>
            </Form.Item>
            {
              saturday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      defaultValue={InitASaturday
                        ? [moment(InitASaturday, 'HH:mm'), moment(FinalASaturday, 'HH:mm')]
                        : [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]}
                      onChange={(event, timeString) => {
                        setInitASaturday(timeString[0]);
                        setFinalASaturday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }

            <Form.Item
              valuePropName="checked"
              initialValue={sunday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={sunday} onChange={(event) => setSunday(event.target.checked)}><b>Domingo</b></Checkbox>
            </Form.Item>
            {
              sunday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      defaultValue={InitASunday
                        ? [moment(InitASunday, 'HH:mm'), moment(FinalASunday, 'HH:mm')]
                        : [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]}
                      onChange={(event, timeString) => {
                        setInitASunday(timeString[0]);
                        setFinalASunday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }
            <Form.Item
              valuePropName="checked"
              initialValue={holiday}
              wrapperCol={{ offset: 0, span: 16 }}
            >
              <Checkbox checked={holiday} onChange={(event) => setHoliday(event.target.checked)}><b>Feriado</b></Checkbox>
            </Form.Item>
            {
              holiday && (
                <Form.Item>
                  <Form.Item style={{ display: 'inline-block', width: 'calc(33.8%)' }}>
                    <TimePicker.RangePicker
                      format="HH:mm"
                      defaultValue={InitAHoliday
                        ? [moment(InitAHoliday, 'HH:mm'), moment(FinalAHoliday, 'HH:mm')]
                        : [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]}
                      onChange={(event, timeString) => {
                        setInitASunday(timeString[0]);
                        setFinalASunday(timeString[1]);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              )
            }
          </Form.Item>
          {/* <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="image"
              label="Foto"
              valuePropName="Foto"
              initialValue={subsidiary?.image}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <div>

                {image && (
                  <div>
                    <img
                      src={
                        // image === fileLogo
                        image === fileImage
                          ? `https://assets.energiamaisvezes.com.br.s3.amazonaws.com/subsidiary/${subsidiary?.image}`
                          : URL.createObjectURL(image)
                      }
                      style={{
                        height: 160,
                        borderRadius: 4,
                        marginBottom: 20,
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )}

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  name="image"
                  id="image-or-video"
                  type="file"
                  onChange={(event) => {
                    if (event.target.files) {
                      setImage(event.target.files[0]);
                      setSameFileImage(false);
                    }
                  }}
                />
                <label htmlFor="image-or-video" className="select-image">
                  Selecionar Imagem
                </label>
              </div>
            </Form.Item>
            <Form.Item
              name="logo"
              label="Logo"
              initialValue={subsidiary?.logo}
              valuePropName="Logo"
              style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
            >
              <div>

                {logo && (
                  <div>
                    <img
                      src={
                        // image === fileImage
                        logo === fileLogo
                          ? `https://assets.energiamaisvezes.com.br.s3.amazonaws.com/subsidiary/${subsidiary?.logo}`
                          : URL.createObjectURL(logo)
                      }
                      style={{
                        height: 160,
                        borderRadius: 4,
                        marginBottom: 20,
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )}

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  name="logo"
                  id="image-or-video-logo"
                  type="file"
                  onChange={(event) => {
                    if (event.target.files) {
                      setLogo(event.target.files[0]);
                      setSameFileLogo(false);
                    }
                  }}
                />
                <label htmlFor="image-or-video-logo" className="select-image">
                  Selecionar Logo
                </label>
              </div>
            </Form.Item>
          </Form.Item> */}
          <Form.Item
            label="CEP"
            name="cep"
            initialValue={subsidiary?.cep}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
          >
            <MaskedInput
              mask="11111-111"
              onChange={async (element) => {
                let cepTemp: any = parseInt(element.target.value.replace('-', ''), 10);
                if (JSON.stringify(cepTemp).length < 8) {
                  cepTemp = `0${JSON.stringify(cepTemp)}`;
                } else {
                  cepTemp = `${JSON.stringify(cepTemp)}`;
                }
                if (cepTemp.length === 8) {
                  const { data } = await axios.get(`https://viacep.com.br/ws/${cepTemp}/json`);
                  setCep(cepTemp);
                  setCidade(data.localidade);
                  setBairro(data.bairro);
                  setlogradouro(data.logradouro);
                  setUf(data.uf);
                  const LatLng = await GetCepLatLong(cepTemp);
                  if (LatLng) {
                    setLati(LatLng.results[0].geometry.location.lat);
                    setLong(LatLng.results[0].geometry.location.lng);
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Estado"
            name="state"
            valuePropName={uf}
            initialValue={values?.subsidiaryEdit?.state}
            rules={[{ required: !uf, message: 'Campo obrigatório!' }]}
          >
            <Input value={uf} onChange={(event) => setUf(event.target.value)} readOnly />
          </Form.Item>
          <Form.Item
            label="Cidade"
            name="city"
            valuePropName={cidade}
            initialValue={subsidiary?.city}
            rules={[{ required: !cidade, message: 'Campo obrigatório!' }]}
          >
            <Input value={cidade} onChange={(event) => setCidade(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Bairro"
            name="neigborhood"
            valuePropName={bairro}
            initialValue={subsidiary?.neigborhood}
            rules={[{ required: !bairro, message: 'Campo obrigatório!' }]}
          >
            <Input value={bairro} onChange={(event) => setBairro(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Logradouro (tipo)"
            name="address"
            initialValue={subsidiary?.address}
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
            style={{ textTransform: 'capitalize' }}
          >
            <Select showSearch>
              {
                addressType.map((element) => {
                  return (
                    <Option style={{ textTransform: 'capitalize' }} value={element.value}>{element.value}</Option>
                  );
                })
              }
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label="Logradouro"
              name="street"
              valuePropName={logradouro}
              initialValue={subsidiary?.street}
              rules={[{ required: !logradouro, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input
                placeholder="Digite o endereço da sua loja"
                value={logradouro}
                onChange={(event) => setlogradouro(event.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Número"
              name="number"
              initialValue={subsidiary?.number}
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
              style={{ display: 'inline-block', width: 'calc(20% - 8px)', margin: '0 0 0 8px' }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Complemento"
              name="complement"
              initialValue={subsidiary?.complement}
              style={{ display: 'inline-block', width: 'calc(30%)', margin: '0 0 0 8px' }}
            >
              <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label="Latitude"
                name="lat"
                // validateStatus={teste ? 'error' : 'success'}
                // help={teste ? 'Deve conter todos os dígitos!' : ''}
                valuePropName={lati}
                initialValue={Number(values.subsidiaryEdit?.lat.replace(',', '.')!)}
                rules={[{ required: !lati, message: 'Campo obrigatório!' }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <Input
                  readOnly
                  type="number"
                  value={lati}
                  onChange={(event) => setLati(Number(event.target.value))}
                />
              </Form.Item>
              <Form.Item
                label="Longitude"
                name="long"
                valuePropName={long}
                initialValue={Number(values.subsidiaryEdit?.long.replace(',', '.')!)}
                rules={[{ required: !long, message: 'Campo obrigatório!' }]}
                style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 0 0 8px' }}
              >
                <Input
                  readOnly
                  type="number"
                  value={long}
                  onChange={(event) => setLong(Number(event.target.value))}
                />
              </Form.Item>
            </Form.Item>
            <LoadScript
              googleMapsApiKey="AIzaSyB9HUirJIS34PJTyYpgjhIVijyBZEX3_qg"
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onClick={(event) => {
                  setLati(event.latLng?.lat());
                  setLong(event.latLng?.lng());
                }}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </Form.Item>
          {
            error && (
              <>
                <Form.Item style={{
                  display: 'flex', textAlignLast: 'center', marginTop: 15,
                }}
                >
                  <Alert style={{ maxWidth: '60%', margin: 'auto', padding: '2px 10px' }} message="Há campos obrigatórios que faltam ser preenchidos!" type="error" showIcon />
                </Form.Item>
              </>
            )
          }
          <Form.Item style={{ display: 'flex', textAlignLast: 'center', marginTop: 15 }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit" style={{ width: 130, flex: 1 }}>
                Salvar
              </Button>
              <Button loading={loading} style={{ width: 130, flex: 1 }}>
                <Link to="/stores/details">
                  Cancelar
                </Link>
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default SubsidiaryEdit;
