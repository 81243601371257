import React, { FC } from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

type IsActiveTagProps = {
  isActive: boolean
  partial: boolean | undefined
};

const IsActiveTag: FC<IsActiveTagProps> = ({ isActive, partial }) => (
  isActive ? (<CheckCircleTwoTone twoToneColor={partial ? '#FFA500' : '#52c41a'} />) : (<CloseCircleTwoTone twoToneColor="#ff4d4f" />)
);

export default IsActiveTag;
